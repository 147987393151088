<template>
  <TitleComponent title="AGGIUNGI UN NUOVO CLIENTE" />
  <el-card class="custom-card">
    <div style="margin-top: -2%; text-align: left">
      <el-switch
        class="custom-switch"
        v-model="isPrivate"
        active-text="Privato"
        inactive-text="Azienda"
        :active-value="true"
        :inactive-value="false"
      />
    </div>

    <el-form ref="formRef" :model="customer" :rules="formRule">
      <FormComponent
        v-if="!isPrivate"
        :value="customer"
        :type="type"
        :rules="formRule"
        :label="customer.label"
        :loading="loadingPoint"
        :handleInputBlur="handleInputBlur"
      />
    </el-form>
    <el-form ref="formRef" :model="customer" :rules="formRule">
      <FormCustomerComponent
        v-if="isPrivate"
        :value="customer"
        :type="type"
        :rules="formRule"
        :label="customer.label"
        :loading="loadingPoint"
        :handleInputBlur="handleInputBlur"
      />
    </el-form>

    <br />
    <el-card shadow="hover">
      <TitleComponent title="Aggiungi auto" />

      <div v-for="(car, counter_car) in customer.cars" v-bind:key="counter_car">
        <el-form ref="formCarRef" :model="car" :rules="formRule">
          <FormCarComponent
            :car="car"
            :type="type"
            :rules="formRule"
            :options="options"
            @change="handleChange"
            :ifCondition="counter_car != 0 ? true : false"
            @buttonClick="rimuoviCar"
            :counterCar="counter_car"
          ></FormCarComponent>
        </el-form>
      </div>

      <ButtonComponent
        label="Aggiungi auto"
        :isRouter="false"
        @buttonClick="aggiungiCar"
        type="success"
      ></ButtonComponent>
    </el-card>

    <!-- <div v-if="storeLogin.role == 'salesvendor'"></div> -->

    <div v-if="storeLogin.role == 'salesvendor'">
      <el-card class="card" shadow="hover">
        <TitleComponent title="Assegna booking gratuiti al cliente" />

        <div v-for="(minute, counter_min) in minutes" v-bind:key="counter_min">
          <div class="flex-container-car">
            <div style="padding-right: 20px">
              <SelectComponent
                @selected="(newValue) => (minute.car = newValue)"
                :options="carsId"
                title="Selezionare un'auto"
              ></SelectComponent>
            </div>
            <div class="input-group">
              <label for="valetInput">Numero di booking gratuiti </label>

              <el-input-number
                v-model="minute.valet"
                :min="0"
                :max="3"
                @change="handleChangeValet(minute)"
              />
            </div>

            <DescriptionComponent
              name="Tariffa"
              icon="PriceTag"
              :label="minute.tariffa + ' €'"
              :loading="false"
              style=""
            ></DescriptionComponent>

            <div class="delete-button">
              <ButtonComponent
                :ifCondition="counter_min != 0 ? true : false"
                :isRouter="false"
                @buttonClick="rimuoviMinute($event, counter_min)"
                type="danger"
                icon="Delete"
              ></ButtonComponent>
            </div>
          </div>
        </div>
        <div class="button-container">
          <ButtonComponent
            label="Aggiungi valet"
            :isRouter="false"
            @buttonClick="aggiungiMinute"
            type="success"
          ></ButtonComponent>
        </div>
      </el-card>
    </div>

    <el-col :sm="6" :offset="10">
      <el-form-item>
        <ButtonComponent
          label="Annulla"
          :isRouter="true"
          linkPath="/customer"
          @buttonClick="annulla"
        ></ButtonComponent>

        <el-button
          type="primary"
          style="margin: 1px 3px"
          @click="onSubmit('form', 'form2')"
        >
          Aggiungi nuovo cliente
        </el-button>
      </el-form-item>
    </el-col>
  </el-card>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, ref, nextTick, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import customerAPI from '../services/customerAPI';
import { isLoggedStore } from '../store/logged';
import partnerAPI from '../services/partnerAPI';
import employeeAPI from '../services/employeeAPI';
import ButtonComponent from '@/Components/ButtonComponent.vue';
import TitleComponent from '@/Components/TitleComponent.vue';
import FormCarComponent from '@/Components/FormCarComponent.vue';
import FormComponent from '@/Components/FormComponent.vue';
import FormCustomerComponent from '@/Components/FormCustomerComponent.vue';
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import SelectComponent from '@/Components/SelectComponent.vue';
import DescriptionComponent from '@/Components/DescriptionComponent.vue';
import { useRouter } from 'vue-router';
import carAPI from '../services/carAPI.js';
const dayjs = require('dayjs');
export default {
  components: {
    ButtonComponent,
    TitleComponent,
    FormCarComponent,
    FormComponent,
    SelectComponent,
    FormCustomerComponent,
    DescriptionComponent,
  },

  setup() {
    const props1 = {
      expandTrigger: 'hover',
      //checkStrictly: true,
    };

    let isPrivate = ref(true);

    const storeLogin = isLoggedStore();
    const router = useRouter();
    let transmission = ref('');
    const options = [
      {
        value: 'manual',
        label: 'manuale',
      },
      {
        value: 'automatic',
        label: 'automatico',
      },
    ];
    let customer = reactive({
      company_name: '',
      piva: '',
      CF: '',
      SDI: '',
      PEC: '',
      name: '',
      surname: '',
      date_birth: new Date(),
      mail: '',
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      latitude: '',
      longitude: '',
      label: '',
      telephone_number: '',
      role: 'customer',
      isAlive: true,
      cars: [
        {
          intestatario: '',
          marca: '',
          modello: '',
          targa: '',
          transmission: '',
          headquarters: {},
        },
      ],
      partners: [],
      employees: [],
    });

    let minutes = ref([
      {
        valet: 0,
        tariffa: 0,
        car: {},
      },
    ]);
    let formRule = reactive({
      name: [
        {
          required: true,
          message: 'Inserire il nome',
          trigger: 'blur',
        },
      ],
      surname: [
        {
          required: true,
          message: 'Inserire il cognome',
          trigger: 'blur',
        },
      ],
      date_birth: [
        {
          type: 'date',
          required: true,
          message: 'Inserire data di nascita',
          trigger: 'blur',
        },
      ],
      mail: [
        {
          required: true,
          message: 'Inserire mail',
          trigger: 'blur',
        },
      ],
      address: [
        {
          required: true,
          message: 'Inserire indirizzo',
          trigger: 'blur',
        },
      ],
      street_number: [
        {
          required: true,
          message: 'Inserire N.civico',
          trigger: 'blur',
        },
      ],
      city: [
        {
          required: true,
          message: 'Inserire città',
          trigger: 'blur',
        },
      ],
      cap: [
        {
          required: true,
          message: 'Inserire cap',
          trigger: 'blur',
        },
      ],
      province: [
        {
          required: true,
          message: 'Inserire provincia',
          trigger: 'blur',
        },
      ],
      telephone_number: [
        {
          required: true,
          message: 'Inserire numero di telefono',
          trigger: 'blur',
        },
      ],
     /*  CF: [
        {
          required: true,
          message: 'Inserire codice fiscale',
          trigger: 'blur',
        },
        {
          min: 16,
          max: 16,
          message: 'Numero di cifre errato',
          trigger: 'blur',
        },
      ], */
      intestatario: [
        {
          required: true,
          message: 'Inserire intestatario',
          trigger: 'blur',
        },
      ],
      marca: [
        {
          required: true,
          message: 'Inserire marca',
          trigger: 'blur',
        },
      ],
      modello: [
        {
          required: true,
          message: 'Inserire modello',
          trigger: 'blur',
        },
      ],
      targa: [
        {
          required: true,
          message: 'Inserire targa',
          trigger: 'blur',
        },
      ],
      transmission: [
        {
          required: true,
          message: 'Scegliere cambio',
          trigger: 'blur',
        },
      ],
    });

    let partner = ref({});

    const loadPartner = async () => {
      const responsePartner = await partnerAPI.getPartnerByEmployee(
        storeLogin.id
      );

      partner.value = responsePartner.data[0].headquarters[0].partner;
    };

    loadPartner();

    const handleChangeValet = (minute) => {
      if (minute.valet == 1) {
        minute.tariffa = partner.value.one_valet;
      }
      if (minute.valet == 2) {
        minute.tariffa = partner.value.two_valet;
      }
      if (minute.valet == 3) {
        minute.tariffa = partner.value.three_valet;
      }
    };

    let carsId = ref([]);
    watch(
      () => customer.cars,
      (cars) => {
        carsId.value = [];
        console.log(cars);
        cars.forEach((car) => {
          if (
            car.intestatario != '' &&
            car.marca != '' &&
            car.modello != '' &&
            car.targa != ''
          ) {
            let tableRow = {
              value: car.targa,
              label: `${car.marca} ${car.modello} ${car.targa}`,
            };

            carsId.value.push(tableRow);
          }
        });
      },
      { deep: true, immediate: true }
    );

    const rimuoviMinute = (e, counter) => {
      minutes.value.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiMinute = (e) => {
      minutes.value.push({
        valet: 0,
        tariffa: 0,
        car: {},
      });
      e.preventDefault();
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });

        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    let responsePoint = ref({
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      latitude: '',
      longitude: '',
    });

    const handleInputBlur = async () => {
        
      if (
        customer.address != '' &&
        customer.street_number != '' &&
        customer.city != '' &&
        customer.cap != '' &&
        customer.province != ''
      ) {

        let point = {
          address: customer.address,
          street_number: customer.street_number,
          city: customer.city,
          cap: customer.cap,
          province: customer.province,
        };

        let response = await loadPoints(point);
        customer.label = response.label;
        customer.latitude = response.point.latitude;
        customer.longitude = response.point.longitude;

        responsePoint.value.address = response.point.address;
        responsePoint.value.street_number = response.point.street_number;
        responsePoint.value.city = response.point.city;
        responsePoint.value.cap = response.point.cap;
        responsePoint.value.province = response.point.province;
        responsePoint.value.latitude = response.point.latitude;
        responsePoint.value.longitude = response.point.longitude;
      }
    };

    const postMinute = async (minute) => {
      try {
        let resp = await carAPI.addMinute(minute);
        await carAPI.mailAddMinute(resp.data.id);
        return resp;
      } catch (e) {
        console.log(e);
      }
    };

    const addMinute = async () => {
      minutes.value.forEach(async (minute) => {
        if(minute.valet>0 && minute.tariffa >0){
          let car = await carAPI.getCarByTarga(minute.car[0]);

        let newMinute = {
          valet: minute.valet,
          residual_valet: minute.valet,
          expiration_valet: new Date(),
          imponibile: minute.tariffa,
          tot_fattura: minute.tariffa + (minute.tariffa * 22) / 100,
          rif_fattura: 0,
          esito: false,
          accepted: false,
          data: new Date(),
          car: car.data[0],
          partner: partner.value,
        };

        newMinute.expiration_valet.setFullYear(
          newMinute.expiration_valet.getFullYear() + minute.valet
        );
        await postMinute(newMinute);
        }
        
      });
    };

    const addcustomer_data = async (customer) => {
      let response = {};
      try {
        customer.address = responsePoint.value.address;
        customer.street_number = responsePoint.value.street_number;
        customer.city = responsePoint.value.city;
        customer.cap = responsePoint.value.cap;
        customer.province = responsePoint.value.province;
        customer.latitude = responsePoint.value.latitude;
        customer.longitude = responsePoint.value.longitude;

        const employeeId = storeLogin.employee.id;
        const partner = await partnerAPI.getPartnerByEmployee(employeeId);
        const employee = await employeeAPI.getEmployee(employeeId);
        customer.date_birth = dayjs(customer.date_birth).format(
          'YYYY-MM-DD HH:mm:ss'
        );

        customer.cars.forEach((car) => {
          car.headquarters = partner.data[0].headquarters;
        });

        customer.partners.push(partner.data[0].headquarters[0].partner);
        customer.employees.push(employee.data[0]);

        response = await customerAPI.addCustomer(toRaw(customer));

        // if(storeLogin.role=="salesvendor"){

        //}

        return response;
      } catch (error) {
        if (error.response.status === 400) {
          ElMessage({
            message: 'Errore!',
            type: 'error',
          });
          console.log('errore inatteso');
        } else if (error.response.status === 409) {
          ElMessage({
            message: 'Mail già usata!',
            type: 'error',
          });
          console.log(error.response, 'mail già utilizzata');
        } else {
          ElMessage({
            message: 'Errore catch!',
            type: 'error',
          });
        }
        console.log(error);
      }
    };
    const rimuoviCar = (e, counter) => {
      customer.cars.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiCar = (e) => {
      customer.cars.push({
        intestatario: '',
        marca: '',
        modello: '',
        telaio: '',
      });
      e.preventDefault();
    };

    const formRef = ref(null);
    const formCarRef = ref(null);

    const onSubmit = async () => {
      await nextTick();
      formRef.value.validate(async (valid) => {
        if (valid) {
          formCarRef.value[0].validate(async (validcar) => {
            if (validcar) {
              const response = await addcustomer_data(toRaw(customer));
              if (response.status === 201) {
                if(storeLogin.role == 'salesvendor') {await addMinute();}
                storeLogin.role == 'salesvendor'
                  ? router.push('/customer')
                  : router.push('/prenotazioni-info');
                ElMessage({
                  message: 'Cliente aggiunto con successo!',
                  type: 'success',
                });
              } else if (response.status === 400) {
                ElMessage({
                  message: 'Errore1!',
                  type: 'error',
                });
                console.log('errore inatteso');
              } else if (response.status === 409) {
                ElMessage({
                  message: 'Mail già usata!',
                  type: 'error',
                });
                console.log('mail già utilizzata');
              }
            } else {
              //this.$router.push("/addcustomer_employee");
              ElMessage({
                message: 'Errore 2!',
                type: 'error',
              });
            }
          });
        }
      });
    };

    const annulla = () => {
      router.back();
    };

    return {
      formRule,
      transmission,
      options,
      addcustomer_data,
      customer,
      props1,
      rimuoviCar,
      aggiungiCar,
      Delete,
      TitleComponent,
      onSubmit,
      formRef,
      formCarRef,
      isPrivate,
      annulla,
      loadPoints,
      loadingPoint,
      handleInputBlur,
      storeLogin,
      minutes,
      carsId,
      handleChangeValet,
      rimuoviMinute,
      aggiungiMinute,
      responsePoint,
    };
  },
  watch: {
    /* formatCar() {
      // if(this.customer.cars[0].targa!=''){
      this.customer.cars.forEach((car) => {
        let tableRow = {
          value: car.id,
          label: `${car.marca} ${car.modello} ${car.targa}`,
        };

        this.carsId.value.push(tableRow);
      });
      //  }
    }, */

    /* async handleInputBlur() {
      console.log("** this.customer ", this.customer)
      if (
        this.customer.address != '' &&
        this.customer.street_number != '' &&
        this.customer.city != '' &&
        this.customer.cap != '' &&
        this.customer.province != ''
      ) {
        let point = {
          address: this.customer.address,
          street_number: this.customer.street_number,
          city: this.customer.city,
          cap: this.customer.cap,
          province: this.customer.province,
        };
        console.log("🚀 ~ handleInputBlur ~ point:", point)

        let response = await this.loadPoints(point);
        console.log("🚀 ~ handleInputBlur ~ response:", response)
        this.customer.label = response.label;
        this.customer.latitude = response.point.latitude;
        this.customer.longitude = response.point.longitude;

        this.responsePoint.address = response.point.address;
        this.responsePoint.street_number = response.point.street_number;
        this.responsePoint.city = response.point.city;
        this.responsePoint.cap = response.point.cap;
        this.responsePoint.province = response.point.province;
        this.responsePoint.latitude = response.point.latitude;
        this.responsePoint.longitude = response.point.longitude;
      }
    },*/
  }, 
};
</script>

<style>
.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}

.custom-card {
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom-switch .el-switch__label {
  font-weight: bold;
}

.card {
  justify-items: center;
}

.flex-container-car {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 10px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 30px;
}

.button-container {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.delete-button {
  margin-right: auto;
}
</style>
