import API from './API';

export default {

  getHeadquartersByEmployee(employeeId) {
    return API.get(`/employee?filter=id||$eq||${employeeId}&`,{
        params: {
             join: [
              'headquarters',
              'headquarters.area',
            ], 
          },
        });
  }, 

  getHeadquarters() {
    return API.get('/headquarter');
  },


  addTask(task) {
    return API.post('/task', task);
  },

  getBookingById(id) {
    return API.get(`/booking/`+ id, {
       params: {
        join: 
        [
          'pickup_bookings', 
          'pickup_bookings.point', 
          'pickup_bookings.driver_pickup', 
          'pickup_bookings.planners', 

          'delivery_bookings',
          'delivery_bookings.point_origin_delivery',
          'delivery_bookings.point_destination_delivery',
          'delivery_bookings.driver_delivery',
          'delivery_bookings.planners', 
          
          'escort_bookings',
          'escort_bookings.point_origin_escort',
          'escort_bookings.point_destination_escort', 
          'escort_bookings.driver_escort',
          'escort_bookings.planners', 

          'customer', 
          'tasks', 
          'car', 
          'headquarter',
          'headquarter.area',
          
        ],
      }, 
    });
  },

  getBookingAttivi(headquarterId,currentDate, tomorrowDate  ) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=data||$gte||${currentDate}&filter=data||$lte||${tomorrowDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks'],

      },
      order: { data: 'ASC' }
    });
  },

  getBookingFuturi(headquarterId,tomorrowDate) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=data||$gte||${tomorrowDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks']
      },
    });
  },

  getBookingPassati(headquarterId,currentDate) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=data||$lt||${currentDate}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks']
      },
    });
  },

  getBookingAttiviAll(currentDate, tomorrowDate) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=data||$gte||${currentDate}&filter=data||$lte||${tomorrowDate}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks']
      },
    });
  },

  getBookingFuturiAll(tomorrowDate ) {
    const state ="canceled";
     return API.get(`/booking?filter=state||$ne||${state}&filter=data||$gte||${tomorrowDate}&sort=data,ASC`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks']
      },
    })
  },

  getBookingPassatiAll(currentDate) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=data||$lte||${currentDate}&sort=data,ASC`, {
      params: {
        
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks']
      },
    });
  },

  updateDateBooking(id, data){
    return API.patch('/booking/'+id, data)
  },

  /***************************PICKUP**********************/
  getRangePickup(req){
    return API.post('/pickup/range', req);
  },
  
  /* updateDatePickup(id,date){
    return API.patch('/pickup/'+id, date)
  }, */
  updateDatePickup(id,date){
    return API.post('/pickup/update-prenotazione/'+id, date)
  },

  /***************************ESCORT **********************/
  getRangeEscort(req){
    return API.post('/escort/range', req);
  }, 

  updateDateEscort(id, date){
    return API.post('/escort/update-prenotazione/'+id, date)
  },

  /***************************DELIVERY**********************/
  getRangeDelivery(req){
    return API.post('/delivery/range', req);
  }, 

  updateDateDelivery(id, date){
    return API.post('/delivery/update-prenotazione/'+id, date)
  },


  messageModifyHourBooking(data){
    return API.post('/booking/messageModifyHourBooking/', data)
  },


  messageModifyTaskBooking(data){
    return API.post('/booking/messageModifyBooking/', data)
  },


  patchBooking(id) {
    return API.patch('/state', id);
  },

  deleteBooking(id) {
    return API.get('/booking/delete/' + id);
  },

  deleteTask(id) {
    return API.delete('/task/' + id);
  },


};