<template>
  <div>
    
    <el-form-item :label="title">
      <VueDatePicker
        v-model="datePartner"
        :enable-time-picker="false"
        :clearable="false"
        :disabled="dateDisabled"
        :state="!highlightDate"
        format="dd/MM/yyyy"
        :disabled-dates="disabledDates"
      />
    </el-form-item>
  </div>
  <div>
    <el-form-item>
      <el-cascader
        v-model="selectedSlot"
        :placeholder="loading ? 'Loading...' : 'Ora'"
        :options="timeSlots"
        :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
        popper-class="custom-popper"
        :disabled="loading"
        :class="{ highlight: highlightSlot }"
      />
    </el-form-item>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
export default {
  components: {
    VueDatePicker,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    timeSlots: {
      type: Array,
      required: true,
    },
    highlightSlot: {
      type: Boolean,
      required: true,
    },
    dateDisabled: {
      type: Boolean,
      required: true,
    },
    highlightDate: {
      type: Boolean,
      required: true,
    },
    disabledDates: {
      required: false,
      type: Function,
    },
    selectedSlotInput: {},
  },
  data() {
    return {
      selectedSlot: null,
      datePartner: '',
    };
  },
  watch: {
    selectedSlot(val) {
      this.$emit('selectedSlot', val);
    },
    datePartner(val) {
      this.$emit('datePartnerChange', val);
    },
   
    selectedSlotInput: {
      immediate: true,
      handler(newVal) {
        this.selectedSlot = newVal;
      },
    }, 
  },
};
</script>
