<template>
  <el-space wrap>
    <el-card style="height: 100%; width: 1220px; overflow: inherit">
      <template #header>
        <AddNewCustomerComponent></AddNewCustomerComponent>
        <div class="container-customer">
          <div>
            <PointComponent
              :value="customerExist"
              @input="(newValue) => (customerExist = newValue)"
              title="Controlla se il cliete è già stato registrato"
              label="email"
              placeholder="email"
            ></PointComponent>
          </div>
          <div>
            <ButtonComponent
              label="cerca"
              :isRouter="false"
              @buttonClick="checkCustomerExist"
            ></ButtonComponent>
          </div>
        </div>
      </template>

      <div class="flex-container">
        <div class="select-component">
          <SelectComponent
            @selected="handleChangeCustomer"
            :options="customersId"
            title="Selezionare un cliente"
            :addButtom="false"
            :highlight="highlightCustomer"
            :isDisabled="false"
            :value="customerSelected"
          ></SelectComponent>
        </div>
        <div class="select-component">
          <SelectComponent
            @selected="handleChangeHeadquarter"
            :options="headquartersId"
            title="Selezionare una sede"
            :addButtom="false"
            :highlight="highlightHeadquarter"
            :isDisabled="false"
          ></SelectComponent>
        </div>
      </div>
      <el-divider />
      <el-form :model="delivery">
        <div class="container-data">
          <DateSelectComponent
        title="Data/ora di ritiro vettura dal punto di prelievo"
        :loading="loadingSlots"
        :timeSlots="timeSlots"
        :highlightSlot="highlightSlot"
        :dateDisabled="!customerSelected || !headquarterSelected"
        :highlight-date="highlightDate"
        @selectedSlot="handleSlotChange"
        @datePartnerChange="handleChangeDate"
        :selectedSlotInput="selectedSlot"
        :disabledDates="disabledDate"
      ></DateSelectComponent>

          <!-- <DateSelectComponent
            title="Data/ora di ritiro vettura dal punto di prelievo"
            :loading="loadingSlots"
            :timeSlots="timeSlots"
            :highlightSlot="highlightSlot"
            :dateDisabled="!customerSelected || !headquarterSelected"
            :highlight-date="highlightDate"
            @selectedSlot="handleSlotChange"
            @datePartnerChange="handleChangeDate"
            :selectedSlotInput="selectedSlot"
          ></DateSelectComponent> -->
        </div>

        <el-divider />
        <el-form :model="delivery">
          <!-- punto di prelievo -->
          <div class="container-point">
            <PointComponent
              :value="delivery.point_origin_delivery.address"
              @input="
                (newValue) =>
                  (delivery.point_origin_delivery.address = newValue)
              "
              title="Punto di prelievo"
              label="Indirizzo"
              placeholder="Indirizzo"
              :span="8"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_origin_delivery.street_number"
              @input="
                (newValue) =>
                  (delivery.point_origin_delivery.street_number = newValue)
              "
              label="N. civico"
              placeholder="N. civico"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_origin_delivery.cap"
              @input="
                (newValue) => (delivery.point_origin_delivery.cap = newValue)
              "
              label="CAP"
              placeholder="CAP"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_origin_delivery.city"
              @input="
                (newValue) => (delivery.point_origin_delivery.city = newValue)
              "
              :span="4"
              label="Città"
              placeholder="Città"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_origin_delivery.province"
              @input="
                (newValue) =>
                  (delivery.point_origin_delivery.province = newValue)
              "
              label="Provincia"
              placeholder="Provincia"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>
          </div>

          <DescriptionComponent
            :label="delivery.point_origin_delivery.label"
            :loading="loadingPoint"
          ></DescriptionComponent>

          <!-- punto di consegna -->
          <div class="container-point">
            <PointComponent
              :value="delivery.point_destination_delivery.address"
              @input="
                (newValue) =>
                  (delivery.point_destination_delivery.address = newValue)
              "
              title="Punto di consegna"
              label="Indirizzo"
              placeholder="Indirizzo"
              :span="8"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_destination_delivery.street_number"
              @input="
                (newValue) =>
                  (delivery.point_destination_delivery.street_number =
                    newValue.target.value)
              "
              label="N. civico"
              placeholder="N. civico"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_destination_delivery.cap"
              @input="
                (newValue) =>
                  (delivery.point_destination_delivery.cap = newValue)
              "
              label="CAP"
              placeholder="CAP"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_destination_delivery.city"
              @input="
                (newValue) =>
                  (delivery.point_destination_delivery.city = newValue)
              "
              :span="4"
              label="Città"
              placeholder="Città"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>

            <PointComponent
              :value="delivery.point_destination_delivery.province"
              @input="
                (newValue) =>
                  (delivery.point_destination_delivery.province = newValue)
              "
              label="Provincia"
              placeholder="Provincia"
              @focus="handleInputFocus"
              @blur="handleInputBlur"
            ></PointComponent>
          </div>

          <DescriptionComponent
            :label="delivery.point_destination_delivery.label"
            :loading="loadingPoint"
          ></DescriptionComponent>
        </el-form>

        <div class="info-container">
          <div class="info-item">
            <PriceComponent
              label="Fascia km"
              :value="`${responseKm} km`"
              tagType="info"
            />
          </div>
          <div class="info-item">
            <PriceComponent
              label="Tariffa"
              :value="`${responseCost} euro`"
              tagType="success"
            />
          </div>
        </div>

        <br />
        <br />

        <div style="padding-top: 2%; text-align: left">
          Aggiungi i dati della vettura da consegnare:
        </div>
        <div class="container-point">
          <PointComponent
            :value="marca"
            @input="(newValue) => (marca = newValue)"
            placeholder="Marca"
            :span="4"
            style="margin-right: 30px"
            :highlight="highlightMarca"
          ></PointComponent>
          <PointComponent
            :value="modello"
            @input="(newValue) => (modello = newValue)"
            placeholder="Modello"
            :span="4"
            style="margin-right: 30px"
            :highlight="highlightModello"
          ></PointComponent>
          <PointComponent
            :value="targa"
            @input="(newValue) => (targa = newValue)"
            placeholder="Targa"
            :span="4"
            style="margin-right: 30px"
            :highlight="highlightTarga"
          ></PointComponent>

          <!-- <el-form-item prop="transmission">
            <SelectComponent
            
              @selected="handleChange"
              v-model="cambio"
              :options="options"
              :value="cambio"
              title="Selezionare tipo di cambio"
              style="width:20%"
              :addButtom="false"
              :isDisabled="false"
              :span="6"
            ></SelectComponent>
          </el-form-item> -->

          <div>
            <el-form-item prop="transmission">
              <el-cascader
                v-model="cambio"
                :options="options"
                clearable
                :class="{ highlight: highlightCambio }"
                placeholder="Tipo di cambio"
              />
            </el-form-item>
          </div>
        </div>
        <el-row :gutter="20">
          <el-col :span="20" style="margin-top: 1.5%">
            <el-form-item>
              <el-input
                v-model="note"
                :rows="5"
                type="textarea"
                placeholder="Aggiungi delle note per i driver"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row justify="center" style="margin-top: 3.5%">
        <ButtonComponent
          label="Annulla"
          :isRouter="true"
          linkPath="/"
          @buttonClick="annulla"
        ></ButtonComponent>
        <ButtonComponent
          label="Crea"
          :isRouter="false"
          @buttonClick="onSubmit"
        ></ButtonComponent>
      </el-row>
    </el-card>
  </el-space>
</template>

<script>
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import store from '../store/prenotazione.js';
import bookingAPI from '@/services/bookingAPI';
import { reactive, ref, toRaw } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import { isEmpty } from 'lodash';
import { useRouter } from 'vue-router';
import '@vuepic/vue-datepicker/dist/main.css';
import { isLoggedStore } from '../store/logged';
import SelectComponent from './SelectComponent.vue';
import AddNewCustomerComponent from './AddNewCustomerComponent.vue';
import PointComponent from './PointComponent.vue';
import DateSelectComponent from './DateSelectComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import DescriptionComponent from './DescriptionComponent.vue';
import PriceComponent from './PriceComponent.vue';
import customerAPI from '../services/customerAPI';
import partnerAPI from '../services/partnerAPI.js';
const dayjs = require('dayjs');

export default {
  name: 'DeliveryComponent',
  components: {
    SelectComponent,
    AddNewCustomerComponent,
    DateSelectComponent,
    PointComponent,
    ButtonComponent,
    DescriptionComponent,
    PriceComponent,
  },

  setup() {
    const router = useRouter();
    const tasks = [];

    let marca = ref('');
    let modello = ref('');
    let targa = ref('');
    let cambio = ref('');
    let note = ref('');

    const options = [
      {
        value: 'manual',
        label: 'manuale',
      },
      {
        value: 'automatic',
        label: 'automatico',
      },
      {
        value: 'both',
        label: 'entrambi',
      },
    ];

    const storeLogin = isLoggedStore();
    const booking = reactive({
      payed: false,
      imponibile: 0,
      tot_fattura: 0,
      rif_fattura: 0,
      liquidato: false,
      data: new Date(),
      over_twentysix: false,
      loaner: false,
      targa_loaner: '',
      state: 'before', //status:"before"
      type: 'gone',
      note: '',
      customer: store.customerSelectedObj,
      //company: {},
      employee: toRaw(storeLogin.employee),
      type_prenotazione: 'delivery',
      headquarter: store.heaquarterSelectedObj,
      delivery_bookings: [],
    });

    const delivery = reactive({
      date_point: '',
      new_date_point: null,
      date_customer: '',
      date_driver: '',
      date_jolly_scan: '',
      true_date_point: '',
      true_date_customer: '',
      true_date_driver: '',
      simba_km: 0,
      simba_cost: 0.0,
      simba_minute: 0,
      type: 'gone',
      order: 0,
      driver_delivery: null,
      planner: null,
      description: '',
      point_origin_delivery: {
        address: store.heaquarterSelectedObj.address,
        street_number: store.heaquarterSelectedObj.street_number,
        cap: store.heaquarterSelectedObj.cap,
        city: store.heaquarterSelectedObj.city,
        province: store.heaquarterSelectedObj.province,
        latitude: 0,
        longitude: 0,
        label: '',
        trip: null,
      },
      point_destination_delivery: {
        address: store.originPointAddress,
        street_number: store.originPointStreetNumber,
        cap: store.originPointCity,
        city: store.originPointCap,
        province: store.originPointProvince,
        latitude: 0,
        longitude: 0,
        label: '',
        trip: null,
      },
      planners: [],
    });

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });
        loadingPoint.value = false;

        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    let highlightCustomer = ref(false);
    let highlightHeadquarter = ref(false);
    let highlightDate = ref(false);
    let highlightSlot = ref(false);
    let highlightMarca = ref(false);
    let highlightModello = ref(false);
    let highlightTarga = ref(false);
    let highlightCambio = ref(false);

    const checkHighlight = () => {
      if (!customerSelected.value) {
        ElMessage.error('Perfavore inserisci un cliente');
        highlightCustomer.value = true;
        //highlightHeadquarter.value = true;
        return false;
      }

      if (!headquarterSelected.value) {
        ElMessage.error('Perfavore inserisci una sede');
        highlightHeadquarter.value = true;
        return false;
      }

      if (delivery.date_point == '') {
        ElMessage.error('Perfavore inserisci una data');
        highlightDate.value = true;
        return false;
      }

      if (!selectedSlot.value) {
        ElMessage.error('Perfavore inserisci un orario');
        highlightSlot.value = true;
        return false;
      }

      if (!marca.value) {
        ElMessage.error('Perfavore inserisci una marca');
        highlightMarca.value = true;
        return false;
      }

      if (!modello.value) {
        ElMessage.error('Perfavore inserisci un modello');
        highlightModello.value = true;
        return false;
      }

      if (!targa.value) {
        ElMessage.error('Perfavore inserisci una targa');
        highlightTarga.value = true;
        return false;
      }

      if (!cambio.value) {
        ElMessage.error('Perfavore seleziona il tipo di cambio');
        highlightCambio.value = true;
        return false;
      } else {
        highlightCustomer.value = false;
        highlightHeadquarter.value = false;
        highlightMarca.value = false;
        highlightModello.value = false;
        highlightTarga.value = false;
        highlightCambio.value = false;

        return true;
      }
    };

    let customerExist = ref('');

    const checkCustomerExist = async () => {
      try {
        let request = {
          mail: customerExist.value,
        };

        const response = await customerAPI.checkCustomerExist(request);

        if (response.data.exist == true) {
          ElMessageBox.confirm(
            `Nome: ${response.data.customer.name}<br>Cognome: ${response.data.customer.surname}<br>Email: ${response.data.customer.mail}`,
            'Cliente Trovato',
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: 'Aggiungi il cliente',
              cancelButtonText: 'Annulla',
            }
          )
            .then(async () => {
              await addPartnerToCustomer();
            })
            .catch(() => {});
        } else {
          ElMessageBox.confirm(
            'Il cliente non esiste, registralo prima di prenotare',
            {
              confirmButtonText: 'Registra un cliente',
              cancelButtonText: 'Cancel',
              type: 'warning',
              customClass: 'custom-message-box',
            }
          )
            .then(() => {
              router.push('/addcustomer_employee');
            })
            .catch(() => {});
        }
      } catch (error) {
        ElMessage({
          message: 'Si è verificato un errore durante la verifica del cliente',
          type: 'error',
        });
        console.log(
          error,
          'Si è verificato un errore durante la verifica del cliente'
        );
      }
    };

    const addPartnerToCustomer = async () => {
      const responsePartner = await partnerAPI.getPartnerByEmployee(
        storeLogin.id
      );

      const partner = responsePartner.data[0].headquarters[0].partner;

      try {
        let request = {
          mail: customerExist.value,
          partner: partner,
        };

        const response = await customerAPI.addPartnerToCustomer(request);

        

        booking.customer = response.data.customer;

        store.customerSelectedObj = response.data.customer;

        const customerExistsInStore = customersId.some(
          (customer) => customer.value === response.data.customer.id
        );

        if (!customerExistsInStore) {
          store.customers.push(response.data.customer);
        }

        //store.customers.push(response.data.customer);

        let tableRow = {
          value: booking.customer.id,
          label: `${booking.customer.name} ${booking.customer.surname}, ${booking.customer.mail}`,
        };
        if (!customerExistsInStore) {
          store.customersCascader.push(tableRow);
        }

        customerSelected.value = response.data.customer.id;
       
      } catch (error) {
        console.log(error);
      }
    };

    //cliente
    let customerSelected = ref(store.customerSelected);
    const customersId = store.customersCascader;
    const customers = store.customers;

    const handleChangeCustomer = async (val) => {
      customerSelected.value = val;
      booking.customer = customers.filter((customer) => customer.id == val)[0];

      store.customerSelectedObj = booking.customer;
      store.customerSelected = booking.customer.id;

      delivery.point_destination_delivery.address = booking.customer.address;
      delivery.point_destination_delivery.street_number =
        booking.customer.street_number;
      delivery.point_destination_delivery.cap = booking.customer.cap;
      delivery.point_destination_delivery.city = booking.customer.city;
      delivery.point_destination_delivery.province = booking.customer.province;

      delivery.point_destination_delivery.latitude = booking.customer.latitude;
      delivery.point_destination_delivery.longitude =
        booking.customer.longitude;

      delivery.point_destination_delivery.label = (
        await loadPoints(delivery.point_destination_delivery)
      ).label;
      delivery.point_destination_delivery.latitude = (
        await loadPoints(delivery.point_destination_delivery)
      ).point.latitude;
      delivery.point_destination_delivery.longitude = (
        await loadPoints(delivery.point_destination_delivery)
      ).point.longitude;

      //loadMinutes(val[0]);
      checkInfo();
      calcolaKm();
    };

    //partner
    const partnersId = ref([]);
    const partners = ref([]);

    //sede
    let headquarterSelected = ref(store.heaquarterSelected);
    let headquartersId = store.headquartersCascader;
    const headquarters = store.headquarters;

    const handleChangeHeadquarter = async (val) => {
      headquarterSelected.value = val;
      booking.headquarter = headquarters.filter(
        (headquarter) => headquarter.id == val
      )[0];
      store.heaquarterSelected = booking.headquarter.id;
      store.heaquarterSelectedObj = booking.headquarter;

      delivery.point_origin_delivery.address = booking.headquarter.address;
      delivery.point_origin_delivery.street_number =
        booking.headquarter.street_number;
      delivery.point_origin_delivery.cap = booking.headquarter.cap;
      delivery.point_origin_delivery.city = booking.headquarter.city;
      delivery.point_origin_delivery.province = booking.headquarter.province;

      delivery.point_origin_delivery.latitude = booking.customer.latitude;
      delivery.point_origin_delivery.longitude = booking.customer.longitude;

      delivery.point_origin_delivery.label = (
        await loadPoints(delivery.point_origin_delivery)
      ).label;
      delivery.point_origin_delivery.latitude = (
        await loadPoints(delivery.point_origin_delivery)
      ).point.latitude;
      delivery.point_origin_delivery.longitude = (
        await loadPoints(delivery.point_origin_delivery)
      ).point.longitude;

      calcolaKm();
      //loadMinutes(toRaw(val)[0]);
      checkInfo();
    };

    //pulsante "avanti" disabilitato prima di aver inserito tutte le informazioni
    let button1_disabled = ref(true);
    const checkInfo = () => {
      if (
        isEmpty(toRaw(booking.customer)) ||
        isEmpty(toRaw(booking.headquarter)) ||
        delivery.date_point == ''
      ) {
        button1_disabled.value = true;
      } else {
        button1_disabled.value = false;
      }
    };

    //ottengo slot disponibili
    const selectedSlot = ref('');
    const timeSlots = ref([
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDate = async (val) => {
      delivery.date_point = val;
      const selectedDate = dayjs(val).format('YYYY-MM-DD');
      loadSlot(selectedDate).then((resp) => {
        console.log(resp);
      });
    };

    let loadingSlots = ref(true);

    let request_slot = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const Slot = async (date) => {
      loadingSlots.value = true;
      let newSlot = '';
      handleSlotChange(newSlot);
      try {
        if (
          !isEmpty(toRaw(delivery.point_origin_delivery)) &&
          !isEmpty(toRaw(delivery.point_destination_delivery))
        ) {
          console.log('request_slot_gone ', request_slot);

          request_slot.originPoint = toRaw(delivery.point_origin_delivery);
          request_slot.destinationPoint = toRaw(
            delivery.point_destination_delivery
          );
          request_slot.type = 'DELIVERY_GONE';
          request_slot.partnerDay = date;

          request_slot.area = booking.headquarter.area;

          const response = await prenotazioneAPI.calcSlot(request_slot);
          loadingSlots.value = false;
          return response.data;
        }
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const mergeSlots = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await Slot(selectedDate);
      //const availableSlots = ['09:00'];
      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);
          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const loadSlot = async (selectedDate) => {
      if (selectedDate != '') {
        const mergedSlotsGone = await mergeSlots(timeSlots.value, selectedDate);
        timeSlots.value.splice(0, timeSlots.value.length, ...mergedSlotsGone);
      }
    };

    const handleSlotChange = (value) => {
      selectedSlot.value = value;
      console.log(
        '🚀 ~ handleSlotChange ~ selectedSlot.value:',
        selectedSlot.value
      );
      if (value != '') {
        delivery.date_point = dayjs(new Date(delivery.date_point))
          .hour(value[0].split(':')[0])
          .minute(value[0].split(':')[1])
          .second(0)
          .format('YYYY-MM-DD HH:mm:ss');
      }
      checkInfo();
    };

    //range del booking
    let responseKm = ref(store.rangeKmDelivery);
    let responseCost = ref(store.rangeCostDelivery);

    let request = {
      headquarter_id: '',
      headquarter_point: {},
      origins_point: {},
      destination_point: {},
      loaner: false,
      isJollyNeeded: true,
      isOnlyJollyNeeded: false,
    };

    const calcolaKm = async () => {
      try {
        request.headquarter_id = booking.headquarter.id;
        request.origins_point = toRaw(delivery.point_origin_delivery);
        request.destination_point = toRaw(delivery.point_destination_delivery);
        request.headquarter_point = booking.headquarter;

        if (
          !isEmpty(toRaw(booking.headquarter))// &&
          //!isEmpty(toRaw(booking.customer))
        ) {
          const response = await bookingAPI.getRangeDelivery(request);

          delivery.simba_minute = response.data.minute;
          delivery.simba_km = response.data.km;
          delivery.simba_imponibile = response.data.imponibile;
          delivery.simba_km_range = response.data.kmRange;

          responseKm.value = response.data.kmRange;
          responseCost.value = response.data.cost;

          store.rangeKmDelivery = response.data.kmRange;
          store.rangeCostDelivery = response.data.cost;

          /* store.originPointAddress = request.origins_point.address;
                    store.originPointStreetNumber = request.origins_point.street_number;
                    store.originPointCity = request.origins_point.city;
                    store.originPointCap = request.origins_point.cap;
                    store.originPointProvince = request.origins_point.province; */

          store.destinationPointAddress = request.destination_point.address;
          store.destinationPointStreetNumber =
            request.destination_point.street_number;
          store.destinationPointCity = request.destination_point.city;
          store.destinationPointCap = request.destination_point.cap;
          store.destinationPointProvince = request.destination_point.province;
        }
      } catch (error) {
        console.log(error);
      }
    };

    calcolaKm();

    checkInfo();

    const rimuoviTask = (e, counter) => {
      booking.tasks.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiTask = (e) => {
      booking.tasks.push({
        description: '',
        cost: 0,
        pay: false,
      });
      e.preventDefault();
    };

    const addBookingNew = async (booking) => {
      let response = {};
      try {
        let newPlannerGone = null;

        booking.imponibile = delivery.simba_imponibile;
        booking.tot_fattura = booking.imponibile * 1.22;

        booking.data = delivery.date_point;

        newPlannerGone = await addPlannerNew(
          toRaw(delivery.point_origin_delivery),
          toRaw(delivery.point_destination_delivery),
          delivery.date_point,
          'DELIVERY_GONE'
        );
        delivery.planners.push(newPlannerGone.data.plannerDriver);
        delivery.planners.push(newPlannerGone.data.plannerJolly);

        delivery.date_customer =
          newPlannerGone.data.driver.trips[0].type == 'date_c1_p1'
            ? newPlannerGone.data.driver.trips[0].dateStart
            : newPlannerGone.data.driver.trips[1].dateStart;
        delivery.date_driver =
          newPlannerGone.data.driver.trips[0].type == 'date_p1_c1'
            ? newPlannerGone.data.driver.trips[0].dateStart
            : newPlannerGone.data.driver.trips[1].dateStart;

        delivery.driver_delivery = newPlannerGone.data.driver.driverId;
        booking.delivery_bookings.push(delivery);

        response = await prenotazioneAPI.addBooking(booking);
        await prenotazioneAPI.addBookingNotification({
          customer: booking.customer,
          booking: response.data,
          area: booking.headquarter.area,
        });

        return response;
      } catch (error) {
        ElMessage({
          message: 'Errore response!',
          type: 'error',
        });
        console.log(error);
      }
    };

    const addPlannerNew = async (
      originPoint,
      destinationPoint,
      partnerTime,
      type
    ) => {
      try {
        const data = {
          originPoint: originPoint,
          destinationPoint: destinationPoint,
          partnerTime: partnerTime,
          type: type,
          area: booking.headquarter.area,
        };

        return await prenotazioneAPI.calcDuration(data);
      } catch (error) {
        ElMessage({
          message: 'Errore response!',
          type: 'error',
        });
        console.log(error);
      }
    };

    const onSubmit = async () => {
      if (!checkHighlight()) {
        return;
      }
      booking.note =
        'Marca: ' +
        marca.value +
        ', Modello: ' +
        modello.value +
        ', Targa: ' +
        targa.value +
        ', Tipo di cambio: ' +
        cambio.value +
        ', Note: ' +
        note.value;
      addBookingNew(toRaw(booking)).then(async (response) => {
        if (response.status === 201) {
          await prenotazioneAPI.mailCustomer(booking.customer.mail);

          ElMessage({
            message: 'Booking aggiunto!',
            type: 'success',
          });
          console.log('Booking aggiunto!');
        } else {
          ElMessage({
            message: 'Errore creazione booking!',
            type: 'error',
          });
          console.log('errore inatteso');
        }
        router.push('/');
      });
    };

    const props1 = {
      expandTrigger: 'hover',
    };

    const isInputFocused = ref(false);

    const handleInputFocus = () => {
      isInputFocused.value = true;
    };
    const handleInputBlur = async () => {
      isInputFocused.value = false;
      if (
        delivery.point_origin_delivery.address == '' ||
        delivery.point_origin_delivery.street_number == '' ||
        delivery.point_origin_delivery.city == '' ||
        delivery.point_origin_delivery.cap == '' ||
        delivery.point_origin_delivery.province == '' ||
        delivery.point_destination_delivery.address == '' ||
        delivery.point_destination_delivery.street_number == '' ||
        delivery.point_destination_delivery.city == '' ||
        delivery.point_destination_delivery.cap == '' ||
        delivery.point_destination_delivery.province == ''
      ) {
        button1_disabled.value = true;
      } else {
        button1_disabled.value = false;
        delivery.point_origin_delivery.label = (
          await loadPoints(delivery.point_origin_delivery)
        ).label;
        delivery.point_origin_delivery.latitude = (
          await loadPoints(delivery.point_origin_delivery)
        ).point.latitude;
        delivery.point_origin_delivery.longitude = (
          await loadPoints(delivery.point_origin_delivery)
        ).point.longitude;

        delivery.point_destination_delivery.label = (
          await loadPoints(delivery.point_destination_delivery)
        ).label;
        delivery.point_destination_delivery.latitude = (
          await loadPoints(delivery.point_destination_delivery)
        ).point.latitude;
        delivery.point_destination_delivery.longitude = (
          await loadPoints(delivery.point_destination_delivery)
        ).point.longitude;
        calcolaKm();
        loadSlot(delivery.date_point);
      }
    };

    const disabledDate = (vDate) => {
      let expirydate = new Date();
      return vDate < expirydate.setDate(expirydate.getDate() + 3);
    };

    return {
      loadingSlots,
      handleInputFocus,
      handleInputBlur,
      headquarterSelected,
      customerSelected,

      delivery,

      selectedSlot,
      timeSlots,
      handleSlotChange,
      handleChangeDate,

      //changeValue_simbaPay,
      //show_minute,
      tasks,

      addBookingNew,
      customers,
      customersId,
      booking,
      partners,
      partnersId,

      headquartersId,
      headquarters,
      onSubmit,
      props1,

      handleChangeCustomer,
      handleChangeHeadquarter,
      aggiungiTask,
      rimuoviTask,
      Delete,
      button1_disabled,

      //residual_minuteVal,
      responseKm,
      responseCost,

      highlightCustomer,
      highlightHeadquarter,

      highlightDate,
      highlightSlot,
      Slot,
      loadingPoint,
      marca,
      modello,
      targa,
      cambio,
      options,
      note,
      customerExist,
      checkCustomerExist,
      highlightMarca,
      highlightModello,
      highlightTarga,
      highlightCambio,
      disabledDate,
    };
  },
};
</script>

<style>
.box-card {
  width: 1000px;
  height: 300px;
}
.custom-popper .el-cascader-panel {
  height: 300px !important;
  overflow-y: auto !important;
}

.custom-popper .el-cascader-menu__wrap {
  height: 300px !important;
  overflow-y: visible !important;
}
</style>
