<template>
  <TitleComponent title="AUTO DEL CLIENTE" />
  <el-card class="custom-card">
    <div
      v-for="(single_car, counter_car) in carsEditable"
      v-bind:key="counter_car"
    >
      <el-form
        ref="form2"
        :model="single_car"
        :rules="formRuleCar(counter_car)"
      >
        <FormCarComponent
          :car="single_car"
          :type="type"
          :rules="formRule"
          :options="options"
          @change="handleChange"
          :inputTransmission="true"
          :modifyButom="true"
          :deleteCar="false"
        ></FormCarComponent>
      </el-form>
    </div>

    <div v-for="(single_car, counter_car) in cars" v-bind:key="counter_car">
      <el-form
        ref="form2"
        :model="single_car"
        :rules="formRuleCar(counter_car)"
      >
        <FormCarComponent
          :car="single_car"
          :type="type"
          :rules="formRule"
          :options="options"
          @change="handleChange"
          :inputTransmission="true"
          :modifyButom="false"
          :deleteCar="false"
          @buttonClick="modifyCar(single_car)"
        ></FormCarComponent>
      </el-form>
    </div>

    <!-- <TitleComponent title="Aggiungi auto al cliente" /> -->

    <el-divider />

    <div
      v-for="(single_car_new, counter_car) in carsNew"
      v-bind:key="counter_car"
    >
      <el-form
        ref="form2"
        :model="single_car_new"
        :rules="formRuleCar(counter_car)"
      >
        <FormCarComponent
          :car="single_car_new"
          :type="type"
          :rules="formRule"
          :options="options"
          :deleteCar="true"
          :counterCar="counter_car"
          :ifCondition="counter_car != 0 ? true : false"
          @buttonClick="rimuoviCar"
          @change="handleChange"
        ></FormCarComponent>
      </el-form>

      <ButtonComponent
        label="Aggiungi auto"
        :isRouter="false"
        @buttonClick="aggiungiCar"
        type="success"
        :plain="true"
      ></ButtonComponent>
    </div>
    <div v-if="carsNew.length > 0">
    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/prenotazioni-info"
        @buttonClick="annulla"
      ></ButtonComponent>
   
      
      <ButtonComponent
        label="Conferma"
        :isRouter="false"
        type="primary"
        @buttonClick="onSubmit('form2')"
      ></ButtonComponent>
    </el-row>
  </div>
  </el-card>
</template>

<script>
import { reactive, toRaw, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import customerAPI from '../services/customerAPI';
import carAPI from '../services/carAPI';
import { useRoute } from 'vue-router';
//import partnerAPI from "@/services/partnerAPI";
import bookingAPI from '../services/bookingAPI.js';
import { isLoggedStore } from '../store/logged';

import FormCarComponent from '../Components/FormCarComponent.vue';
import ButtonComponent from '../Components/ButtonComponent.vue';
import TitleComponent from '../Components/TitleComponent.vue';

export default {
  components: {
    FormCarComponent,
    ButtonComponent,
    TitleComponent,
  },

  setup() {
    const storeLogin = isLoggedStore();
    const route = useRoute();
    const props1 = {
      //checkStrictly: true,
      expandTrigger: 'hover',
    };
    const options = [
      {
        value: 'manual',
        label: 'manuale',
      },
      {
        value: 'automatic',
        label: 'automatico',
      },
    ];
    const formRuleCar = (counter) => {
      return {
        intestatario: [
          {
            required: true,
            message: 'Inserire intestatario macchina ' + counter,
            trigger: 'blur',
          },
        ],
        marca: [
          {
            required: true,
            message: 'Inserire marca',
            trigger: 'blur',
          },
        ],
        modello: [
          {
            required: true,
            message: 'Inserire modello',
            trigger: 'blur',
          },
        ],
        targa: [
          {
            required: true,
            message: 'Inserire targa',
            trigger: 'blur',
          },
        ],
        transmission: [
          {
            required: true,
            message: 'Scegliere cambio',
            trigger: 'blur',
          },
        ],
      };
    };
    let transmission = ref('');
    const handleChange = (val) => {
      transmission.value = val;
    };
    const carsNew = reactive([
      {
        intestatario: '',
        marca: '',
        modello: '',
        telaio: '',
        transmission: 'automatic',
        customer: {},
        headquarters: [{}],
      },
    ]);
    let customer = ref({});
    let headquarters = ref([{}]);
    const loadCustomer = async () => {
      try {
        const response = await customerAPI.getCustomer(route.params.id);
        customer.value = response.data;
        console.log('customer : ////', customer.value);
        loadCars(customer.value.id);
        return customer;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };
    const loadHeadquarters = async () => {
      try {
        const employeeId = storeLogin.employee.id;
        const response = await bookingAPI.getHeadquartersByEmployee(employeeId);
        headquarters.value = response.data[0];
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };
    loadCustomer();
    loadHeadquarters();
    const cars = reactive([]);
    const carsEditable = reactive([]);
    const processCar = (car) => {
      const tableRow = {
        id: car.id,
        intestatario: car.intestatario,
        marca: car.marca,
        modello: car.modello,
        targa: car.targa,
        transmission: car.transmission,
        customer: car.customer,
      };
      return tableRow;
    };
    const loadCars = async (customerId) => {
      try {
        const employeeId = storeLogin.employee.id;
        //const partner = await partnerAPI.getPartnerByEmployee(employeeId);
        const response = await carAPI.getCarsByCustomer(customerId);
        response.data.forEach((car) => {
          const isEmployeeAssociated = car.headquarters.some((headquarter) =>
            headquarter.employees.some((employee) => employee.id === employeeId)
          );
          if (car.minutes.length !== 0) {
            car.minutes.forEach((minute) => {
              if (minute.accepted == true) {
                const tableRow = processCar(car);
                if (isEmployeeAssociated) {
                  carsEditable.push(tableRow);
                } else {
                  if (car.transmission === 'manual') {
                    tableRow.transmission = 'manuale';
                  } else if (car.transmission === 'automatic') {
                    tableRow.transmission = 'automatico';
                  } else {
                    tableRow.transmission = 'entrambi';
                  }
                  cars.push(tableRow);
                }
              }
            });
          } else {
            const tableRow = processCar(car);
            if (isEmployeeAssociated) {
              carsEditable.push(tableRow);
            } else {
              if (car.transmission === 'manual') {
                tableRow.transmission = 'manuale';
              } else if (car.transmission === 'automatic') {
                tableRow.transmission = 'automatico';
              } else {
                tableRow.transmission = 'entrambi';
              }
              cars.push(tableRow);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const addCar = async (car) => {
      let response = {};
      try {
        if (car.transmission === 'manuale') {
          car.transmission = 'manual';
        } else if (car.transmission === 'automatico') {
          car.transmission = 'automatico';
        } else {
          car.transmission = 'both';
        }
        response = await carAPI.addCar(car);
        return response;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };
    const rimuoviCar = (e, counter) => {
      carsNew.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiCar = (e) => {
      carsNew.push({
        intestatario: '',
        marca: '',
        modello: '',
        telaio: '',
        transmission: '',
        customer: {},
        headquarters: [{}],
      });
      e.preventDefault();
    };
    const modifyCar = async (car) => {
      let response;
      try {
        if (car.transmission === 'manuale') {
          car.transmission = 'manual';
        } else if (car.transmission === 'automatico') {
          car.transmission = 'automatico';
        } else {
          car.transmission = 'both';
        }

        response = await carAPI.modifyCar(car);
        if (response.status === 200) {
          ElMessage({
            message: 'Macchina modificata con successo!',
            type: 'success',
          });
        } else {
          ElMessage({
            message: 'Errore modifica auto!',
            type: 'error',
          });
          console.log('errore modifica auto');
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      modifyCar,
      addCar,
      cars,
      carsEditable,
      props1,
      formRuleCar,
      handleChange,
      rimuoviCar,
      aggiungiCar,
      transmission,
      options,
      Delete,
      customer,
      headquarters,
      carsNew,
    };
  },
  methods: {
    onSubmit(formName) {
      //console.log("formName", formName)
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          this.carsNew.forEach((car) => {
            car.customer = this.customer;
            car.headquarters = this.headquarters.headquarters;
            this.addCar(toRaw(car)).then((response) => {
              if (response.status === 201) {
                this.$router.push('/prenotazioni-info');
                //this.$router.push({name:'home', params: {id: '[paramdata]'}});
                ElMessage({
                  message: 'Macchina aggiunta con successo!',
                  type: 'success',
                });
              } else {
                ElMessage({
                  message: 'Errore inserimento auto!',
                  type: 'error',
                });
                console.log('errore aggiunta auto');
              }
            });
          });
        } else {
          console.log('erroree');
        }
      });
    },
  },
};
</script>

<style>
.el-col {
  margin-top: 4%;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}

.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
