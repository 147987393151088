<template>
  <!--  <el-col :span="span" :style="style"> -->
  <el-cascader
    v-model="selectedElement"
    :placeholder="title"
    :options="options"
    filterable
    :props="props1"
    clearable
    :class="{ 'highlight': highlight }"
    :prop="prop"
  />

  <router-link v-if="addButtom" class="routerLink" :to="{ path: linkPath }">
    <el-button :disabled="isDisabled">
      <el-icon
        style="vertical-align: middle; margin-left: 5%"
        color="#409EFC"
        :size="20"
      >
        <CirclePlus />
      </el-icon>
    </el-button>
  </router-link>
  <!-- </el-col> -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    addButtom: {
      type: Boolean,
      require: true,
    },
    highlight: {
      type: Boolean,
      require: true,
    },
    linkPath: {
      type: String,
      required: false,
    },
    model: {
      required: false,
    },
    isDisabled: {
      type: Boolean,
      require: true,
    },
    span: {
      type: Number,
      required: false,
      default: 8,
    },
    style: {
      type: String,
      required: false,
    },

    prop: { 
      type: String,
       required: false
     },

    value: {
      required: false,
    },
  },

  data() {
    return {
      //selectedElement: '',
      selectedElement: this.value,
    };
  },

  watch: {
    value(newValue) {
      this.selectedElement = newValue;
    },

    selectedElement(newValue) {
      this.$emit('selected', newValue);
    },
  },
};
</script>
<style>

.highlight {
  border: 2px solid rgb(205, 54, 54); 
}
</style>