<template>
    <el-row justify="center">
    
        <img style="width: 90px; height: 116px" :src="require('../assets/logo-simba-RIDIMENSIONATO.png')" class="image" />
    
        <h1>BENVENUTO NELL'AREA<br>
            RISERVATA SIMBA</h1> <br> <br>
    
    </el-row>

    <el-dialog
    v-model="centerDialogVisible"
    title="Attenzione"
    width="900"
    align-center
    :show-close="false"
  >
    <span>Simba non è al momento disponibile</span>
  </el-dialog>
</template>

<script>
import { ref } from 'vue'
export default {
    setup() {
        const centerDialogVisible = ref(true)
        return {
            centerDialogVisible
        }
    },
}
</script>