<template>
  <h1>CAMBIA PASSWORD</h1>
  <el-form :model="user">
    <el-row :gutter="30" justify="center">  
      <el-col :sm="9">
        <el-form-item label="Mail"> 
          <el-input v-model="user.mail" />
        </el-form-item>
      </el-col>  
    </el-row>
    <el-row :gutter="20" justify="center">  
      <el-col :span="3" >
        <router-link :to="{ path: `/login` }" class="routerLink">
          <el-button > Annulla </el-button>
        </router-link>
      </el-col>
      <el-col :span="3">
        <router-link :to="{ path: `/login` }" class="routerLink">
          <el-button color="rgb(231,228,58)" :dark="true" @click="update_password">Cambia password </el-button>
        </router-link>
      </el-col>
    </el-row> 
  </el-form>
</template>

<script>
import {reactive } from "vue";
import { ElMessage } from 'element-plus';
import authAPI from '../services/authAPI.js'
import { Delete } from '@element-plus/icons-vue';


export default {
  setup() {

    let user =reactive({
      mail: "" 
    });



    const update_password = () => {      

      updPassword().then((response) => {
        if (response.status === 200) {
          
            ElMessage({
              message: "controlla la mail!",
              type: "success",
            });
            
          } else {
            ElMessage.error("Error!");
          }
        
      });
    };

    const updPassword = async () => {
      let response = {};
      try {
 
        response = await authAPI.resetPassword(user.mail);
        return response;
      } catch {
         ElMessage.error("Error!");
        console.log("error");
      }
    };




    return {
        updPassword,
        update_password,
        user,
      Delete
    };
  },
};
</script>

<style>

.el-row {
  margin-bottom: -10px;
}
.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}
</style>
