<template >
  <el-row justify="center" >
    <el-col :span="15" :style="style">
        <el-form-item :label=label>
            <input type="file" accept="application/pdf" multiple @change="handleUploadChange">
        </el-form-item>
    </el-col>
  </el-row>
  <el-row justify="center">
    <el-col :span="15" style="margin-top: 1.5%;">

        <el-form-item>
            <el-input v-model="note" :rows="5" type="textarea" placeholder="Aggiungi delle note per i driver" />
        </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    style:{

      default:"margin-top: 5%;"

    }
  },
  data() {
    return {
      note: '',
      
    };
  },
  methods:{
    handleUploadChange(e) {
        console.log("🚀 ~ handleUploadChange ~ e:", e)
        this.$emit('uploadChange', e);
    }
  },
  watch: {
    note(val) {
      this.$emit('note', val);
    },
    /* handleUploadChange(e) {
        console.log("🚀 ~ handleUploadChange ~ e:", e)
        this.$emit('uploadChange', e);
    } */
  },   
};
</script>
