<template>
    <div class="title-container">
      <h1 >{{ title }}</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TitleComponent',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .title-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .title-container h1 {
    font-size: 24px;
    font-weight: bold;
  }
  </style>
  