<template>
  <h1>CORSE</h1>


  <el-row justify="center" style="margin-top: 60px">
    <el-col :span="5" :offset="1">
      <CardComponent
        :imgSrc="corsePassateImg"
        title="STORICO ORDINI"
        label="Ordini conclusi"
        linkPath="/corse/corse_passate"
        @click="handleCardClickPassate"
        :isRouter=true
      ></CardComponent>
    </el-col>
    <el-col :span="5" :offset="1">
      <CardComponent
        :imgSrc="corseAttiveImg"
        title="ORDINI LIVE"
        label="Prenotazioni in corso"
        linkPath="/"
        parameters="1"
        :isHome="true"
        @click="handleCardClickPresenti"
        :isRouter=true

      ></CardComponent>
    </el-col>
    <el-col :span="5" :offset="1">
      <CardComponent
        :imgSrc="corseFutureImg"
        title="AGENDA"
        label="Prenotazioni future"
        linkPath="/corse/corse_future"
        @click="handleCardClickFuture"
        :isRouter=true
      ></CardComponent>
    </el-col>
  </el-row>
</template>

<script>
import { Check, Delete, Edit } from "@element-plus/icons-vue";
import corsePassateImg from "../assets/passati.png"
import corseAttiveImg from "../assets/attivi.png"
import corseFutureImg from "../assets/futuri.png"
import CardComponent from '@/Components/CardComponent.vue';
import { useRouter } from 'vue-router';

export default {
  name: "CorsePage",
  components: {
    CardComponent
  },
  setup() {
    //viene eseguito prima di caricare la pagina, qui richiama le api (da quello che metti su per esempio ../services/detailsAPI)
    const router = useRouter();

    const handleCardClickPassate = () => {
      router.push('/corse/corse_passate');
    };

    const handleCardClickPresenti = () => {
      router.push('/');
    };

    const handleCardClickFuture = () => {
      router.push('/corse/corse_future');
    };

    return {
      Check,
      Delete,
      Edit,
      corsePassateImg,
      corseAttiveImg,
      corseFutureImg,
      handleCardClickPassate,
      handleCardClickPresenti,
      handleCardClickFuture
    };
  },
};
</script>

<style>
h1 {
  margin-top: 40px;
}
</style>
