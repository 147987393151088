import API from './API';
import axios from 'axios';

export default {

  getPoints(text){
    return API.post('/planner/maps-geocode', text)
  },

  getHeadquarters(){
    return API.get('/employee/headquarters')
  },

  calculateMinute(points){
    return API.post('/booking/calculate-minute', points)
  },

  addBooking(booking) {
    return API.post('/booking/', booking);
  },

  addBookingNotification(data) {
    return API.post('/booking/messageNewBooking/', data);
  },

  calcSlot(deatl) {
    return API.post('/planner/slot-availables/', deatl);
  },

  upload(file, mail) {
    return axios.post('https://gestionalesimba.it/booking/upload/' + mail, file, {
      //return axios.post('http://localhost:3000/booking/upload/' + mail, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem("jwt") 
      },
    });
  },

  mailCustomer(mail){
    return API.get('/booking/mailCustomer/'+mail)
  },

  uploadModify(file, mail) {
    return axios.post('https://gestionalesimba.it/booking/upload-modify/' + mail, file, {
      //return axios.post('http://localhost:3000/booking/upload/' + mail, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem("jwt") 
      },
    });
  },

  mailCustomerModify(mail){
    return API.get('/booking/mailCustomerModify/'+mail)
  },

  addTask(task){
    return API.post('/task', task)
  },

  addPlanner(planner){
    return API.post('/planner', planner)
  },

  calcDuration(data){
    return API.post('/planner/best-driver', data)
  },

  getDriverById(id){
    return API.get('/driver/'+ id)
  }

}