import API from './API';

export default {
  login(loginData) {
    console.log(loginData)
    return API.post('/auth/login/', loginData);
  },

  resetPassword(mail){
    return API.get('/auth/reset-password/'+mail)
  }
};