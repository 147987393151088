<template>
    <el-card :body-style="{ padding: '0px' }"  @click="handleCardClick" :class="{ 'highlight-card ': selectedCar }" >
        <el-row justify="center" >
            <img :src="`${imgSrc}`" class="image" />
            <div style="padding: 14px;  height: 150%;">
                <span><b>{{title}}</b></span>
                <hr style="background-color: rgb(231,228,58);height: 1px; margin-top: 7px;margin-bottom: 7px;">
                <span>{{label}}</span>
                <div v-if="isRouter && !isHome" class="bottom" style="margin-top: 10px">
                    <router-link :to="{ path: `${linkPath}`}" class="routerLink">
                        <el-button size="small" >
                            <el-icon>
                                <DArrowRight />
                            </el-icon>
                        </el-button>
                    </router-link>
                </div>
                <div v-if="isRouter && isHome" class="bottom" style="margin-top: 10px">
                    <router-link :to="{ path: '/', query: {q : 1}}" class="routerLink">
                        <el-button size="small" >
                            <el-icon>
                                <DArrowRight />
                            </el-icon>
                        </el-button>
                    </router-link>
                </div>
                <div v-else-if="!isRouter" class="bottom" style="margin-top: 10px">
                    <el-button size="small" >
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </div>
            </div>
        </el-row>
    </el-card>
</template>

<script>
export default {
 props: {
    title: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    imgSrc: {
        type: String,
        require: true
    },
    linkPath: {
        type: String,
        required: false
    },
    isRouter: {
        type: Boolean,
        required: true
    },
    selectedCar:{
        required:false
    },
    isHome: {
        type: Boolean,
        default: false,
        required: false
    },

 },
 methods: {
    handleCardClick() {
        this.$emit('click');
        this.$emit('cardClick');
    },
    handleClick() {
      
    },

 }
}
</script>

<style>
.highlight-card {
    border: 2px solid blue; /* Highlight style, adjust as needed */
    box-shadow: 100px 100px 100px rgba(0, 0, 0, 0.5);
  }
</style>