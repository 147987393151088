<template>
  <el-space wrap>
    <el-card style="height: 100%; width: 1200px">
      <template #header v-if="active === 0">
        <AddNewCustomerComponent></AddNewCustomerComponent>
        <div class="container-customer">
          <div>
            <PointComponent
              :value="customerExist"
              @input="(newValue) => (customerExist = newValue)"
              title="Controlla se il cliete è già stato registrato"
              label="email"
              placeholder="email"
            ></PointComponent>
          </div>
          <div>
            <ButtonComponent
              label="cerca"
              :isRouter="false"
              @buttonClick="checkCustomerExist"
            ></ButtonComponent>
          </div>
        </div>
      </template>

      <div v-if="active === 0">
        <div class="flex-container">
          <div class="select-component">
            <SelectComponent
              @selected="handleChangeCustomer"
              :options="customersId"
              title="Selezionare un cliente"
              :addButtom="false"
              :highlight="highlightCustomer"
              :isDisabled="false"
              :value="customerSelected"
            ></SelectComponent>
          </div>
          <div class="select-component">
            <SelectComponent
              @selected="handleChangeHeadquarter"
              :options="headquartersId"
              title="Selezionare una sede"
              :addButtom="false"
              :highlight="highlightHeadquarter"
              :isDisabled="false"
              :value="headquarterSelected"
            ></SelectComponent>
          </div>
        </div>

        <el-divider />

        <!--  <div class="container">
          <div>Verrà fornita l'auto al driver?</div>
          <div>
            <el-radio-group v-model="booking.loaner" @change="changeLoaner">
              <el-radio label="true">Sì</el-radio>
              <el-radio label="false">No</el-radio>
            </el-radio-group>
          </div>
        </div> -->

        <div class="container">
          <div>
            Verrà fornita l'auto al driver per accompagna il cliente al punto
            stabilito
          </div>
        </div>
        <el-divider />

        <el-form :model="escort_gone">
          <!-- <ButtonComponent :label="is_gone ? 'Escludi Andata' : 'Includi Andata'" :isRouter=false @buttonClick="deleteGone" :type="is_gone ? 'danger' : 'success'"></ButtonComponent> -->
          <div style="margin-top: -2%; text-align: left">
            <el-switch
              v-model="is_gone"
              class="custom-switch"
              active-text="Includi Andata"
              inactive-text="Escludi Andata"
              :active-value="true"
              :inactive-value="false"
            />
          </div>

          <div v-if="is_gone">
            <div class="container-data">
              <!-- <DateSelectComponent
                title="Data/ora di partenza dalla sede"
                :loading="loadingSlotsGone"
                :timeSlots="timeSlotsGone"
                :highlightSlot="highlightSlotGone"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateGone"
                @selectedSlot="handleSlotChangeGone"
                @datePartnerChange="handleChangeDateGone"
                :selectedSlotInput="selectedSlotGone"
              ></DateSelectComponent> -->
              <DateSelectComponent
                title="Data/ora di partenza dalla sede"
                :loading="loadingSlotsGone"
                :timeSlots="timeSlotsGone"
                :highlightSlot="highlightSlotGone"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateGone"
                @selectedSlot="handleSlotChangeGone"
                @datePartnerChange="handleChangeDateGone"
                :selectedSlotInput="selectedSlotGone"
                :disabledDates="disabledDate"
              ></DateSelectComponent>
            </div>
            <div class="container-point">
              <PointComponent
                :value="escort_gone.point_origin_escort.address"
                @input="
                  (newValue) =>
                    (escort_gone.point_origin_escort.address = newValue)
                "
                title="Punto di prelievo"
                label="Indirizzo"
                placeholder="Indirizzo"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_origin_escort.street_number"
                @input="
                  (newValue) =>
                    (escort_gone.point_origin_escort.street_number = newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_origin_escort.cap"
                @input="
                  (newValue) => (escort_gone.point_origin_escort.cap = newValue)
                "
                label="CAP"
                placeholder="CAP"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_origin_escort.city"
                @input="
                  (newValue) =>
                    (escort_gone.point_origin_escort.city = newValue)
                "
                :span="4"
                label="Città"
                placeholder="Città"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_origin_escort.province"
                @input="
                  (newValue) =>
                    (escort_gone.point_origin_escort.province = newValue)
                "
                label="Provincia"
                placeholder="Provincia"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>
            </div>
            <DescriptionComponent
              :label="escort_gone.point_origin_escort.label"
              :loading="loadingPoint"
            ></DescriptionComponent>

            <div class="container-point">
              <PointComponent
                :value="escort_gone.point_destination_escort.address"
                @input="
                  (newValue) =>
                    (escort_gone.point_destination_escort.address = newValue)
                "
                title="Punto di consegna"
                label="Indirizzo"
                placeholder="Indirizzo"
                :span="8"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_destination_escort.street_number"
                @input="
                  (newValue) =>
                    (escort_gone.point_destination_escort.street_number =
                      newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_destination_escort.cap"
                @input="
                  (newValue) =>
                    (escort_gone.point_destination_escort.cap = newValue)
                "
                label="CAP"
                placeholder="CAP"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_destination_escort.city"
                @input="
                  (newValue) =>
                    (escort_gone.point_destination_escort.city = newValue)
                "
                :span="4"
                label="Città"
                placeholder="Città"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="escort_gone.point_destination_escort.province"
                @input="
                  (newValue) =>
                    (escort_gone.point_destination_escort.province = newValue)
                "
                label="Provincia"
                placeholder="Provincia"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>
            </div>

            <DescriptionComponent
              :label="escort_gone.point_destination_escort.label"
              :loading="loadingPoint"
            ></DescriptionComponent>
          </div>
          <div class="info-container">
            <div class="info-item">
              <PriceComponent
                label="Fascia km"
                :value="`${responseKmGone} km`"
                tagType="info"
              />
            </div>
            <div class="info-item">
              <PriceComponent
                label="Tariffa"
                :value="`${responseCostGone} euro`"
                tagType="success"
              />
            </div>
          </div>
        </el-form>
        <el-divider />
        <div style="margin-top: -2%; text-align: left">
          <el-switch
            class="custom-switch"
            v-model="is_return"
            active-text="Includi Ritorno"
            inactive-text="Escludi Ritorno"
            :active-value="true"
            :inactive-value="false"
          />
        </div>

        <el-form :model="escort_return">
          <div v-if="is_return">
            <div class="container-data">
              <DateSelectComponent
                title="Data/ora di arrivo driver al punto di prelievo"
                :loading="loadingSlotsReturn"
                :timeSlots="timeSlotsReturn"
                :highlightSlot="highlightSlotReturn"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateReturn"
                @selectedSlot="handleSlotChangeReturn"
                :selectedSlotInput="selectedSlotReturn"
                @datePartnerChange="handleChangeDateReturn"
                :disabledDates="disabledDateReturn"
              ></DateSelectComponent>

              <!-- <DateSelectComponent
                title="Data/ora di arrivo driver al punto di prelievo"
                :loading="loadingSlotsReturn"
                :timeSlots="timeSlotsReturn"
                :highlightSlot="highlightSlotReturn"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateReturn"
                @selectedSlot="handleSlotChangeReturn"
                :selectedSlotInput="selectedSlotReturn"
                @datePartnerChange="handleChangeDateReturn"
              ></DateSelectComponent> -->
            </div>

            <div class="container-point">
              <PointComponent
                :value="escort_return.point_origin_escort.address"
                @input="
                  (newValue) =>
                    (escort_return.point_origin_escort.address = newValue)
                "
                title="Punto di prelievo"
                label="Indirizzo"
                placeholder="Indirizzo"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_origin_escort.street_number"
                @input="
                  (newValue) =>
                    (escort_return.point_origin_escort.street_number = newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_origin_escort.cap"
                @input="
                  (newValue) =>
                    (escort_return.point_origin_escort.cap = newValue)
                "
                label="CAP"
                placeholder="CAP"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_origin_escort.city"
                @input="
                  (newValue) =>
                    (escort_return.point_origin_escort.city = newValue)
                "
                :span="4"
                label="Città"
                placeholder="Città"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_origin_escort.province"
                @input="
                  (newValue) =>
                    (escort_return.point_origin_escort.province = newValue)
                "
                label="Provincia"
                placeholder="Provincia"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>
            </div>

            <DescriptionComponent
              :label="escort_return.point_origin_escort.label"
              :loading="loadingPoint"
            ></DescriptionComponent>

            <div class="container-point">
              <PointComponent
                :value="escort_return.point_destination_escort.address"
                @input="
                  (newValue) =>
                    (escort_return.point_destination_escort.address = newValue)
                "
                title="Punto di consegna"
                label="Indirizzo"
                placeholder="Indirizzo"
                :disabled="booking.loaner == 'true'"
                :span="8"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_destination_escort.street_number"
                @input="
                  (newValue) =>
                    (escort_return.point_destination_escort.street_number =
                      newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_destination_escort.cap"
                @input="
                  (newValue) =>
                    (escort_return.point_destination_escort.cap = newValue)
                "
                label="CAP"
                placeholder="CAP"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_destination_escort.city"
                @input="
                  (newValue) =>
                    (escort_return.point_destination_escort.city = newValue)
                "
                label="Città"
                placeholder="Città"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="escort_return.point_destination_escort.province"
                @input="
                  (newValue) =>
                    (escort_return.point_destination_escort.province = newValue)
                "
                label="Provincia"
                placeholder="Provincia"
                :disabled="booking.loaner == 'true'"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>
            </div>

            <DescriptionComponent
              :label="escort_return.point_destination_escort.label"
              :loading="loadingPoint"
            ></DescriptionComponent>

            <div class="info-container">
              <div class="info-item">
                <PriceComponent
                  label="Fascia km"
                  :value="`${responseKmReturn} km`"
                  tagType="info"
                />
              </div>
              <div class="info-item">
                <PriceComponent
                  label="Tariffa"
                  :value="`${responseCostReturn} euro`"
                  tagType="success"
                />
              </div>
            </div>
          </div>
        </el-form>

        <el-divider />

        <el-row style="margin-top: 2%">
          <el-col :span="18">
            <div class="form-label-2">
              Il cliente deve pagare dei lavori di officina?
            </div>
          </el-col>
          <el-col :span="5" style="margin: -1.3rem -36rem">
            <el-radio-group v-model="task" @change="changeValueTask">
              <el-radio label="true" size="large">Sì</el-radio>
              <el-radio label="false" size="large">No</el-radio>
            </el-radio-group>
          </el-col>
          <div
            v-if="task == 'true'"
            style="margin-left: 47%; margin-top: -1.8%"
          >
            <SelectComponent
              @selected="handleChangeCar"
              :options="carsId"
              title="Selezionare un'auto"
              :addButtom="true"
              :highlight="highlightCar"
              :linkPath="`/add-car/${customerSelected}`"
              :isDisabled="customerSelected == ''"
              :span="25"
              :value="carSelected"
            ></SelectComponent>
          </div>
        </el-row>

        <el-row justify="center" style="margin-top: -1%">
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/"
            @buttonClick="annulla"
          ></ButtonComponent>
          <ButtonComponent
            :label="task == 'true' ? 'Avanti' : 'Crea'"
            :isRouter="false"
            @buttonClick="handleButtonClickCrea"
          ></ButtonComponent>
        </el-row>
      </div>

      <div v-if="active === 1">
        <el-form :model="tasks">
          <el-scrollbar height="250px">
            <div style="padding-bottom: 2%; padding-top: 2%">
              Aggiungi dei lavori di manutenzione alla vettura:
            </div>
            <div
              v-for="(task, counter_task) in tasks"
              v-bind:key="counter_task"
              width="100%"
            >
              <div class="task-row">
                <AggiungiLavori
                  @description="(newValue) => (task.description = newValue)"
                  @cost="(newValue) => (task.cost = newValue)"
                  @pay="(newValue) => (task.pay = newValue)"
                  :highlightDescription="highlightDescription"
                  :highlightCost="highlightCost"
                ></AggiungiLavori>

                <div style="margin-bottom: 2%">
                  <ButtonComponent
                    :ifCondition="counter_task != 0 ? true : false"
                    :isRouter="false"
                    @buttonClick="rimuoviTask($event, counter_task)"
                    type="danger"
                    icon="Delete"
                  ></ButtonComponent>
                </div>
              </div>
            </div>
            <ButtonComponent
              label="Aggiungi lavori"
              :isRouter="false"
              @buttonClick="aggiungiTask"
              type="success"
            ></ButtonComponent>
          </el-scrollbar>

          <br />
          <br />
          <br />
          <el-divider />

          <div>
            <UploadFile
              label="Carica preventivo"
              @note="handleChangeNote"
              @uploadChange="onFileSelected"
              style="margin-top: 1%"
            ></UploadFile>
          </div>

          <el-row justify="center" style="margin-top: 3.5%">
            <ButtonComponent
              label="Indietro"
              :isRouter="false"
              @buttonClick="back"
            ></ButtonComponent>
            <ButtonComponent
              label="Crea"
              :isRouter="false"
              @buttonClick="onSubmit"
            ></ButtonComponent>
          </el-row>
        </el-form>
      </div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="Step 1" />
        <el-step title="Step 2" />
      </el-steps>
    </el-card>
  </el-space>
</template>

<script>
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import store from '../store/prenotazione.js';
import carAPI from '../services/carAPI.js';
import bookingAPI from '@/services/bookingAPI';
import customerAPI from '../services/customerAPI';
import partnerAPI from '@/services/partnerAPI';
import PointComponent from './PointComponent.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import { reactive, ref, toRaw } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import { isEmpty } from 'lodash';
import { useRouter } from 'vue-router';
import SelectComponent from './SelectComponent.vue';
import { isLoggedStore } from '../store/logged';
import AddNewCustomerComponent from './AddNewCustomerComponent.vue';
import DateSelectComponent from './DateSelectComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import DescriptionComponent from './DescriptionComponent.vue';
import AggiungiLavori from './AggiungiLavori.vue';
import PriceComponent from './PriceComponent.vue';

import UploadFile from './UploadFile';
const dayjs = require('dayjs');

export default {
  name: 'EscortComponent',
  components: {
    AddNewCustomerComponent,
    SelectComponent,
    PointComponent,
    DateSelectComponent,
    ButtonComponent,
    DescriptionComponent,
    AggiungiLavori,
    PriceComponent,
    UploadFile,
  },

  setup() {
    const storeLogin = isLoggedStore();

    const router = useRouter();
    const changeValueTask = (val) => {
      task.value = val;
      isAvantiButtonDisabled();
    };

    let task = ref('false');
    const tasks = reactive([
      {
        description: '',
        cost: 0,
        pay: false,
        accepted: false,
      },
    ]);

    //let task_radio = ref("false");

    const booking = reactive({
      payed: false,
      imponibile: 0,
      tot_fattura: 0,
      rif_fattura: 0,
      liquidato: false,
      data: new Date(),
      over_twentysix: false,
      loaner: 'true',
      targa_loaner: '',
      state: 'before', //status:"before"
      type: 'both',
      note: '',
      customer: store.customerSelectedObj,
      //company: {},
      //car: {},
      employee: storeLogin.employee,
      type_prenotazione: 'escort',
      headquarter: store.heaquarterSelectedObj,

      escort_bookings: [],
    });

    /* let tasks= [{
                description: "",
                cost: 0,
                pay: false,
                accepted: true
            }], */

    const escort_gone = reactive({
      date_partner: '',
      date_customer: '',
      date_driver: '',
      date_jolly_scan: '',
      true_date_partner: '',
      true_date_customer: '',
      true_date_driver: '',
      simba_km: 0,
      simba_cost: 0.0,
      simba_minute: 0,
      type_booking: 'gone',
      order: 0,
      tasks: false,
      driver_escort: null,
      planner: null,
      point_origin_escort: {
        address: store.heaquarterSelectedObj.address,
        street_number: store.heaquarterSelectedObj.street_number,
        cap: store.heaquarterSelectedObj.cap,
        city: store.heaquarterSelectedObj.city,
        province: store.heaquarterSelectedObj.province,
        label: '',
        latitude: 0,
        longitude: 0,
        trip: null,
      },
      point_destination_escort: {
        address: store.originPointAddress, //"",
        street_number: store.originPointStreetNumber, //"",
        cap: store.originPointCity, //"",
        city: store.originPointCap, //"",
        province: store.originPointProvince, //"",
        label: '',
        latitude: 0,
        longitude: 0,
        trip: null,
      },
      planners: [],
    });

    const escort_return = reactive({
      date_partner: '',
      new_date_partner: null,
      date_customer: '',
      date_driver: '',
      date_jolly_scan: '',
      true_date_partner: '',
      true_date_customer: '',
      true_date_driver: '',
      simba_km: 0,
      simba_cost: 0.0,
      simba_minute: 0,
      type_booking: 'return',
      order: 0,
      tasks: false,
      driver_escort: null,
      planner: null,
      point_origin_escort: {
        address: store.destinationPointAddress,
        street_number: store.destinationPointStreetNumber,
        cap: store.destinationPointCity,
        city: store.destinationPointCap,
        province: store.destinationPointProvince,
        label: '',
        latitude: 0,
        longitude: 0,
        trip: null,
      },
      point_destination_escort: {
        address: store.heaquarterSelectedObj.address,
        street_number: store.heaquarterSelectedObj.street_number,
        cap: store.heaquarterSelectedObj.cap,
        city: store.heaquarterSelectedObj.city,
        province: store.heaquarterSelectedObj.province,
        label: '',
        latitude: 0,
        longitude: 0,
        trip: null,
      },
      planners: [],
    });

    let customerExist = ref('');

    const checkCustomerExist = async () => {
      try {
        let request = {
          mail: customerExist.value,
        };

        const response = await customerAPI.checkCustomerExist(request);

        if (response.data.exist == true) {
          ElMessageBox.confirm(
            `Nome: ${response.data.customer.name}<br>Cognome: ${response.data.customer.surname}<br>Email: ${response.data.customer.mail}`,
            'Cliente Trovato',
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: 'Aggiungi il cliente',
              cancelButtonText: 'Annulla',
            }
          )
            .then(() => {
              addPartnerToCustomer();
            })
            .catch(() => {});
        } else {
          ElMessageBox.confirm(
            'Il cliente non esiste, registralo prima di prenotare',
            {
              confirmButtonText: 'Registra un cliente',
              cancelButtonText: 'Cancel',
              type: 'warning',
              customClass: 'custom-message-box',
            }
          )
            .then(() => {
              router.push('/addcustomer_employee');
            })
            .catch(() => {});
        }
      } catch (error) {
        ElMessage({
          message: 'Si è verificato un errore durante la verifica del cliente',
          type: 'error',
        });
        console.log(
          error,
          'Si è verificato un errore durante la verifica del cliente'
        );
      }
    };

    const addPartnerToCustomer = async () => {
      const responsePartner = await partnerAPI.getPartnerByEmployee(
        storeLogin.id
      );

      const partner = responsePartner.data[0].headquarters[0].partner;

      try {
        let request = {
          mail: customerExist.value,
          partner: partner,
        };

        const response = await customerAPI.addPartnerToCustomer(request);

        booking.customer = response.data.customer;

        store.customerSelectedObj = response.data.customer;

        const customerExistsInStore = customersId.value.some(
          (customer) => customer.value === response.data.customer.id
        );

        if (!customerExistsInStore) {
          store.customers.push(response.data.customer);
        }

        //store.customers.push(response.data.customer);

        let tableRow = {
          value: booking.customer.id,
          label: `${booking.customer.name} ${booking.customer.surname}, ${booking.customer.mail}`,
        };
        if (!customerExistsInStore) {
          store.customersCascader.push(tableRow);
        }
        customerSelected.value = response.data.customer.id;
        //store.customersCascader.push(tableRow);

        loadCars(booking.customer.id);
      } catch (error) {
        console.log(error);
      }
    };

    //cliente
    let customerSelected = ref(store.customerSelected);
    const customersId = ref(store.customersCascader);
    const customers = ref(store.customers);

    const customerId = {
      customer_id: '',
    };

    const handleChangeCustomer = async (val) => {
      customerSelected.value = val;

      customerSelected.value = val;
      carsId.value = [];
      booking.customer = customers.value.filter(
        (customer) => customer.id == val
      )[0];

      store.customerSelected = booking.customer.id;
      store.customerSelectedObj = booking.customer;

      escort_gone.point_destination_escort.address = booking.customer.address;
      escort_gone.point_destination_escort.street_number =
        booking.customer.street_number;
      escort_gone.point_destination_escort.cap = booking.customer.cap;
      escort_gone.point_destination_escort.city = booking.customer.city;
      escort_gone.point_destination_escort.province = booking.customer.province;
      escort_gone.point_destination_escort.latitude = booking.customer.latitude;
      escort_gone.point_destination_escort.longitude =
        booking.customer.longitude;

      store.originPointAddress = escort_gone.point_destination_escort.address;
      store.originPointStreetNumber =
        escort_gone.point_destination_escort.street_number;
      store.originPointCity = escort_gone.point_destination_escort.cap;
      store.originPointCap = escort_gone.point_destination_escort.city;
      store.originPointProvince = escort_gone.point_destination_escort.province;

      escort_return.point_origin_escort.address = booking.customer.address;
      escort_return.point_origin_escort.street_number =
        booking.customer.street_number;
      escort_return.point_origin_escort.cap = booking.customer.cap;
      escort_return.point_origin_escort.city = booking.customer.city;
      escort_return.point_origin_escort.province = booking.customer.province;
      escort_return.point_origin_escort.latitude = booking.customer.latitude;
      escort_return.point_origin_escort.longitude = booking.customer.longitude;

      store.destinationPointAddress = escort_return.point_origin_escort.address;
      store.destinationPointStreetNumber =
        escort_return.point_origin_escort.street_number;
      store.destinationPointCity = escort_return.point_origin_escort.cap;
      store.destinationPointCap = escort_return.point_origin_escort.city;
      store.destinationPointProvince =
        escort_return.point_origin_escort.province;

      let resposnePointDestination = await loadPoints(
        escort_gone.point_destination_escort
      );

      escort_gone.point_destination_escort.label =
        resposnePointDestination.label;
      escort_gone.point_destination_escort.latitude =
        resposnePointDestination.point.latitude;
      escort_gone.point_destination_escort.longitude =
        resposnePointDestination.point.longitude;

      let resposnePoinOrigin = await loadPoints(
        escort_return.point_origin_escort
      );

      escort_return.point_origin_escort.label = resposnePoinOrigin.label;
      escort_return.point_origin_escort.latitude =
        resposnePoinOrigin.point.latitude;
      escort_return.point_origin_escort.longitude =
        resposnePoinOrigin.point.longitude;

      customerId.customer_id = val[0];

      loadCars(val[0]);
      //loadMinutes(val[0]);
      isAvantiButtonDisabled();
      calcolaKmGone();
      calcolaKmReturn();
    };

    //macchina

    let carsId = ref([]);
    const cars = ref([]);

    const storecheck = () => {
      if (store.customerSelected) loadCars(store.customerSelected);
    };

    const loadCars = async (customer_id) => {
      try {
        const partner = await partnerAPI.getPartnerByEmployee(
          booking.employee.id
        );
        const response = await carAPI.getCarsByCustomer(
          customer_id,
          partner.data[0].headquarters[0].partner.id
        );
        let tableRow = {};

        response.data.forEach((car) => {
          if (car.minutes.length != 0) {
            car.minutes.forEach((minute) => {
              if (minute.accepted == true) {
                tableRow = {
                  value: car.id,
                  label: `${car.marca} ${car.modello} ${car.targa}`,
                };
                carsId.value.push(tableRow);
                cars.value.push(car);
              }
            });
          } else {
            tableRow = {
              value: car.id,
              label: `${car.marca} ${car.modello} ${car.targa}`,
            };
            carsId.value.push(tableRow);
            cars.value.push(car);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    storecheck();
    //macchina
    let carSelected= ref(store.carSelected);

    const handleChangeCar = async (val) => {
      booking.car = cars.value.filter((car) => car.id == val)[0];
      carSelected.value=booking.car.id;

      isAvantiButtonDisabled();
      //loadMinutes();
    };

    //partner
    const partnersId = ref([]);
    const partners = ref([]);

    //sede
    let headquarterSelected = ref(store.heaquarterSelected);
    let headquartersId = ref(store.headquartersCascader);
    const headquarters = ref(store.headquarters);

    const response_minute_gone = ref(0);
    const response_minute_return = ref(0);

    const handleChangeHeadquarter = async (val) => {
      headquarterSelected.value = val;
      booking.headquarter = headquarters.value.filter(
        (headquarter) => headquarter.id == val
      )[0];

      store.heaquarterSelected = booking.headquarter.id;
      store.heaquarterSelectedObj = booking.headquarter;

      escort_gone.point_origin_escort.address = booking.headquarter.address;
      escort_gone.point_origin_escort.street_number =
        booking.headquarter.street_number;
      escort_gone.point_origin_escort.cap = booking.headquarter.cap;
      escort_gone.point_origin_escort.city = booking.headquarter.city;
      escort_gone.point_origin_escort.province = booking.headquarter.province;
      escort_gone.point_origin_escort.latitude = booking.headquarter.latitude;
      escort_gone.point_origin_escort.longitude = booking.headquarter.longitude;

      escort_return.point_destination_escort.address =
        booking.headquarter.address;
      escort_return.point_destination_escort.street_number =
        booking.headquarter.street_number;
      escort_return.point_destination_escort.cap = booking.headquarter.cap;
      escort_return.point_destination_escort.city = booking.headquarter.city;
      escort_return.point_destination_escort.province =
        booking.headquarter.province;
      escort_return.point_destination_escort.latitude =
        booking.headquarter.latitude;
      escort_return.point_destination_escort.longitude =
        booking.headquarter.longitude;

      let resposnePointDestination = await loadPoints(
        escort_gone.point_origin_escort
      );

      escort_gone.point_origin_escort.label = resposnePointDestination.label;
      escort_gone.point_origin_escort.latitude =
        resposnePointDestination.point.latitude;
      escort_gone.point_origin_escort.longitude =
        resposnePointDestination.point.longitude;

      let resposnePoinOrigin = await loadPoints(
        escort_return.point_destination_escort
      );

      escort_return.point_destination_escort.label = resposnePoinOrigin.label;
      escort_return.point_destination_escort.latitude =
        resposnePoinOrigin.point.latitude;
      escort_return.point_destination_escort.longitude =
        resposnePoinOrigin.point.longitude;

      calcolaKmGone();
      calcolaKmReturn();
      isAvantiButtonDisabled();
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });
        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    //pulsante "avanti" disabilitato prima di aver inserito tutte le informazioni
    let button1_disabled = ref(true);

    const isAvantiButtonDisabled = () => {
      const pickupGone = escort_gone.point_origin_escort;

      const pickupReturn = escort_return.point_destination_escort;

      if (is_return.value && is_gone.value) {
        if (
          (pickupGone.address == '' &&
            pickupGone.street_number == '' &&
            pickupGone.city == '' &&
            pickupGone.province == '' &&
            pickupGone.cap == '') ||
          (pickupReturn.address == '' &&
            pickupReturn.street_number == '' &&
            pickupReturn.city !== '' &&
            pickupReturn.province == '' &&
            pickupReturn.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          escort_gone.date_partner == '' ||
          escort_return.date_partner == '' ||
          (task.value == 'true' && isEmpty(toRaw(booking.car)))
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      } else if (!is_return.value && is_gone.value) {
        if (
          (pickupGone.address == '' &&
            pickupGone.street_number == '' &&
            pickupGone.city == '' &&
            pickupGone.province == '' &&
            pickupGone.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          escort_gone.date_partner == '' ||
          (task.value == 'true' && isEmpty(toRaw(booking.car)))
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      } else if (is_return.value && !is_gone.value) {
        if (
          (pickupReturn.address == '' &&
            pickupReturn.street_number == '' &&
            pickupReturn.city == '' &&
            pickupReturn.province == '' &&
            pickupReturn.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          escort_return.date_partner == '' ||
          (task.value == 'true' && isEmpty(toRaw(booking.car)))
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      }
      return true;
    };

    //scelta di solo andata, solo ritorno o entrambi
    let is_gone = ref(true);
    let is_return = ref(true);

    const deleteGone = () => {
      is_gone.value = !is_gone.value;

      isAvantiButtonDisabled();
    };

    const deleteReturn = () => {
      is_return.value = !is_return.value;
      isAvantiButtonDisabled();
    };

    //ottengo slot disponibili
    const selectedSlotGone = ref(null);
    const selectedSlotReturn = ref(null);
    const timeSlotsGone = ref([
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);
    const timeSlotsReturn = ref([
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDateGone = async (val) => {
      try {
        escort_gone.date_partner = val;
        const selectedDate = dayjs(escort_gone.date_partner).format(
          'YYYY-MM-DD'
        );
        //selectedSlotGone.value = null;
        //console.log("selectedDate ",selectedDate)
        await loadSlotGone(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeDateReturn = async (val) => {
      try {
        escort_return.date_partner = val;
        const selectedDate = dayjs(escort_return.date_partner).format(
          'YYYY-MM-DD'
        );
        //selectedSlotGone.value = null;
        await loadSlotReturn(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    let loadingSlotsGone = ref(true);
    let loadingSlotsReturn = ref(true);

    let request_slot_gone = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };
    const SlotGone = async (date) => {
      loadingSlotsGone.value = true;
      let newSlot = '';
      handleSlotChangeGone(newSlot);

      try {
        if (
          !isEmpty(toRaw(escort_gone.point_origin_escort)) &&
          !isEmpty(toRaw(escort_gone.point_destination_escort))
        ) {
          if (booking.loaner == 'false') {
            request_slot_gone.originPoint = toRaw(
              escort_gone.point_origin_escort
            );

            request_slot_gone.destinationPoint = toRaw(
              escort_gone.point_destination_escort
            );
            request_slot_gone.type = 'ESCORT_GONE';
            request_slot_gone.partnerDay = date;
          } else {
            request_slot_gone.originPoint = toRaw(
              escort_gone.point_origin_escort
            );
            request_slot_gone.destinationPoint = toRaw(
              escort_gone.point_destination_escort
            );
            request_slot_gone.type = 'ESCORT_AUTO_GONE';
            request_slot_gone.partnerDay = date;
          }
          request_slot_gone.area = booking.headquarter.area;
          const response = await prenotazioneAPI.calcSlot(request_slot_gone);
          loadingSlotsGone.value = false;
          return response.data;
        }
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };
    let request_slot_return = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };
    const SlotReturn = async (date) => {
      loadingSlotsReturn.value = true;
      let newSlot = '';
      handleSlotChangeReturn(newSlot);
      try {
        if (
          !isEmpty(toRaw(escort_return.point_origin_escort)) &&
          !isEmpty(toRaw(escort_return.point_destination_escort))
        ) {
          if (booking.loaner == 'false') {
            request_slot_return.originPoint = toRaw(
              escort_return.point_destination_escort
            );
            request_slot_return.destinationPoint = toRaw(
              escort_return.point_origin_escort
            );
            request_slot_return.type = 'ESCORT_RETURN';
            request_slot_return.partnerDay = date;
          } else {
            request_slot_return.originPoint = toRaw(
              escort_return.point_destination_escort
            );
            request_slot_return.destinationPoint = toRaw(
              escort_return.point_origin_escort
            );
            request_slot_return.type = 'ESCORT_AUTO_RETURN';
            request_slot_return.partnerDay = date;
          }
          request_slot_return.area = booking.headquarter.area;
          console.log(
            '🚀 ~ SlotReturn ~ request_slot_return:',
            request_slot_return
          );
          const response = await prenotazioneAPI.calcSlot(request_slot_return);
          console.log('🚀 ~ SlotReturn ~ response:', response);
          loadingSlotsReturn.value = false;
          return response.data;
        }
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const mergeSlotsGone = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotGone(selectedDate);
      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);
          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const mergeSlotsReturn = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotReturn(selectedDate);
      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          let isAvailable = availableSlots.includes(timeSlot.value);
          const [hour, minute] = timeSlot.value.split(':').map(Number);

          const newDate = dayjs(selectedDate)
            .hour(hour)
            .minute(minute)
            .second(0)
            .toDate();

          isAvailable =
            isAvailable && is_gone.value
              ? new Date(newDate).getTime() >
                new Date(escort_gone.date_partner).getTime()
              : isAvailable;

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const loadSlotGone = async (selectedDate) => {
      if (selectedDate != '') {
        const mergedSlotsGone = await mergeSlotsGone(
          timeSlotsGone.value,
          selectedDate
        );
        timeSlotsGone.value.splice(
          0,
          timeSlotsGone.value.length,
          ...mergedSlotsGone
        );
      }
    };

    const loadSlotReturn = async (selectedDate) => {
      if (selectedDate != '') {
        //selectedSlotGone.value=null;
        const mergedSlotsReturn = await mergeSlotsReturn(
          timeSlotsReturn.value,
          selectedDate
        );
        timeSlotsReturn.value.splice(
          0,
          timeSlotsReturn.value.length,
          ...mergedSlotsReturn
        );
      }
    };

    const handleSlotChangeGone = (value) => {
      selectedSlotGone.value = value;
      if (value != '') {
        escort_gone.date_partner = dayjs(escort_gone.date_partner)
          .hour(value[0].split(':')[0])
          .minute(value[0].split(':')[1])
          .second(0)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
      isAvantiButtonDisabled();
    };

    const handleSlotChangeReturn = (value) => {
      selectedSlotReturn.value = value;
      if (value != '') {
        escort_return.date_partner = dayjs(new Date(escort_return.date_partner))
          .hour(value[0].split(':')[0])
          .minute(value[0].split(':')[1])
          .second(0)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
      isAvantiButtonDisabled();
    };

    //scelta dell'auto di cortesia da fornire al cliente
    const changeLoaner = (val) => {
      booking.loaner = val;

      if (is_gone.value && is_return.value) {
        calcolaKmGone();
        calcolaKmReturn();
        //selectedSlotGone.value = null;
        //selectedSlotReturn.value = null;

        if (escort_gone.date_partner != '')
          loadSlotGone(escort_gone.date_partner);
        if (escort_return.date_partner != '')
          loadSlotReturn(escort_return.date_partner);
      } else if (is_gone.value && !is_return.value) {
        calcolaKmGone();
        selectedSlotGone.value = null;
        if (escort_gone.date_partner != '')
          loadSlotGone(escort_gone.date_partner);
      } else if (!is_gone.value && is_return.value) {
        calcolaKmReturn();
        selectedSlotReturn.value = null;
        if (escort_return.date_partner != '')
          loadSlotReturn(escort_return.date_partner);
      }
    };

    let button2_disabled = ref(true);
    //gestione step della prenotazione
    let active = ref(0);

    let highlightCustomer = ref(false);
    let highlightHeadquarter = ref(false);
    let highlightCar = ref(false);

    let highlightDateGone = ref(false);
    let highlightDateReturn = ref(false);
    let highlightSlotGone = ref(false);
    let highlightSlotReturn = ref(false);

    const checkHighlight = () => {
      if (!customerSelected.value) {
        ElMessage.error('Perfavore inserisci un cliente');
        highlightCustomer.value = true;
        return false;
      } else if (!headquarterSelected.value) {
        ElMessage.error('Perfavore inserisci una sede');
        highlightHeadquarter.value = true;
        return false;
      } else if (is_gone.value && escort_gone.date_partner == '') {
        ElMessage.error('Perfavore inserisci una data');
        highlightDateGone.value = true;
        return false;
      } else if (is_gone.value && !selectedSlotGone.value) {
        ElMessage.error('Perfavore inserisci un orario');
        highlightSlotGone.value = true;
        return false;
      } else if (is_return.value && escort_return.date_partner == '') {
        ElMessage.error('Perfavore inserisci una data');
        highlightDateReturn.value = true;
        return false;
      } else if (is_return.value && !selectedSlotReturn.value) {
        ElMessage.error('Perfavore inserisci un orario');
        highlightSlotReturn.value = true;
        return false;
      }

      highlightCustomer.value = false;
      highlightHeadquarter.value = false;
      highlightDateGone.value = false;
      highlightDateReturn.value = false;
      highlightSlotGone.value = false;
      highlightSlotReturn.value = false;
      return true;
    };

    const next = () => {
      if (checkHighlight()) {
        if (active.value++ > 1) {
          active.value = 0;
        }
      }
    };

    //upload file
    let selectedFile1 = null;
    let fd = new FormData();
    const onFileSelected = (e) => {
      for (let file of e.target.files) {
        selectedFile1 = file;
        fd.append('files', selectedFile1, selectedFile1.name);
      }
      return fd;
    };

    const fileLoad = async (fd) => {
      try {
        const mail = booking.customer.mail;
        await prenotazioneAPI.upload(fd, mail).then((resp) => {
          if (resp.status == 201) {
            console.log('riuscito');
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    //range del booking
    let responseKmGone = ref(store.rangeKmGoneEscort);
    let responseKmReturn = ref(store.rangeKmReturnEscort);
    let responseCostGone = ref(store.rangeCostGoneEscort);
    let responseCostReturn = ref(store.rangeCostReturnEscort);

    let request = {
      headquarter_id: '',
      origins_point: {},
      destination_point: '',
      loaner: false,
      area: booking.headquarter.area,
    };

    const calcolaKmGone = async () => {
      try {
        request.headquarter_id = booking.headquarter.id;
        request.origins_point = toRaw(escort_gone.point_origin_escort);
        request.destination_point = toRaw(escort_gone.point_destination_escort);
        request.loaner = booking.loaner.toLowerCase() === 'true';

        if (
          !isEmpty(toRaw(booking.headquarter)) //&&
          //!isEmpty(toRaw(booking.customer))
        ) {
          const response = await bookingAPI.getRangeEscort(request);
          escort_gone.simba_minute = response.data.minute;
          escort_gone.simba_km = response.data.km;
          escort_gone.simba_imponibile = response.data.imponibile;
          escort_gone.simba_km_range = response.data.kmRange;

          responseKmGone.value = response.data.kmRange;
          responseCostGone.value = response.data.cost;

          store.rangeKmGoneEscort = response.data.kmRange;
          store.rangeCostGoneEscort = response.data.cost;

          //store.heaquarterSelectedObj = request.origins_point;

          //store.destinationPointAddress = request.destination_point.address;
          //store.destinationPointStreetNumber = request.destination_point.street_number;
          //store.destinationPointCity = request.destination_point.city;
          //store.destinationPointCap = request.destination_point.cap;
          //store.destinationPointProvince = request.destination_point.province;
        }
      } catch (error) {
        console.log(error);
      }
    };
    calcolaKmGone();

    const calcolaKmReturn = async () => {
      request.headquarter_id = booking.headquarter.id;
      request.origins_point = toRaw(escort_return.point_origin_escort);
      request.destination_point = toRaw(escort_return.point_destination_escort);
      request.loaner = booking.loaner.toLowerCase() === 'true';

      if (
        !isEmpty(toRaw(booking.headquarter)) //&&
        //!isEmpty(toRaw(booking.customer))
      ) {
        const response = await bookingAPI.getRangeEscort(request);

        escort_return.simba_minute = response.data.minute;
        escort_return.simba_km = response.data.km;
        escort_return.simba_imponibile = response.data.imponibile;
        escort_return.simba_km_range = response.data.kmRange;

        responseKmReturn.value = response.data.kmRange;
        responseCostReturn.value = response.data.cost;

        store.rangeKmReturnEscort = response.data.kmRange;
        store.rangeCostReturnEscort = response.data.cost;

        //store.originPointAddress = request.origins_point.address;
        //store.originPointStreetNumber = request.origins_point.street_number;
        //store.originPointCity = request.origins_point.city;
        //store.originPointCap = request.origins_point.cap;
        //store.originPointProvince = request.origins_point.province;

        //store.destinationPointAddress = store.heaquarterSelectedObj;
      }
    };
    calcolaKmReturn();

    isAvantiButtonDisabled();

    const rimuoviTask = (e, counter) => {
      tasks.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiTask = (e) => {
      tasks.push({
        description: '',
        cost: 0,
        pay: false,
      });
      e.preventDefault();
    };

    const handleButtonClickCrea = () => {
      if (task.value == 'true') {
        next();
      } else {
        onSubmit(booking);
      }
    };

    const addBookingNew = async (booking) => {
      console.log('🚀 ~ addBookingNew ~ booking:', booking);
      let response = {};
      try {
        booking.loaner = booking.loaner.toLowerCase() === 'true';

        if (task.value == 'true') {
          booking.tasks = tasks;

          if (!checkTask()) {
            return;
          }
        }

        if (booking.loaner) {
          booking.type_prenotazione = 'escort_loaner';
        }

        let newPlannerGone = null;
        let newPlannerReturn = null;

        if (is_gone.value && is_return.value) {
          booking.type = 'both';
          booking.imponibile =
            escort_gone.simba_imponibile + escort_return.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          booking.data = escort_gone.date_partner;

          escort_gone.tasks = task.value.toLowerCase() === 'true';
          escort_return.tasks = task.value.toLowerCase() === 'true';

          if (booking.loaner) {
            newPlannerGone = await addPlannerNew(
              toRaw(escort_gone.point_origin_escort),
              toRaw(escort_gone.point_destination_escort),
              escort_gone.date_partner,
              'ESCORT_AUTO_GONE'
            );
            escort_gone.planners.push(newPlannerGone.data.plannerDriver);

            newPlannerReturn = await addPlannerNew(
              toRaw(escort_return.point_origin_escort),
              toRaw(escort_return.point_destination_escort),
              escort_return.date_partner,
              'ESCORT_AUTO_RETURN'
            );
            escort_return.planners.push(newPlannerReturn.data.plannerDriver);

            escort_gone.date_customer =
              newPlannerGone.data.driver.trips[0].dateStart;
            escort_return.date_customer =
              newPlannerReturn.data.driver.trips[0].dateStart;
          } else {
            newPlannerGone = await addPlannerNew(
              toRaw(escort_gone.point_origin_escort),
              toRaw(escort_gone.point_destination_escort),
              escort_gone.date_partner,
              'ESCORT_GONE'
            );
            escort_gone.planners.push(newPlannerGone.data.plannerDriver);
            escort_gone.planners.push(newPlannerGone.data.plannerJolly);

            newPlannerReturn = await addPlannerNew(
              toRaw(escort_return.point_origin_escort),
              toRaw(escort_return.point_destination_escort),
              escort_return.date_partner,
              'ESCORT_RETURN'
            );
            escort_return.planners.push(newPlannerReturn.data.plannerDriver);
            escort_return.planners.push(newPlannerReturn.data.plannerJolly);

            escort_gone.date_customer =
              newPlannerGone.data.driver.trips[0].dateStart;
            escort_return.date_customer =
              newPlannerReturn.data.driver.trips[0].dateStart;
          }

          escort_gone.driver_escort = newPlannerGone.data.driver.driverId;
          escort_return.driver_escort = newPlannerReturn.data.driver.driverId;

          booking.escort_bookings.push(escort_gone);
          booking.escort_bookings.push(escort_return);
        } else if (is_gone.value && !is_return.value) {
          booking.type = 'gone';
          booking.data = escort_gone.date_partner;
          escort_gone.tasks = task.value.toLowerCase() === 'true';
          booking.imponibile = escort_gone.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          if (booking.loaner) {
            newPlannerGone = await addPlannerNew(
              toRaw(escort_gone.point_origin_escort),
              toRaw(escort_gone.point_destination_escort),
              escort_gone.date_partner,
              'ESCORT_AUTO_GONE'
            );
            escort_gone.planners.push(newPlannerGone.data.plannerDriver);
            escort_gone.date_customer =
              newPlannerGone.data.driver.trips[0].dateStart;
          } else {
            newPlannerGone = await addPlannerNew(
              toRaw(escort_gone.point_origin_escort),
              toRaw(escort_gone.point_destination_escort),
              escort_gone.date_partner,
              'ESCORT_GONE'
            );
            escort_gone.planners.push(newPlannerGone.data.plannerDriver);
            escort_gone.planners.push(newPlannerGone.data.plannerJolly);

            escort_gone.date_customer =
              newPlannerGone.data.driver.trips[0].dateStart;
          }

          escort_gone.driver_escort = newPlannerGone.data.driver.driverId;

          booking.escort_bookings.push(escort_gone);
        } else if (!is_gone.value && is_return.value) {
          booking.type = 'return';
          booking.data = escort_return.date_partner;
          escort_return.tasks = task.value.toLowerCase() === 'true';
          booking.imponibile = escort_return.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          if (booking.loaner) {
            newPlannerReturn = await addPlannerNew(
              toRaw(escort_return.point_origin_escort),
              toRaw(escort_return.point_destination_escort),
              escort_return.date_partner,
              'ESCORT_AUTO_RETURN'
            );
            console.log(
              '🚀 ~ addBookingNew ~ newPlannerReturn:',
              newPlannerReturn
            );
            escort_return.planners.push(newPlannerReturn.data.plannerDriver);

            escort_return.date_customer =
              newPlannerReturn.data.driver.trips[0].dateStart;
          } else {
            newPlannerReturn = await addPlannerNew(
              toRaw(escort_return.point_origin_escort),
              toRaw(escort_return.point_destination_escort),
              escort_return.date_partner,
              'ESCORT_RETURN'
            );
            escort_return.planners.push(newPlannerReturn.data.plannerDriver);
            escort_return.planners.push(newPlannerReturn.data.plannerJolly);

            escort_return.date_customer =
              newPlannerReturn.data.driver.trips[0].dateStart;
          }
          escort_return.driver_escort = newPlannerReturn.data.driver.driverId;

          booking.escort_bookings.push(escort_return);
        }

        response = await prenotazioneAPI.addBooking(booking);
        await prenotazioneAPI.addBookingNotification({
          customer: booking.customer,
          booking: response.data,
          area: booking.headquarter.area,
        });

        return response;
      } catch (error) {
        ElMessage({
          message: 'Errore response!',
          type: 'error',
        });
        console.log(error);
      }
    };

    const addPlannerNew = async (
      originPoint,
      destinationPoint,
      partnerTime,
      type
    ) => {
      try {
        const data = {
          originPoint: originPoint,
          destinationPoint: destinationPoint,
          partnerTime: partnerTime,
          type: type,
          area: booking.headquarter.area,
        };
        console.log('🚀 ~ setup ~ data:', data);

        let response = await prenotazioneAPI.calcDuration(data);

        return response;
      } catch (error) {
        ElMessage({
          message: 'Errore response!',
          type: 'error',
        });
        console.log(error);
      }
    };

    let highlightDescription = ref(false);
    let highlightCost = ref(false);

    const checkTask = () => {
      for (let task of booking.tasks) {
        if (task.description == '') {
          ElMessage.error('Perfavore inserisci una descrizione dei lavori');
          highlightDescription.value = true;
          return false;
        }

        if (!task.cost) {
          ElMessage.error('Perfavore inserisci un costo dei lavori');
          highlightCost.value = true;
          return false;
        }

        const floatRegex = /^-?\d+(\.\d+)?$/;
        if (!floatRegex.test(task.cost)) {
          highlightCost.value = true;
          ElMessage.error('Perfavore inserisci un costo valido');
          return false;
        }
        if (task.cost.includes(',')) {
          highlightCost.value = true;
          ElMessage.error('Perfavore non usare virgole per il costo');
          return false;
        }

        highlightDescription.value = false;
        highlightCost.value = false;
      }
      return true;
    };

    const onSubmit = async () => {
      if (!checkHighlight()) {
        return;
      }

      addBookingNew(toRaw(booking)).then(async (response) => {
        if (response.status === 201) {
          if (selectedFile1 !== null) {
            await fileLoad(fd);
          } else {
            await prenotazioneAPI.mailCustomer(booking.customer.mail);
          }

          router.push('/');
          ElMessage({
            message: 'Booking aggiunto!',
            type: 'success',
          });
          console.log('Booking aggiunto!');
        } else {
          ElMessage({
            message: 'Errore creazione booking!',
            type: 'error',
          });
          console.log('errore inatteso');
        }
      });
    };

    const props1 = {
      expandTrigger: 'hover',
    };

    const isInputFocused = ref(false);

    const handleInputFocusGone = () => {
      isInputFocused.value = true;
    };
    const handleInputBlurGone = async () => {
      isInputFocused.value = false;
      if (
        escort_gone.point_origin_escort.address == '' ||
        escort_gone.point_origin_escort.street_number == '' ||
        escort_gone.point_origin_escort.city == '' ||
        escort_gone.point_origin_escort.cap == '' ||
        escort_gone.point_origin_escort.province == '' ||
        escort_gone.point_destination_escort.address == '' ||
        escort_gone.point_destination_escort.street_number == '' ||
        escort_gone.point_destination_escort.city == '' ||
        escort_gone.point_destination_escort.cap == '' ||
        escort_gone.point_destination_escort.province == ''
      ) {
        button1_disabled.value = true; 
      } else {
        button1_disabled.value = false;
        selectedSlotGone.value = null;

        store.originPointAddress = escort_gone.point_destination_escort.address;
        store.originPointStreetNumber =
          escort_gone.point_destination_escort.street_number;
        store.originPointCity = escort_gone.point_destination_escort.city;
        store.originPointCap = escort_gone.point_destination_escort.cap;
        store.originPointProvince =
          escort_gone.point_destination_escort.province;

        loadSlotGone(escort_gone.date_partner);
        calcolaKmGone();

        let resposnePointDestination = await loadPoints(
          escort_gone.point_destination_escort
        );

        escort_gone.point_destination_escort.label =
          resposnePointDestination.label;
        escort_gone.point_destination_escort.latitude =
          resposnePointDestination.point.latitude;
        escort_gone.point_destination_escort.longitude =
          resposnePointDestination.point.longitude;
      }
    };

    const handleInputFocusReturn = () => {
      isInputFocused.value = true;
    };
    const handleInputBlurReturn = async () => {
      isInputFocused.value = false;
      if (
        escort_return.point_origin_escort.address == '' ||
        escort_return.point_origin_escort.street_number == '' ||
        escort_return.point_origin_escort.city == '' ||
        escort_return.point_origin_escort.cap == '' ||
        escort_return.point_origin_escort.province == '' ||
        escort_return.point_destination_escort.address == '' ||
        escort_return.point_destination_escort.street_number == '' ||
        escort_return.point_destination_escort.city == '' ||
        escort_return.point_destination_escort.cap == '' ||
        escort_return.point_destination_escort.province == ''
      ) {
        button1_disabled.value = true;
      } else {
        selectedSlotReturn.value = null;

        loadSlotReturn(escort_return.date_partner);

        let resposnePoinOrigin = await loadPoints(
          escort_return.point_origin_escort
        );
        store.originPointAddress = escort_return.point_origin_escort.address;
        store.originPointStreetNumber =
          escort_return.point_origin_escort.street_number;
        store.originPointCity = escort_return.point_origin_escort.city;
        store.originPointCap = escort_return.point_origin_escort.cap;
        store.originPointProvince = escort_return.point_origin_escort.province;

        escort_return.point_origin_escort.label = resposnePoinOrigin.label;
        escort_return.point_origin_escort.latitude =
          resposnePoinOrigin.point.latitude;
        escort_return.point_origin_escort.longitude =
          resposnePoinOrigin.point.longitude;

        calcolaKmReturn();
      }
    };

    const back = () => {
      active.value = 0;
    };

    const disabledDate = (vDate) => {
      let expirydate = new Date();
      return vDate < expirydate.setDate(expirydate.getDate() + 3);
    };

    const disabledDateReturn = (vDate) => {
      let expirydate = new Date();
      if (is_gone.value && escort_gone.date_partner) {
        const partnerDate = new Date(escort_gone.date_partner);
        partnerDate.setHours(0, 0, 0, 0);

        return vDate < partnerDate;
      }

      return vDate < expirydate.setDate(expirydate.getDate() + 3);
    };

    const handleChangeNote = (value) => {
      booking.value.note = value;
    };

    return {
      handleChangeNote,
      loadingSlotsGone,
      loadingSlotsReturn,
      back,
      handleInputFocusGone,
      handleInputBlurGone,
      handleInputFocusReturn,
      handleInputBlurReturn,
      fileLoad,
      handleButtonClickCrea,
      headquarterSelected,
      customerSelected,
      carSelected,
      handleChangeDateGone,
      handleChangeDateReturn,
      task,
      //task_radio,
      escort_gone,
      escort_return,
      changeLoaner,
      deleteGone,
      is_gone,
      deleteReturn,
      is_return,
      selectedSlotGone,
      selectedSlotReturn,
      timeSlotsGone,
      timeSlotsReturn,
      handleSlotChangeGone,
      handleSlotChangeReturn,

      changeValueTask,

      onFileSelected,
      active,
      next,
      tasks,
      loadCars,
      addBookingNew,
      customerId,
      customers,
      customersId,
      booking,
      partners,
      partnersId,
      carsId,
      headquartersId,
      headquarters,
      onSubmit,
      props1,
      handleChangeCar,
      handleChangeCustomer,
      handleChangeHeadquarter,
      aggiungiTask,
      rimuoviTask,
      Delete,
      button1_disabled,
      button2_disabled,

      response_minute_gone,
      response_minute_return,

      calcolaKmGone,
      calcolaKmReturn,
      responseKmGone,
      responseCostGone,
      responseKmReturn,
      responseCostReturn,

      highlightCustomer,
      highlightHeadquarter,
      highlightCar,
      highlightDateGone,
      highlightDateReturn,
      highlightSlotGone,
      highlightSlotReturn,

      DescriptionComponent,
      loadingPoint,

      customerExist,
      checkCustomerExist,
      disabledDate,
      disabledDateReturn,
      highlightDescription,
      highlightCost,
    };
  },
};
</script>

<style>
.box-card {
  width: 1250px;
  height: 1000px;
}

.el-steps {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 2%;
}

.row-1 {
  height: 80px;
}

.row-2 {
  height: 95px;
}

.row-3 {
  height: 55px;
}

.form-label {
  font-size: 18px;
  margin-top: -5%;
  margin-left: -38%;
  color: #333;
}

.form-label-2 {
  font-size: 18px;
  margin-top: -7%;
  margin-left: -60%;
  color: #333;
}
</style>
