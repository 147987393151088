<template >
  <div>
      <el-form-item label="Descrizione lavori">
          <el-input v-model="description" :class="{ 'highlight': highlightDescription }"/>
      </el-form-item>
  </div>
  <div >
      <el-form-item label="Costo">
          <el-input v-model="cost" :class="{ 'highlight': highlightCost }"/>
      </el-form-item>
  </div>
  <div >
      <el-form-item label="Il cliente paga i lavori sull'app">
          <el-switch v-model="pay"/>
      </el-form-item>
  </div>
</template>

<script>
export default {
props: {

  highlightDescription: {
      type: Boolean,
      require: false,
    },

    highlightCost: {
      type: Boolean,
      require: false,
    },
    InputDescription:{},
    InputCost: {},
    InputPay: {}

},
data() {
  return {
    description: this.InputDescription,
    cost: this.InputCost,
    pay: this.InputPay,
  };
},
watch: {
    InputDescription(newValue) {
      this.description = newValue;
    },
    InputCost(newValue) {
      this.cost = newValue;
    },
    InputPay(newValue) {
      this.pay = newValue;
    },

  description(val) {
    this.$emit('description', val);
  },
  cost(val) {
      this.$emit('cost', val)
  },
  pay(val) {
      this.$emit('pay', val)
  }
},
};


</script>


<style scoped>

.highlight {
  border: 2px solid rgb(205, 54, 54); 
}
</style>
