<template>
  <el-row v-if="!isFromCorseLive" justify="center">
    <img
      style="width: 90px; height: 116px"
      :src="require('../assets/logo-simba-RIDIMENSIONATO.png')"
      class="image"
    />

    <h1>
      BENVENUTO NELL'AREA<br />
      RISERVATA SIMBA
    </h1>
    <br />
    <br />
  </el-row>
  <div v-if="isFromCorseLive" justify="center">
    <h1>Corse attive</h1>
  </div>

  <div class="flex flex-wrap items-center" style="margin-top: 30px">
    <el-text class="driver" type="info"
      >Seleziona una sede per visualizzare i booking attivi
    </el-text>
    <el-cascader
      v-model="input"
      :options="headquartersId"
      stripe
      :props="props1"
      clearable
      @change="handleChange_headquarter"
    />
  </div>

  <div class="top">
    <FilterTableComponent
      :servizi="serviziBooking"
      @nomeChanged="(newValue) => (nomeFilter = newValue)"
      @targaChanged="(newValue) => (targaFilter = newValue)"
      @servizioChanged="(newValue) => (servizioFilter = newValue)"
      @dataChanged="(newValue) => (dataFilter = newValue)"
      @resetClick="resetFilter"
      :nome="nomeFilter"
    ></FilterTableComponent>

    <el-table
      :data="homeTableData"
      stripe
      :header-cell-style="{ background: 'rgb(231,228,58)' }"
      style="width: 100%"
    >
      <el-table-column prop="id" label="N° booking">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
      </el-table-column>
      <el-table-column prop="sede" label="Sede" />
      <el-table-column prop="customer" label="Cliente" />
      <el-table-column prop="tag" label="Stato" filter-placement="bottom-end">
        <template #default="scope">
          <div v-if="scope.row.tag == 'Non ancora accettato'">
            <el-tag
              :width="150"
              :type="scope.row.tag == 'Non ancora accettato' ? 'error' : ''"
            >
              {{ scope.row.tag }}
            </el-tag>
          </div>
          <div v-else-if="scope.row.tag == 'Accettato dal cliente'">
            <el-tag
              :width="150"
              :type="scope.row.tag == 'Accettato dal cliente' ? 'success' : ''"
            >
              {{ scope.row.tag }}
            </el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="typeBooking" label="Servizio" />
      <el-table-column prop="auto" label="Auto" />
      <el-table-column prop="targa" label="Targa" />
      <el-table-column prop="ora" label="Data" />

      <el-table-column prop="type" label="Aggiungi lavori">
        <template #default="scope">
          <router-link
            :to="{ path: `/tasks/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button
              size="small"
              v-if="
                scope.row.type == 1 ||
                scope.row.type == 2 ||
                scope.row.type == 4 ||
                scope.row.type == 5 ||
                scope.row.type == 6 ||
                scope.row.type == 7
              "
            >
              Aggiungi lavori
            </el-button>
          </router-link>
        </template>
      </el-table-column>

      <el-table-column prop="newDate" label="Modifica data/ora">
        <template #default="scope">
          <router-link
            :to="{ path: `/update-date/${scope.row.id}` }"
            class="routerLink"
          >
            <!-- <el-button
              size="small"
              :disabled="
                !formatDateGone(scope.row.dataBooking, scope.row.isGone) ||
                !formatDateReturn(scope.row.dataBooking, scope.row.isReturn)
              "
            > -->

            <el-button
              size="small"
              :disabled="
                !formatDateGone(scope.row.dateTimeGone, scope.row.isGone) &&
                !formatDateReturn(scope.row.dateTimeReturn, scope.row.isReturn)
              "
            >
              Modifica data/ora
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="50">
        <template #default="scope">
          <router-link
            :to="{ path: `/booking_attivo/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small">
              <el-icon>
                <DArrowRight />
              </el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div style="text-align: center">
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      :total="homeTable.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import bookingAPI from '../services/bookingAPI.js';
import { useRoute } from 'vue-router';
import FilterTableComponent from '@/Components/FilterTableComponent.vue';
const dayjs = require('dayjs');
import { isLoggedStore } from '../store/logged';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export default {
  name: 'HomePage',
  components: {
    FilterTableComponent,
  },
  data() {
    return {
      page: 1,
      pageSize: 7,
    };
  },
  setup() {
    let nomeFilter = ref('');
    let targaFilter = ref('');
    let servizioFilter = ref('');
    let dataFilter = ref('');

    const route = useRoute();
    let isFromCorseLive = ref(false);
    if (route.query.q == 1) {
      isFromCorseLive = true;
    }

    const props1 = {
      expandTrigger: 'hover',
    };

    const storeLogin = isLoggedStore();
    let headquartersId = ref([]);
    const headquarters = ref([]);
    let tableData = ref([
      {
        id: '',
        sede: '',
        customer: '',
        tag: '',
        auto: '',
        targa: '',
        ora: '',
        newDate: '',
        tasks: [],
        dateGone: '',
        dateReturn: '',
        isGone: false,
        isReturn: false,
        type: 0, //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
        headquarterId: '',
        dataBooking: '',
      },
    ]);
    const input = ref('');

    const typeBooking = (type) => {
      switch (type) {
        case 1:
          return 'Pickup & Delivery';
        case 2:
          return 'Accompagna un cliente';
        case 3:
          return "Consegna un'auto";
        case 4:
          return 'Accompagna un cliente e lavori';
        case 5:
          return 'Accompagna un cliente con auto partner';
        case 6:
          return 'Accompagna un cliente con auto partner e lavori';
        case 7:
          return 'Pickup & Delivery con auto di cortesia';
      }
    };

    const loadHeadquarters = async () => {
      try {
        const employeeId = storeLogin.employee.id;
        const response = await bookingAPI.getHeadquartersByEmployee(employeeId);

        response.data[0].headquarters.forEach((headquarter) => {
          let tableRow = {
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number}, ${headquarter.city}`,
          };
          headquartersId.value.push(tableRow);
        });

        loadAllBookings();
      } catch (e) {
        console.log(e);
      }
    };

    loadHeadquarters();

    const formatDateGone = (date, isGone) => {
      if (isGone) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate);
        let expiryPlus2Hours = expiry.add(2, 'hour');
        expiryPlus2Hours = dayjs(expiryPlus2Hours).format();

        let bookingDate = dayjs(date).utc().format();
        return bookingDate > expiryPlus2Hours;
      }
    };

    const formatDateReturn = (date, isReturn) => {
      if (isReturn) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate);
        let expiryPlus2Hours = expiry.add(2, 'hour');
        expiryPlus2Hours = dayjs(expiryPlus2Hours).format();
        let bookingDate = dayjs(date).utc().format();
        return bookingDate > expiryPlus2Hours;
        //return bookingDate.isAfter(expiryPlus2Hours);
      }
    };

    const handleChange_headquarter = async (val) => {
      try {
        if (val != null) {
          tableData.value = [];

          const today = dayjs(new Date())
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format('YYYY-MM-DD HH:mm:ss');
          const tomorrow = dayjs(today)
            .add(1, 'day')
            .format('YYYY-MM-DD HH:mm:ss');

          const response = await bookingAPI.getBookingAttivi(
            val,
            today,
            tomorrow
          );

          response.data.forEach(async (booking) => {
            const tasks = [];
            const data = await loadBooking(booking.id);

            /* if (booking.tasks) {
                            booking.tasks.forEach((task) => {
                                if (task.accepted == false) {
                                    tasks.push({description:task.description+ ", ", cost:task.cost+"€"});
                                }
                            })
                        }  */

            let tableRow = {
              id: booking.id,
              sede:
                booking.headquarter.address +
                ' ' +
                booking.headquarter.street_number +
                ', ' +
                booking.headquarter.city,
              customer: booking.customer.name + ' ' + booking.customer.surname,
              tag: state(booking.state),
              auto:
                booking.car != null
                  ? booking.car.marca + ', ' + booking.car.modello
                  : '-',
              targa: booking.car != null ? booking.car.targa : '-',
              ora: data.date.current_date,
              newDate: data.date.newDate,
              tasks: tasks,
              type: data.typeOfBooking,
              isGone: data.isGone,
              isReturn: data.isReturn,
              dateGone: data.dateGone,
              dateReturn: data.dateReturn,
              typeBooking: typeBooking(data.typeOfBooking),
              headquarterId: booking.headquarter.id,
              dataBooking: booking.data,
              //orario_arrivo: booking.pickup_bookings[0].type=="gone"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
              //orario_rilascio: booking.pickup_bookings[0].type=="return"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
            };
            tableRow.stato = state(tableRow.stato);
            tableData.value.push(tableRow);
          });
        } else {
          loadAllBookings();
        }
      } catch (e) {
        console.log(e);
      }
    };

    const loadBooking = async (id) => {
      try {
        let singleBooking = {
          dateGone: '',
          dateReturn: '',
          dateGoneTime: '',
          dateReturnTime: '',
          typeOfBooking: 0,
          isGone: false,
          isReturn: false,
          date: {
            current_date: '',
            newDate: '',
          },
        };

        const response = await bookingAPI.getBookingById(id);
        const booking = response.data;

        if (booking.pickup_bookings && booking.pickup_bookings.length > 0) {
          for (let i = 0; i < booking.pickup_bookings.length; i++) {
            const currentBooking = booking.pickup_bookings[i];
            booking.loaner == true
              ? (singleBooking.typeOfBooking = 7)
              : (singleBooking.typeOfBooking = 1);

            if (currentBooking.type_booking === 'gone') {
              singleBooking.dateGone = dayjs(currentBooking.date_partner)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.dateGoneTime = currentBooking.date_partner;
              singleBooking.isGone = true;
            } else {
              singleBooking.dateReturn = dayjs(currentBooking.date_partner)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.dateReturnTime = currentBooking.date_partner;
              singleBooking.isReturn = true;
            }
          }
        }
        if (booking.escort_bookings && booking.escort_bookings.length > 0) {
          for (let i = 0; i < booking.escort_bookings.length; i++) {
            const currentBooking = booking.escort_bookings[i];

            if (currentBooking.tasks == true && booking.loaner == true) {
              singleBooking.typeOfBooking = 6;
            } else if (
              currentBooking.tasks == false &&
              booking.loaner == true
            ) {
              singleBooking.typeOfBooking = 5;
            } else if (
              currentBooking.tasks == true &&
              booking.loaner == false
            ) {
              singleBooking.typeOfBooking = 4;
            } else {
              singleBooking.typeOfBooking = 2;
            }

            if (currentBooking.type_booking === 'gone') {
              singleBooking.dateGone = dayjs(currentBooking.date_partner)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.isGone = true;
              singleBooking.dateGoneTime = currentBooking.date_partner;
            } else {
              singleBooking.dateReturn = dayjs(currentBooking.date_partner)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.isReturn = true;
              singleBooking.dateReturnTime = currentBooking.date_partner;
            }
          }
        }
        if (booking.delivery_bookings && booking.delivery_bookings.length > 0) {
          for (let i = 0; i < booking.delivery_bookings.length; i++) {
            const currentBooking = booking.delivery_bookings[i];
            singleBooking.typeOfBooking = 3;

            if (currentBooking.type_booking === 'gone') {
              singleBooking.dateGone = dayjs(currentBooking.date_point)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.dateGoneTime = currentBooking.date_point;
              singleBooking.isGone = true;
            } else {
              singleBooking.dateReturn = dayjs(currentBooking.date_point)
                .utc()
                .format('DD/MM/YY HH:mm');
              singleBooking.isReturn = true;
              singleBooking.dateReturnTime = currentBooking.date_point;
            }
          }
        }

        if (singleBooking.dateGone != '' && singleBooking.dateReturn != '') {
          singleBooking.date.current_date = `A: ${singleBooking.dateGone} R: ${singleBooking.dateReturn}`;
        } else {
          singleBooking.date.current_date =
            singleBooking.dateGone != ''
              ? `A: ${singleBooking.dateGone}`
              : `R: ${singleBooking.dateReturn}`;
        }

        return singleBooking;
      } catch (e) {
        console.log(e);
      }
    };

    const loadAllBookings = async () => {
      try {
        tableData.value = [];
        const today = dayjs(new Date())
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .format('YYYY-MM-DD HH:mm:ss');
        const tomorrow = dayjs(today)
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss');

        const response = await bookingAPI.getBookingAttiviAll(today, tomorrow);
        const headquartersIds = headquartersId.value.map((hq) => hq.value);
        const filteredBookings = response.data.filter((booking) =>
          headquartersIds.includes(booking.headquarter.id)
        );

          for (const booking of filteredBookings) {
          const tasks = [];
          const data = await loadBooking(booking.id);

          let tableRow = {
            id: booking.id,
            sede:
              booking.headquarter.address +
              ' ' +
              booking.headquarter.street_number +
              ', ' +
              booking.headquarter.city,
            customer: booking.customer.name + ' ' + booking.customer.surname,
            tag: state(booking.state),
            auto:
              booking.car != null
                ? booking.car.marca + ', ' + booking.car.modello
                : '-',
            targa: booking.car != null ? booking.car.targa : '-',
            ora: data.date.current_date,
            newDate: data.date.newDate,
            tasks: tasks,
            type: data.typeOfBooking,
            isGone: data.isGone,
            isReturn: data.isReturn,
            dateGone: data.dateGone,
            dateTimeGone: data.dateGoneTime,
            dateTimeReturn: data.dateReturnTime,
            dateReturn: data.dateReturn,
            typeBooking: typeBooking(data.typeOfBooking),
            headquarterId: booking.headquarter.id,
            dataBooking: booking.data,
            //orario_arrivo: booking.pickup_bookings[0].type=="gone"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
            //orario_rilascio: booking.pickup_bookings[0].type=="return"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
          };

          tableRow.stato = state(tableRow.stato);
          tableData.value.push(tableRow);
        }//);
      } catch (e) {
        console.log(e);
      }
    };

    

    const serviziBooking = ref([
      {
        value: 'Pickup & Delivery',
        label: 'Pickup & Delivery',
      },
      {
        value: 'Accompagna un cliente',
        label: 'Accompagna un cliente',
      },
      {
        value: "Consegna un'auto",
        label: "Consegna un'auto",
      },
      /*{
                value: "Accompagna un cliente e lavori",
                label: "Accompagna un cliente e lavori",
            },
            {
                value: 'Accompagna un cliente con auto di cortesia',
                label: 'Accompagna un cliente con auto di cortesia',
            },
            {
                value: 'Accompagna un cliente con auto partner e lavori',
                label: 'Accompagna un cliente con auto partner e lavori',
            },
            {
                value: 'Pickup & Delivery con auto partner',
                label: 'Pickup & Delivery con auto partner',
            } */
    ]);

    const state = (state) => {
      switch (state) {
        case 'before':
          state = 'Non ancora accettato';
          break;
        case 'accepted':
          state = 'Accettato dal cliente';
          break;
        case 'startAPickup':
          state = 'Driver in viaggio';
          break;
        case 'driverScanJollyAPickup':
          state = 'Jolly e driver presso la sede';
          break;
        case 'customerAPickup':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoAPickup':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanAPickup':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'partnerAPickup':
          state = 'Driver arrivato in concessionaria';
          break;

        case 'startRPickup':
          state = 'Driver in viaggio';
          break;
        case 'partnerRPickup':
          state = 'Driver arrivato in concessionaria';
          break;
        case 'customerRPickup':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoRPickup':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanRPickup':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyRPickup':
          state = 'Jolly presso il cliente';
          break;

        case 'startAPickupLoaner':
          state = 'Driver in arrivo';
          break;
        case 'partnerAPickupLoaner':
          state = 'Il Driver ha ritirato auto di cortesia';
          break;
        case 'customerAPickupLoaner':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoAPickupLoaner':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanAPickupLoaner':
          state = "Il cliente ha ritirato l'auto di cortesia";
          break;
        case 'partnersecondAPickupLoaner':
          state = 'Driver arrivato in concessionaria';
          break;

        case 'startRPickupLoaner':
          state = 'Driver in arrivo';
          break;
        case 'partnerRPickupLoaner':
          state = 'Il Driver ha ritirato auto di cortesia';
          break;
        case 'customerRPickupLoaner':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoRPickupLoaner':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanRPickupLoaner':
          state = "Il cliente ha ritirato l'auto di cortesia";
          break;

        case 'startADelivery':
          state = 'Driver in viaggio';
          break;
        case 'partnerADelivery':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'photofirstADelivery':
          state = 'Il driver ha fatto le foto';
          break;
        case 'customerADelivery':
          state = 'Driver arrivato al punto di consegna';
          break;
        case 'photosecondADelivery':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyADelivery':
          state = 'Il jolly ha accompagnato il driver ';
          break;

        case 'startRDelivery':
          state = 'Driver in viaggio';
          break;
        case 'customerRDelivery':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'photofirstRDelivery':
          state = 'Il driver ha fatto le foto';
          break;
        case 'partnerRDelivery':
          state = 'Driver arrivato al punto di consegna';
          break;
        case 'photosecondRDelivery':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyRDelivery':
          state = 'Il jolly ha accompagnato il driver ';
          break;

        case 'startAEscort':
          state = 'Driver in viaggio';
          break;
        case 'customerAEscort':
          state = 'Driver arrivato al punto di incontro con il cliente';
          break;
        case 'scanfirstAEscort':
          state = 'Il driver ha fatto salire il cliente in auto';
          break;
        case 'scanssecondAEscort':
          state = 'Il driver ha portato il cliente al punto stabilito';
          break;

        case 'partnerAEscort':
          state = 'Il driver ha consegnato auto al partner';
          break;

        case 'startREscort':
          state = 'Driver in viaggio';
          break;
        case 'customerREscort':
          state = 'Driver arrivato al punto di incontro con il cliente';
          break;
        case 'scanfirstREscort':
          state = 'Il driver ha fatto salire il cliente in auto';
          break;
        case 'scansecondREscort':
          state = 'Il driver ha portato il cliente al punto stabilito';
          break;

        case 'end':
          state = 'Concluso';
          break;
      }
      return state;
    };

    /* const homeTable = computed(function () {
            return tableData.value.filter((row) =>
                row.headquarterId.toLowerCase().includes(input.value.toLowerCase()) &&
                row.customer.toLowerCase().includes(nomeFilter.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilter.value.toLowerCase()) &&
                row.typeBooking.includes(servizioFilter.value[0] == undefined ? '' : servizioFilter.value[0]) &&

                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilter.value == '' ? '' : dayjs(new Date(dataFilter.value)).format("YYYY-MM-DD"))
            );
        }); */

    const homeTable = computed(function () {
      return tableData.value.filter((row) => {
        const headquarterMatch = row.headquarterId
          .toLowerCase()
          .includes(input.value.toLowerCase());
        const customerMatch = row.customer
          .toLowerCase()
          .includes(nomeFilter.value.toLowerCase());
        const targaMatch = row.targa
          .toLowerCase()
          .includes(targaFilter.value.toLowerCase());

        const servizioMatch = (() => {
          const servizio =
            servizioFilter.value[0] == undefined ? '' : servizioFilter.value[0];
          return row.typeBooking.includes(servizio);
        })();

        const dataMatch = dayjs(new Date(row.dataBooking))
          .format('YYYY-MM-DD')
          .includes(
            dataFilter.value === ''
              ? ''
              : dayjs(new Date(dataFilter.value)).format('YYYY-MM-DD')
          );

        return (
          headquarterMatch &&
          customerMatch &&
          targaMatch &&
          servizioMatch &&
          dataMatch
        );
      });
    });

    const resetFilter = async () => {
      window.location.reload();
    };

    return {
      tableData,
      homeTable,
      loadHeadquarters,
      headquartersId,
      headquarters,
      state,
      handleChange_headquarter,
      typeBooking,
      formatDateGone,
      formatDateReturn,
      props1,
      loadAllBookings,
      route,
      isFromCorseLive,
      nomeFilter,
      targaFilter,
      servizioFilter,
      dataFilter,
      resetFilter,
      serviziBooking,
    };
  },
  computed: {
    homeTableData() {
      if (!this.homeTable || this.homeTable.length === 0) return [];
      return this.homeTable.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
  },
};
</script>

<style>
.el-table {
  color: black;
}

.el-table thead {
  color: black;
}

.el-table td {
  color: black;
  background-color: rgb(241, 241, 241);
}

.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.top {
  margin-top: 50px;
}
</style>
