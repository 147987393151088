import API from './API';

export default {

   getCustomersByEmployee( employeeId ) {
    return API.get(`/employee/`+employeeId,{
        params: {
            join: ['headquarters','employees.headquarters','employees.headquarters.partner'],
          },
        });
  }, 

  updateCustomer(id, customer){
    return API.patch('/customer/'+id, customer)
  },

  getMinuteByCar(carId,partnerId) {
    return API.get(`/minute?filter=car.id||$eq||${carId}&filter=partner.id||$eq||${partnerId}&`,{
      params: {
           join: [
            'partner',
            'car'
          ], 
        },
      });
  },

  partner() {
    return API.get('/employee/partners');
  },
  
  /* getCustomersByPartner(partnerId) {
    return API.get(`/partner/`+partnerId, {
     params:{
        join:[
          'customers'
        ]
      } 
    });
  }, */
  getCustomersByPartner( partnerId ) {
    return API.get(`/customer?filter=partners.id||$eq||${partnerId}&filter=is_deleted||$eq||${false}&`, {
      params:{
         join:[
           'partners'
         ]
       } 
     });
  },

  getCars( ) {
    return API.get(`/car`);
  },

  addMinute(minute){
    return API.post('/minute', minute)
  },

  getCustomer(id) {
    return API.get('/customer/'+id);
  },

  getCustomers() {
    return API.get('/customer?sort=surname,ASC');
  },

  updCustomer(customer) {
    return API.patch('/customer/' + customer.id, customer)
  },

  addCustomer(customer) {
    return API.post('/auth/register/' , customer)
  },

  checkCustomerExist(data) {
    return API.post('/customer/exist' , data)
  },

  
  addPartnerToCustomer(data) {
    return API.post('/customer/add-partner' , data)
  },

};