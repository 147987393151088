<template>
  <div style="margin-top: 40px">
    <h1>Dettagli del booking {{ booking_info.date }}</h1>
    <h2>{{ booking_info.title_prenotazione }}</h2>
  </div>

  <hr
    style="
      height: 4px;
      color: rgb(231, 228, 58);
      background-color: rgb(231, 228, 58);
      width: 35%;
      margin-top: 15px;
      margin-left: 32.5%;
    "
  />

  <br />
  <div
    v-if="type == 5 || type == 7"
    class="d-flex align-items-center"
    style="padding: 20px"
  >
    <el-icon
      :style="{
        fontSize: '34px',
        color: 'orange',
        animation: 'pulse 1s infinite',
      }"
      style="vertical-align: middle"
    >
      <BellFilled />
    </el-icon>
    <span
      v-if="type == 5"
      style="font-weight: bold; vertical-align: middle; margin-left: 15px"
    >
      Il driver ha in dotazione la vostra vettura
    </span>
    <span
      v-if="type == 7"
      style="font-weight: bold; vertical-align: middle; margin-left: 15px"
    >
      Il driver ritira l'auto di cortesia
    </span>
  </div>

  <el-row justify="center">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Cliente"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Cliente
            </div>
          </template>
          {{ booking_info.customer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Phone />
              </el-icon>
              Cell. cliente
            </div>
          </template>
          {{ booking_info.customer_number }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="type == 1 || type == 4 || type == 6 || type == 7"
        >
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Bicycle />
              </el-icon>
              Auto
            </div>
          </template>
          {{ booking_info.auto }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="type == 1 || type == 4 || type == 6 || type == 7"
        >
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <tickets />
              </el-icon>
              Targa
            </div>
          </template>
          {{ booking_info.targa }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>
    <!-- PARTNER -->
    <el-row justify="center">
      <el-col :span="20">
          <el-descriptions
              class="margin-top"
              title="Partner"
              :column="2"
              :size="size"
              border
          >
             <el-descriptions-item>
              <template #label>
                  <div class="cell-item">
                  <el-icon :style="iconStyle">
                      <office-building />
                  </el-icon>
                  Sede del partner
                  </div>
              </template>
              {{booking_info.partner_point}}
              </el-descriptions-item>
              
              
              
          </el-descriptions>
      </el-col>
  </el-row>

    <!--PICKUP-->
    <el-row justify="center" v-if="type == 7 || type == 1">
      <el-col :span="20">
          <el-descriptions
              class="margin-top"
              title="Booking"
              :column="2"
              :size="size"
              border
          >
          
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di prelievo
                  </div>
                  </template>
                  {{booking_info.origin_point_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Fascia km andata:
                  </div>
                  </template>
                  {{ booking_info.simba_km_range_gone }}km
              </el-descriptions-item>
              <!--<el-descriptions-item v-if="type == 7 && is_gone">
              <template #label>
                  <div class="cell-item">
                  <el-icon :style="iconStyle">
                      <office-building />
                  </el-icon>
                  Sede del partner
                  </div>
              </template>
              {{booking_info.partner_point}}
              </el-descriptions-item>-
              <el-descriptions-item v-if="type == 7 && is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario consegna dell'auto
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
              <!--<el-descriptions-item v-if="type == 7 && is_return">
                  <template #label>
                      <div class="cell-item">
                      <el-icon :style="iconStyle">
                          <office-building />
                      </el-icon>
                      Sede del partner
                      </div>
                  </template>
                  {{booking_info.partner_point}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario ripresa dell'auto
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>-->
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di consegna
                  </div>
                  </template>
                  {{booking_info.destination_point_return}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Fascia km ritorno:
                  </div>
                  </template>
                  {{ booking_info.simba_km_range_return }}km
              </el-descriptions-item>
  
              
              <!--<el-descriptions-item v-if="type == 5 && is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di presa del cliente
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="type == 5 && is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di ripresa del cliente
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>-->
          </el-descriptions>
      </el-col>
  </el-row>
  <!--ESCORT-->
  <el-row justify="center" v-if="type == 5 || type == 6 || type == 4 || type == 2">
      <el-col :span="20">
          <el-descriptions
              class="margin-top"
              title="Booking"
              :column="2"
              :size="size"
              border
          >
          
              <!--<el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di prelievo andata
                  </div>
                  </template>
                  {{booking_info.origin_point_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di presa del cliente
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di consegna andata
                  </div>
                  </template>
                  {{booking_info.destination_point_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Fascia km andata:
                  </div>
                  </template>
                  {{ booking_info.simba_km_range_gone }}km
              </el-descriptions-item>
  
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di prelievo ritorno
                  </div>
                  </template>
                  {{booking_info.origin_point_return}}
              </el-descriptions-item>
              <!--<el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di ripresa del cliente
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di consegna ritorno
                  </div>
                  </template>
                  {{booking_info.destination_point_return}}
              </el-descriptions-item>-->
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Fascia km ritorno:
                  </div>
                  </template>
                  {{ booking_info.simba_km_range_return }}km
              </el-descriptions-item>
          </el-descriptions>
      </el-col>
  </el-row>
  
  <!--CONSEGNA UN AUTO-->
  <el-row justify="center" v-if="type == 3">
      <el-col :span="20">
          <el-descriptions
              class="margin-top"
              title="Booking"
              :column="2"
              :size="size"
              border
          >
              <el-descriptions-item>
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di prelievo
                  </div>
                  </template>
                  {{booking_info.origin_point_gone}}
              </el-descriptions-item>
              <!--<el-descriptions-item>
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario 
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
              <el-descriptions-item>
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di consegna
                  </div>
                  </template>
                  {{booking_info.destination_point_gone}}
              </el-descriptions-item>
              <el-descriptions-item>
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Fascia km:
                  </div>
                  </template>
                  {{ booking_info.simba_km_range_gone }}km
              </el-descriptions-item>
          </el-descriptions>
      </el-col>
  </el-row>
  

  <!--DRIVER ANDATA-->
  <el-row justify="center" v-if="is_gone">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        :title="type == 3 ? 'Driver' : 'Driver Andata'"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Nome driver
            </div>
          </template>
          {{ booking_info.driver_andata }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Phone />
              </el-icon>
              Cell. driver
            </div>
          </template>
          {{ booking_info.telephone_gone_driver }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--DRIVER RITORNO-->
  <el-row justify="center" v-if="is_return">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Driver Ritorno"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Nome driver
            </div>
          </template>
          {{ booking_info.driver_ritorno }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Phone />
              </el-icon>
              Cell. driver
            </div>
          </template>
          {{ booking_info.telephone_return_driver }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--DESCRIZIONE AUTO-->
  <el-row justify="center" v-if="type == 3">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Auto"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item width="200px">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Van />
              </el-icon>
              Descrizione auto
            </div>
          </template>
          {{ booking_info.car_description }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--NOTE-->
  <el-row justify="center" v-if="type == 3 && noteVisibile">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Note"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item width="200px">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Van />
              </el-icon>
              Note
            </div>
          </template>
          {{ booking_info.note }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--DETTAGLI LAVORI-->
  <!-- <div justify="center">
    <el-row
      v-if="type == 1 || type == 4 || type == 7 || type == 6"
      type="flex"
      style="margin-top: 3%; margin-left: 50px; marging-right: 50px"
    >
      <el-col :span="24">
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item name="1">
              <template #title>
                <div class="collapse-title">
                  Dettagli dei lavori da svolgere nella prenotazione
                </div>
              </template>

              <div
                v-for="(task, counter_min) in booking_info.tasks"
                v-bind:key="counter_min"
              >
                <div v-if="task.pay == true" style="margin-bottom: 1%">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ task.cost + ' €' }} {{ 'pagamento in app' }}
                    </template>
                  </el-input>
                </div>
                <div v-if="task.pay == true && task.accepted == false" style="margin-bottom: 1%">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ 'IN ATTESA DI CONFERMA DAL CLIENTE' }} {{ task.cost + ' €' }} {{ 'pagamento in app' }}
                    </template>
                  </el-input>
                </div>
                <div v-if="task.pay == false" style="margin-bottom: 1%">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ task.cost + ' €' }} {{ 'pagamento NON in app' }}
                    </template>
                  </el-input>
                </div>
              </div>
              <el-input
                v-model="booking_info.title"
                autosize
                readonly="readonly"
              >
                <template #append>
                  {{ booking_info.tot_cost + ' €' }}
                </template>
              </el-input>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>

    <div style="margin-top: 3%" v-if="showDeleteButton">
      <el-button type="danger" size="large" @click="open"
        >Elimina la prenotazione</el-button
      >
    </div>
  </div> -->
  <div justify="center">
    <el-row
      v-if="type == 1 || type == 4 || type == 7 || type == 6"
      type="flex"
      style="margin-top: 3%; margin-left: 50px; marging-right: 50px"
    >
      <el-col :span="24">
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item name="1">
              <template #title>
                <div class="collapse-title">
                  Dettagli dei lavori da svolgere nella prenotazione
                </div>
              </template>
  
              <div
                v-for="(task, counter_min) in booking_info.tasks"
                v-bind:key="counter_min"
                style="display: flex; align-items: center; margin-bottom: 1%"
              >
                <div v-if="task.pay == true  && task.accepted == true" style="flex: 1">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ task.cost + ' €' }} {{ 'pagamento in app' }}
                    </template>
                  </el-input>
                </div>
                <div v-if="task.pay == true && task.accepted == false" style="flex: 1">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ 'IN ATTESA DI CONFERMA DAL CLIENTE' }} {{ task.cost + ' €' }} {{ 'pagamento in app' }}
                    </template>
                  </el-input>
                </div>
                <div v-if="task.pay == false" style="flex: 1">
                  <el-input
                    v-model="task.description"
                    autosize
                    readonly="readonly"
                  >
                    <template #append>
                      {{ task.cost + ' €' }} {{ 'pagamento NON in app' }}
                    </template>
                  </el-input>
                </div>
  
                <!-- Elimina button -->
                <el-button
                  type="danger"
                  @click="deleteTask(task.id)"
                  style="margin-left: 10px"
                >
                  Elimina
                </el-button>
              </div>
  
              <el-input
                v-model="booking_info.title"
                autosize
                readonly="readonly"
              >
                <template #append>
                  {{ booking_info.tot_cost + ' €' }}
                </template>
              </el-input>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  
    <div style="margin-top: 3%" v-if="showDeleteButton">
      <el-button type="danger" size="large" @click="open"
        >Elimina la prenotazione</el-button
      >
    </div>
  </div>
  
</template>

<script>
import { ref } from 'vue';
import bookingAPI from '../services/bookingAPI.js';
import { useRoute, useRouter } from 'vue-router';
import { reactive } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
const dayjs = require('dayjs');
export default {
  name: 'HomePage',
  components: {},
  setup() {
    //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia

    const router = useRouter();
    const type = ref(0);
    let condition1 = false;
    let condition2 = true;
    let noteVisibile = ref(false);
    const activeName = ref('1');
    const route = useRoute();
    const bookingId = route.params.id.split('-')[0];
    const tableData = ref([]);
    const booking_info = reactive({
      customer: '',
      date:"",
      customer_number: '',
      auto: '',
      targa: '',
      tasks: [],
      driver_andata: '',
      driver_ritorno: '',
      telephone_gone_driver: '',
      telephone_return_driver: '',
      title_prenotazione: '',
      title: 'Costo totale dei lavori',
      tot_cost: 0,
      state: '',
      simba_km_range_gone: 0,
      simba_km_range_return: 0,

      origin_point_gone: '',
      destination_point_gone: '',

      origin_point_return: '',
      destination_point_return: '',

      car_description: '',
      note: '',
    });

    let date_gone = ref('');
    let hour_gone = ref('');
    let date_return = ref('');
    let hour_return = ref('');

    let is_gone = ref(false);
    let is_return = ref(false);

    const loadBooking = async () => {
      try {
        const response = await bookingAPI.getBookingById(route.params.id);
        const booking = response.data;

        

        booking_info.date = dayjs(booking.data).format(
          'DD/MM/YYYY'
        );

        checkBookingDate( booking.data);
        
        booking_info.customer =
          booking.customer.name + ' ' + booking.customer.surname;
        booking_info.customer_number = booking.customer.telephone_number;
        booking_info.state = booking.state;

        if (booking.note != '') {
          const note = booking.note.split(',');
          if (note.length != 0) {
            booking_info.car_description =
              note[0] + ', ' + note[1] + ', ' + note[2] + ', ' + note[3];
            if (note.length == 5) {
              noteVisibile.value = true;
              booking_info.note = note[4].split(':')[1];
            }
          }
        }

        booking_info.partner_point =
          booking.headquarter.address +
          ' ' +
          booking.headquarter.street_number +
          ', ' +
          booking.headquarter.cap +
          ' ' +
          booking.headquarter.city +
          ' ' +
          booking.headquarter.province;

        if (booking.pickup_bookings && booking.pickup_bookings.length > 0) {
          booking_info.title_prenotazione = 'Pickup e Delivery';
          booking_info.auto = booking.car.marca + ', ' + booking.car.modello;
          booking_info.targa = booking.car.targa;
          booking_info.tasks = booking.tasks;

          booking.loaner == true ? (type.value = 7) : (type.value = 1);

          booking.tasks.forEach((task) => {
            booking_info.tot_cost = booking_info.tot_cost + task.cost;
          });

          for (let i = 0; i < booking.pickup_bookings.length; i++) {
            const currentBooking = booking.pickup_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              console.log('gone:', currentBooking);

              date_gone.value = currentBooking.date_partner;
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;

              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point.address +
                ' ' +
                currentBooking.point.street_number +
                ', ' +
                currentBooking.point.cap +
                ' ' +
                currentBooking.point.city +
                ' ' +
                currentBooking.point.province;

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_pickup.name +
                    ' ' +
                    currentBooking.driver_pickup.surname)
                : 'Non assegnato';

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_pickup.telephone_number)
                : null;
            } else {
              console.log('return', currentBooking);
              date_return.value = currentBooking.date_partner;
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;

              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;

              booking_info.destination_point_return =
                currentBooking.point.address +
                ' ' +
                currentBooking.point.street_number +
                ', ' +
                currentBooking.point.cap +
                ' ' +
                currentBooking.point.city +
                ' ' +
                currentBooking.point.province;

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_pickup.name +
                    ' ' +
                    currentBooking.driver_pickup.surname)
                : 'Non assegnato';

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_pickup.telephone_number)
                : null;
            }
          }
        }

        if (booking.escort_bookings && booking.escort_bookings.length > 0) {
          for (let i = 0; i < booking.escort_bookings.length; i++) {
            const currentBooking = booking.escort_bookings[i];

            booking_info.title_prenotazione = 'Accompagna un cliente';

            if (currentBooking.tasks == true && booking.loaner == true) {
              booking_info.auto =
                booking.car.marca + ', ' + booking.car.modello;
              booking_info.targa = booking.car.targa;
              type.value = 6;
            } else if (
              currentBooking.tasks == false &&
              booking.loaner == true
            ) {
              type.value = 5;
            } else if (
              currentBooking.tasks == true &&
              booking.loaner == false
            ) {
              booking_info.auto =
                booking.car.marca + ', ' + booking.car.modello;
              booking_info.targa = booking.car.targa;
              type.value = 4;
            } else {
              type.value = 2;
            }

            if (currentBooking.type_booking === 'gone') {
              date_gone.value = currentBooking.date_partner;
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;

              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point_origin_escort.address +
                ' ' +
                currentBooking.point_origin_escort.street_number +
                ', ' +
                currentBooking.point_origin_escort.cap +
                ' ' +
                currentBooking.point_origin_escort.city +
                ' ' +
                currentBooking.point_origin_escort.province;

              booking_info.destination_point_gone =
                currentBooking.point_destination_escort.address +
                ' ' +
                currentBooking.point_destination_escort.street_number +
                ', ' +
                currentBooking.point_destination_escort.cap +
                ' ' +
                currentBooking.point_destination_escort.city +
                ' ' +
                currentBooking.point_destination_escort.province;

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_escort.name +
                    ' ' +
                    currentBooking.driver_escort.surname)
                : 'Non assegnato';

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_escort.telephone_number)
                : null;
            } else {
              date_return.value = currentBooking.date_partner;
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;
              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;

              booking_info.origin_point_return =
                currentBooking.point_origin_escort.address +
                ' ' +
                currentBooking.point_origin_escort.street_number +
                ', ' +
                currentBooking.point_origin_escort.cap +
                ' ' +
                currentBooking.point_origin_escort.city +
                ' ' +
                currentBooking.point_origin_escort.province;

              booking_info.destination_point_return =
                currentBooking.point_destination_escort.address +
                ' ' +
                currentBooking.point_destination_escort.street_number +
                ', ' +
                currentBooking.point_destination_escort.cap +
                ' ' +
                currentBooking.point_destination_escort.city +
                ' ' +
                currentBooking.point_destination_escort.province;

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_escort.name +
                    ' ' +
                    currentBooking.driver_escort.surname)
                : 'Non assegnato';

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_escort.telephone_number)
                : null;
            }
          }
        }
        if (booking.delivery_bookings && booking.delivery_bookings.length > 0) {
          for (let i = 0; i < booking.delivery_bookings.length; i++) {
            booking_info.title_prenotazione = "Consegna un'auto";

            type.value = 3;
            const currentBooking = booking.delivery_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              date_gone.value = currentBooking.date_point;
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;
              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point_origin_delivery.address +
                ' ' +
                currentBooking.point_origin_delivery.street_number +
                ', ' +
                currentBooking.point_origin_delivery.cap +
                ' ' +
                currentBooking.point_origin_delivery.city +
                ' ' +
                currentBooking.point_origin_delivery.province;

              booking_info.destination_point_gone =
                currentBooking.point_destination_delivery.address +
                ' ' +
                currentBooking.point_destination_delivery.street_number +
                ', ' +
                currentBooking.point_destination_delivery.cap +
                ' ' +
                currentBooking.point_destination_delivery.city +
                ' ' +
                currentBooking.point_destination_delivery.province;

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_delivery.name +
                    ' ' +
                    currentBooking.driver_delivery.surname)
                : 'Non assegnato';

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_delivery.telephone_number)
                : null;
            } else {
              date_return.value = currentBooking.date_point;
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;

              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;
              booking_info.origin_point_return =
                currentBooking.point_origin_delivery.address +
                ' ' +
                currentBooking.point_origin_delivery.street_number +
                ', ' +
                currentBooking.point_origin_delivery.cap +
                ' ' +
                currentBooking.point_origin_delivery.city +
                ' ' +
                currentBooking.point_origin_delivery.province;

              booking_info.destination_point_return =
                currentBooking.point_destination_delivery.address +
                ' ' +
                currentBooking.point_destination_delivery.street_number +
                ', ' +
                currentBooking.point_destination_delivery.cap +
                ' ' +
                currentBooking.point_destination_delivery.city +
                ' ' +
                currentBooking.point_destination_delivery.province;

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_delivery.name +
                    ' ' +
                    currentBooking.driver_delivery.surname)
                : 'Non assegnato';

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_delivery.telephone_number)
                : null;
            }
          }
        }

        return booking;
      } catch (e) {
        console.log(e);
      }
    };

    loadBooking();

    const showDeleteButton = ref(false);

    const checkBookingDate = (booking_info) => {
      const bookingDate = dayjs(booking_info); 
      const todayPlus3Days = dayjs().add(3, 'day');

      if (new Date(bookingDate)  > new Date(todayPlus3Days)) {
        showDeleteButton.value = true;
      }
    };

    

    const changeCondition = () => {
      condition1 = false;
    };

    const open = async () => {
      ElMessageBox.confirm(
        'Sei sicuro di voler eliminare la prenotazione?',
        'Warning',
        {
          confirmButtonText: 'Elimina',
          cancelButtonText: 'Annulla',
          type: 'warning',
        }
      )
        .then(async () => {
          await bookingAPI.deleteBooking(route.params.id);

          console.log('Ok');
          ElMessage({
            type: 'success',
            message: 'Eliminazione completata',
          });
          router.push('/');
        })
        .catch(() => {
          /*ElMessage({
            type: "info",
            message: "Annulla",
          });*/
        });
    };

    return {
      type,
      tableData,
      bookingId,
      booking_info,
      loadBooking,
      //onSubmit,
      condition1,
      condition2,
      changeCondition,
      activeName,
      open,
      hour_gone,
      hour_return,

      is_gone,
      is_return,
      noteVisibile,

      showDeleteButton,
      router
    };
  },

  methods: {
    onSubmit() {
      this.updBooking().then((response) => {
        if (response.status === 200) {
          this.$router.go('/');
          ElMessage({
            message: 'Stato del booking aggiornato!',
            type: 'success',
          });
          console.log('Stato del booking aggiornato!');
        } else {
          console.log('errore inatteso');
        }
      });
    },

    deleteTask(id) {
      
    this.$confirm('Sei sicuro di voler eliminare questa attività?', 'Conferma', {
      confirmButtonText: 'Sì',
      cancelButtonText: 'No',
      type: 'warning',
    }).then(async () => {
      
      //this.booking_info.tasks.splice(index, 1);
      await bookingAPI.deleteTask(id)
      this.$router.go('/');
      this.$message({
        type: 'success',
        message: 'Attività eliminata con successo!',
      });
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Eliminazione annullata',
      });
    });
  }
  },
};
</script>

<style>
.margin {
  margin-top: 30px;
}

hr {
  margin-top: 20px;
}

.marg_form {
  margin-top: 100px;
}

.el-input-group__append {
  background: transparent;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }

  200% {
    transform: scale(1);
  }
}
</style>
