<template>
<h1>TARIIFFE</h1>
<br />
<br />
<div>
    <el-form ref="tariffeForm" :model="tableData" :rules="formRules">
        <h3>PICKUP E DELIVERY </h3>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 5km" class="tariffe">
                    <el-input v-model="tableData.pickup_5km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 5km" class="tariffe" prop="partner_pickup_5km">
                    <el-input v-model="tableData.partner_pickup_5km" :disabled="type === 0" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 10km" class="tariffe">
                    <el-input v-model="tableData.pickup_10km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 10km" class="tariffe" prop="partner_pickup_10km">
                    <el-input v-model="tableData.partner_pickup_10km" :disabled="type === 0" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 15km" class="tariffe">
                    <el-input v-model="tableData.pickup_15km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 15km" class="tariffe" prop="partner_pickup_15km">
                    <el-input v-model="tableData.partner_pickup_15km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 20km" class="tariffe">
                    <el-input v-model="tableData.pickup_20km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 20km" class="tariffe" prop="partner_pickup_20km">
                    <el-input v-model="tableData.partner_pickup_20km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 25km" class="tariffe">
                    <el-input v-model="tableData.pickup_25km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 25km" class="tariffe" prop="partner_pickup_25km">
                    <el-input v-model="tableData.partner_pickup_25km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 30km" class="tariffe">
                    <el-input v-model="tableData.pickup_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 30km" class="tariffe" prop="partner_pickup_30km">
                    <el-input v-model="tableData.partner_pickup_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba over 30km" class="tariffe">
                    <el-input v-model="tableData.pickup_over_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner over 30km" class="tariffe" prop="partner_pickup_over_30km">
                    <el-input v-model="tableData.partner_pickup_over_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <br />
        <br />
        <h3>PICKUP E DELIVERY CON AUTO DI CORTESIA</h3>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 5km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_5km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 5km" class="tariffe" prop="partner_pickup_loaner_5km">
                    <el-input v-model="tableData.partner_pickup_loaner_5km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 10km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_10km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 10km" class="tariffe" prop="partner_pickup_loaner_10km">
                    <el-input v-model="tableData.partner_pickup_loaner_10km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 15km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_15km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 15km" class="tariffe" prop="partner_pickup_loaner_15km">
                    <el-input v-model="tableData.partner_pickup_loaner_15km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 20km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_20km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 20km" class="tariffe" prop="partner_pickup_loaner_20km">
                    <el-input v-model="tableData.partner_pickup_loaner_20km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 30km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 30km" class="tariffe" prop="partner_pickup_loaner_30km">
                    <el-input v-model="tableData.partner_pickup_loaner_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba over 30km" class="tariffe">
                    <el-input v-model="tableData.pickup_loaner_over_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner over 30km" class="tariffe" prop="partner_pickup_loaner_over_30km">
                    <el-input v-model="tableData.partner_pickup_loaner_over_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <br />
        <br />
       <!--  <h3>ACCOMPAGNA UN CLIENTE </h3>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 10km" class="tariffe">
                    <el-input v-model="tableData.escort_10km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 10km" class="tariffe" prop="partner_escort_10km">
                    <el-input v-model="tableData.partner_escort_10km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 15km" class="tariffe">
                    <el-input v-model="tableData.escort_15km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 15km" class="tariffe" prop="partner_escort_15km">
                    <el-input v-model="tableData.partner_escort_15km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 20km" class="tariffe">
                    <el-input v-model="tableData.escort_20km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 20km" class="tariffe" prop="partner_escort_20km">
                    <el-input v-model="tableData.partner_escort_20km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 30km" class="tariffe">
                    <el-input v-model="tableData.escort_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label=" tariffa partner 30km" class="tariffe" prop="partner_escort_30km">
                    <el-input v-model="tableData.partner_escort_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <br />
        <br />
        <br /> -->
        <h3>ACCOMPAGNA UN CLIENTE CON AUTO DEL PARTNER</h3>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 5km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_5km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 5km" class="tariffe" prop="partner_escort_loaner_5km">
                    <el-input v-model="tableData.partner_escort_loaner_5km" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 10km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_10km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 10km" class="tariffe" prop="partner_escort_loaner_10km">
                    <el-input v-model="tableData.partner_escort_loaner_10km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 15km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_15km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 15km" class="tariffe" prop="partner_escort_loaner_15km">
                    <el-input v-model="tableData.partner_escort_loaner_15km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 20km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_20km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 20km" class="tariffe" prop="partner_escort_loaner_20km">
                    <el-input v-model="tableData.partner_escort_loaner_20km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 30km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label=" tariffa partner 30km" class="tariffe" prop="partner_escort_loaner_30km">
                    <el-input v-model="tableData.partner_escort_loaner_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba over 30km" class="tariffe">
                    <el-input v-model="tableData.escort_loaner_over_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label=" tariffa partner over 30km" class="tariffe" prop="partner_escort_loaner_over_30km">
                    <el-input v-model="tableData.partner_escort_loaner_over_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <br />
        <br />
        <br />
        <h3>CONSEGNA UN'AUTO</h3>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 5km" class="tariffe">
                    <el-input v-model="tableData.delivery_5km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 5km" class="tariffe" prop="partner_delivery_5km">
                    <el-input v-model="tableData.partner_delivery_5km" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 10km" class="tariffe">
                    <el-input v-model="tableData.delivery_10km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 10km" class="tariffe" prop="partner_delivery_10km">
                    <el-input v-model="tableData.partner_delivery_10km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 15km" class="tariffe">
                    <el-input v-model="tableData.delivery_15km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 15km" class="tariffe" prop="partner_delivery_15km">
                    <el-input v-model="tableData.partner_delivery_15km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 20km" class="tariffe">
                    <el-input v-model="tableData.delivery_20km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 20km" class="tariffe" prop="partner_delivery_20km">
                    <el-input v-model="tableData.partner_delivery_20km" />
                </el-form-item>
            </el-col>
        </el-row>
            <el-row :gutter="30" justify="center">
                <el-col :sm="5" :xs="24">
                    <el-form-item label="tariffa simba 25km" class="tariffe">
                        <el-input v-model="tableData.delivery_25km" readonly="readonly" />
                    </el-form-item>
                </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa partner 25km" class="tariffe" prop="partner_delivery_25km">
                    <el-input v-model="tableData.partner_delivery_25km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba 30km" class="tariffe">
                    <el-input v-model="tableData.delivery_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label=" tariffa partner 30km" class="tariffe" prop="partner_delivery_30km">
                    <el-input v-model="tableData.partner_delivery_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30" justify="center">
            <el-col :sm="5" :xs="24">
                <el-form-item label="tariffa simba over 30km" class="tariffe">
                    <el-input v-model="tableData.delivery_over_30km" readonly="readonly" />
                </el-form-item>
            </el-col>
            <el-col :sm="5" :xs="24">
                <el-form-item label=" tariffa partner over 30km" class="tariffe" prop="partner_delivery_over_30km">
                    <el-input v-model="tableData.partner_delivery_over_30km" />
                </el-form-item>
            </el-col>
        </el-row>
        <br />
    
    </el-form>
</div>

<el-button> Annulla </el-button>

<el-button color="rgb(231,228,58)" :dark="true" @click="onModificaClick('tariffeForm')">Modifica </el-button>
</template>

<script>
import partnerAPI from '../services/partnerAPI.js'
import {
    reactive,
    ref
} from "vue";
import {
    ElMessage
} from 'element-plus';
import {
    isLoggedStore
} from '../store/logged';

export default {
    setup() {

        const storeLogin = isLoggedStore();


        let tableData = ref({
            id: "",
            company_name: "",
            piva: "",
            CF: "",
            SDI: "",
            PEC: "",
            mail: "",
            phone_number: "",
            IBAN: "",
            CAB: "",
            ABI: "",
            RID: "",
            provvigione: "",
            API_key: "",
            delivery_10km: 0.0,
            delivery_5km: 0.0,
            partner_delivery_10km: 0.0,
            partner_delivery_5km: 0.0,
            delivery_15km: 0.0,
            partner_delivery_15km: 0.0,
            delivery_20km: 0.0,
            partner_delivery_20km: 0.0,
            delivery_25km: 0.0,
            partner_delivery_25km: 0.0,
            delivery_30km: 0.0,
            partner_delivery_30km: 0.0,            
            delivery_over_30km: 0.0,
            partner_delivery_over_30km: 0.0,
            pickup_5km: 0.0,
            pickup_10km: 0.0,
            pickup_15km: 0.0,
            pickup_20km: 0.0,
            pickup_25km: 0.0,
            pickup_30km: 0.0,
            pickup_over_30km: 0.0,
            partner_pickup_5km: 0.0,
            partner_pickup_10km: 0.0,
            partner_pickup_15km: 0.0,
            partner_pickup_20km: 0.0,
            partner_pickup_25km: 0.0,
            partner_pickup_30km: 0.0,
            partner_pickup_over_30km: 0.0,
            pickup_loaner_5km: 0.0,
            pickup_loaner_10km: 0.0,
            partner_pickup_loaner_5km: 0.0,
            partner_pickup_loaner_10km: 0.0,
            pickup_loaner_15km: 0.0,
            partner_pickup_loaner_15km: 0.0,
            pickup_loaner_20km: 0.0,
            partner_pickup_loaner_20km: 0.0,
            pickup_loaner_30km: 0.0,
            pickup_loaner_over_30km: 0.0,
            partner_pickup_loaner_30km: 0.0,
            partner_pickup_loaner_over_30km: 0.0,
            escort_5km: 0.0,
            escort_10km: 0.0,
            escort_15km: 0.0,
            escort_20km: 0.0,
            escort_30km: 0.0,
            escort_over_30km: 0.0,
            partner_escort_5km: 0.0,
            partner_escort_10km: 0.0,
            partner_escort_15km: 0.0,
            partner_escort_20km: 0.0,
            partner_escort_30km: 0.0,
            partner_escort_over_30km: 0.0,
            escort_loaner_5km: 0.0,
            escort_loaner_10km: 0.0,
            partner_escort_loaner_5km: 0.0,
            partner_escort_loaner_10km: 0.0,
            escort_loaner_15km: 0.0,
            partner_escort_loaner_15km: 0.0,
            escort_loaner_20km: 0.0,
            partner_escort_loaner_20km: 0.0,
            escort_loaner_30km: 0.0,
            partner_escort_loaner_30km: 0.0,            
            escort_loaner_over_30km: 0.0,
            partner_escort_loaner_over_30km: 0.0,
        });

        const loadTariffe = async () => {
            try {

                const employeeId = storeLogin.employee.id;
              
                const resp = await partnerAPI.getPartnerByEmployee(employeeId);
                const partner = resp.data[0].headquarters[0].partner;

                tableData.value.id=partner.id;
                tableData.value.company_name=partner.company_name;
                tableData.value.piva=partner.piva;
                tableData.value.CF=partner.CF;
                tableData.value.SDI=partner.SDI;
                tableData.value.PEC=partner.PEC;
                tableData.value.mail=partner.mail;
                tableData.value.phone_number=partner.phone_number;
                tableData.value.IBAN=partner.IBAN;
                tableData.value.CAB=partner.CAB;
                tableData.value.ABI=partner.ABI;
                tableData.value.RID=partner.RID;
                tableData.value.provvigione=partner.provvigione;

                tableData.value.delivery_10km = partner.delivery_10km;
                tableData.value.delivery_5km = partner.delivery_5km;
                tableData.value.partner_delivery_5km = partner.partner_delivery_5km;
                tableData.value.partner_delivery_10km = partner.partner_delivery_10km;
                tableData.value.delivery_15km = partner.delivery_15km;
                tableData.value.partner_delivery_15km = partner.partner_delivery_15km;
                tableData.value.delivery_20km = partner.delivery_20km;
                tableData.value.partner_delivery_20km = partner.partner_delivery_20km;
                tableData.value.delivery_25km = partner.delivery_25km;
                tableData.value.partner_delivery_25km = partner.partner_delivery_25km;
                tableData.value.delivery_30km = partner.delivery_30km;
                tableData.value.partner_delivery_30km = partner.partner_delivery_30km;                
                tableData.value.delivery_over_30km = partner.delivery_over_30km;
                tableData.value.partner_delivery_over_30km = partner.partner_delivery_over_30km;

                tableData.value.pickup_5km = partner.pickup_5km;
                tableData.value.pickup_10km = partner.pickup_10km;
                tableData.value.pickup_15km = partner.pickup_15km;
                tableData.value.pickup_20km = partner.pickup_20km;
                tableData.value.pickup_25km = partner.pickup_25km;
                tableData.value.pickup_30km = partner.pickup_30km;
                tableData.value.pickup_over_30km = partner.pickup_over_30km;
                tableData.value.partner_pickup_5km = partner.partner_pickup_5km;
                tableData.value.partner_pickup_10km = partner.partner_pickup_10km;
                tableData.value.partner_pickup_15km = partner.partner_pickup_15km;
                tableData.value.partner_pickup_20km = partner.partner_pickup_20km;
                tableData.value.partner_pickup_25km = partner.partner_pickup_25km;
                tableData.value.partner_pickup_30km = partner.partner_pickup_30km;
                tableData.value.partner_pickup_over_30km = partner.partner_pickup_over_30km;

                tableData.value.pickup_loaner_5km = partner.pickup_loaner_5km;
                tableData.value.partner_pickup_loaner_5km = partner.partner_pickup_loaner_5km;
                tableData.value.pickup_loaner_10km = partner.pickup_loaner_10km;
                tableData.value.partner_pickup_loaner_10km = partner.partner_pickup_loaner_10km;
                tableData.value.pickup_loaner_15km = partner.pickup_loaner_15km;
                tableData.value.partner_pickup_loaner_15km = partner.partner_pickup_loaner_15km;
                tableData.value.pickup_loaner_20km = partner.pickup_loaner_20km;
                tableData.value.partner_pickup_loaner_20km = partner.partner_pickup_loaner_20km;
                tableData.value.pickup_loaner_30km = partner.pickup_loaner_30km;
                tableData.value.partner_pickup_loaner_30km = partner.partner_pickup_loaner_30km;                
                tableData.value.pickup_loaner_over_30km = partner.pickup_loaner_over_30km;
                tableData.value.partner_pickup_loaner_over_30km = partner.partner_pickup_loaner_over_30km;

                tableData.value.escort_5km = partner.escort_5km;
                tableData.value.escort_10km = partner.escort_10km;
                tableData.value.escort_15km = partner.escort_15km;
                tableData.value.escort_20km = partner.escort_20km;
                tableData.value.escort_30km = partner.escort_30km;
                tableData.value.escort_over_30km = partner.escort_over_30km;
                tableData.value.partner_escort_5km = partner.partner_escort_5km;
                tableData.value.partner_escort_10km = partner.partner_escort_10km;
                tableData.value.partner_escort_15km = partner.partner_escort_15km;
                tableData.value.partner_escort_20km = partner.partner_escort_20km;
                tableData.value.partner_escort_30km = partner.partner_escort_30km;
                tableData.value.partner_escort_over_30km = partner.partner_escort_over_30km;

                tableData.value.escort_loaner_5km = partner.escort_loaner_5km;
                tableData.value.escort_loaner_10km = partner.escort_loaner_10km;
                tableData.value.partner_escort_loaner_5km = partner.partner_escort_loaner_5km;
                tableData.value.partner_escort_loaner_10km = partner.partner_escort_loaner_10km;
                tableData.value.escort_loaner_15km = partner.escort_loaner_15km;
                tableData.value.partner_escort_loaner_15km = partner.partner_escort_loaner_15km;
                tableData.value.escort_loaner_20km = partner.escort_loaner_20km;
                tableData.value.partner_escort_loaner_20km = partner.partner_escort_loaner_20km;
                tableData.value.escort_loaner_30km = partner.escort_loaner_30km;
                tableData.value.partner_escort_loaner_30km = partner.partner_escort_loaner_30km;                
                tableData.value.escort_loaner_over_30km = partner.escort_loaner_over_30km;
                tableData.value.partner_escort_loaner_over_30km = partner.partner_escort_loaner_over_30km;


            } catch (e) {

                console.log("errore load tariffe: ", e);
            }
        };

        loadTariffe();

        let formRules = reactive({
            partner_pickup_5km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_5km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_5km}`));
                        }
                    },
                    trigger: 'blur',
                },
            ],
            partner_pickup_10km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_10km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_10km}`));
                        }
                    },
                    trigger: 'blur',
                },
            ],
            partner_pickup_15km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_15km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_15km}`));
                        }
                    },

                }
            ],
            partner_pickup_20km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_20km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_20km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_25km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_25km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_25km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_over_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_over_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_over_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_5km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_5km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_5km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_10km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_10km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_10km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_15km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_15km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_15km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_20km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_20km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_20km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_pickup_loaner_over_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.pickup_loaner_over_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.pickup_loaner_over_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_5km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_5km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_5km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],

            partner_escort_10km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_10km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_10km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_15km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_15km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_15km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_20km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_20km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_20km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_loaner_5km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_5km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_5km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],

            partner_escort_loaner_10km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_10km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_10km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_loaner_15km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_15km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_15km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_loaner_20km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_20km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_20km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_loaner_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_escort_loaner_over_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.escort_loaner_over_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.escort_loaner_over_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_5km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_5km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_5km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_10km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_10km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_10km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_15km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_15km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_15km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_20km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_20km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_20km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_25km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_25km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_25km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],
            partner_delivery_over_30km: [{
                    required: true,
                    message: 'Inserire valore',
                    trigger: 'blur'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value >= tableData.value.delivery_over_30km) {
                            callback();
                        } else {
                            callback(new Error(`Deve essere >= a ${tableData.value.delivery_over_30km}`));
                        }
                    },

                    trigger: 'blur'
                }
            ],

        })

        const updateTariffe = async (partner) => {

            try {
                const response = await partnerAPI.updateTariffe(partner.id, partner);
                return response;
            } catch (error) {
                console.log(error);
            }
        };

        return {
            updateTariffe,
            tableData,
            formRules,

        };

    },

    methods: {

        onModificaClick(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                     this.updateTariffe((this.tableData)).then((response) => {
                        if (response.status === 200) {
                            this.$router.push('/tariffe');
                            ElMessage({
                                message: "Tariffe modificate!",
                                type: "success",
                            });
                        } else if (response.status === 400) {
                            //this.$router.push('/200');
                            ElMessage({
                                message: "Errore!",
                                type: "error",
                            });
                        }
                    })
                } else {
                    ElMessage({
                        message: "Errore!",
                        type: "error",
                    });
                    console.log("erroree");
                }
            })
        }
    }
};
</script>

<style>
.tariffe {
    margin-top: -10%;
}

.service {
    margin-top: 10%;
}

.salva {
    margin-top: -40px;
    margin-bottom: 10px;
}
</style>
