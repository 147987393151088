<template>
  <TitleComponent :title="customer.name + ' ' + customer.surname" />
  <el-card v-if="minutesByCar.length != 0">
    <TitleComponent title="Booking gratuiti assegnati al cliente" />

    <div
      v-for="(minute, counter_minute) in minutesByCar"
      v-bind:key="counter_minute"
    >
      <el-row :gutter="20" justify="center" style="width: 100%">
        <el-col :span="3">
          <el-form-item label="targa">
            <el-input v-model="minute.targa" readonly="readonly" />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="valet">
            <el-input v-model="minute.valet" readonly="readonly" />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="valet residui">
            <el-input v-model="minute.residual_valet" readonly="readonly" />
          </el-form-item>
        </el-col>

        <el-col :span="5">
          <el-form-item label="scadenza minuti">
            <el-date-picker
              v-model="minute.expiration_valet"
              readonly="readonly"
              type="date"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-card>
  <el-card class="custom-card">
    <el-form ref="formRef" :model="customer" :rules="formRule">
      <FormCustomerComponent
        v-if="customer.company_name == '' || customer.company_name == null"
        :label="customer.label"
        :loading="loadingPoint"
        :handleInputBlur="handleInputBlur"
        :value="customer"
        :type="type"
        :rules="formRule"
      />
      <FormComponent
        v-else
        :label="customer.label"
        :loading="loadingPoint"
        :handleInputBlur="handleInputBlur"
        :value="customer"
        :type="type"
        :rules="formRule"
      />
    </el-form>
    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/customer"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Modifica i dati del cliente"
        :isRouter="false"
        @buttonClick="update"
      ></ButtonComponent>
    </el-row>
    <TitleComponent title="AUTO DEL CLIENTE" />
    <el-card class="custom-card">
      <div
        v-for="(single_car, counter_car) in carsEditable"
        v-bind:key="counter_car"
      >
        <el-form
          ref="form2"
          :model="single_car"
          :rules="formRuleCar(counter_car)"
        >
          <FormCarComponent
            :car="single_car"
            :type="type"
            :rules="formRule"
            :options="options"
            @change="handleChange"
            @buttonClick="modifyCar(single_car)"
            :inputTransmission="true"
            :modifyButom="true"
            :deleteCar="false"
          ></FormCarComponent>
        </el-form>
      </div>

      <div v-for="(single_car, counter_car) in cars" v-bind:key="counter_car">
        <el-form
          ref="form2"
          :model="single_car"
          :rules="formRuleCar(counter_car)"
        >
          <FormCarComponent
            :car="single_car"
            :type="type"
            :rules="formRule"
            :options="options"
            @change="handleChange"
            :inputTransmission="true"
            :modifyButom="false"
            
            :deleteCar="false"
          ></FormCarComponent>
        </el-form>
      </div>
    </el-card>

    <br />
    <div v-if="storeLogin.role == 'salesvendor'">
      <el-card class="card" shadow="hover">
        <TitleComponent title="Assegna booking gratuiti al cliente" />

        <div v-for="(minute, counter_min) in minutes" v-bind:key="counter_min">
          <div class="flex-container-car">
            <div style="padding-right: 20px">
              <SelectComponent
                @selected="(newValue) => (minute.car = newValue)"
                :options="carsId"
                title="Selezionare un'auto"
              ></SelectComponent>
            </div>
            <div class="input-group">
              <label for="valetInput">Numero di booking gratuiti </label>

              <el-input-number
                v-model="minute.valet"
                :min="0"
                :max="3"
                @change="handleChangeValet(minute)"
              />
            </div>

            <DescriptionComponent
              name="Tariffa"
              icon="PriceTag"
              :label="minute.tariffa + ' €'"
              :loading="false"
              style=""
            ></DescriptionComponent>

            <div class="delete-button">
              <ButtonComponent
                :ifCondition="counter_min != 0 ? true : false"
                :isRouter="false"
                @buttonClick="rimuoviMinute($event, counter_min)"
                type="danger"
                icon="Delete"
              ></ButtonComponent>
            </div>
          </div>
        </div>
        <div class="button-container">
          <ButtonComponent
            label="Aggiungi valet"
            :isRouter="false"
            @buttonClick="aggiungiMinute"
            type="success"
          ></ButtonComponent>
        </div>
        <div class="button-container">
          <ButtonComponent
            label="Aggiungi i nuovi valet"
            :isRouter="false"
            @buttonClick="addMinute"
          ></ButtonComponent>
        </div>
      </el-card>
    </div>

    <div v-if="storeLogin.role == 'employee'">
      <TitleComponent title="Aggiungi auto" />
      <el-card class="custom-card">
        <div
          v-for="(single_car_new, counter_car) in carsNew"
          v-bind:key="counter_car"
        >
          <el-divider />
          <el-form
            ref="form2"
            :model="single_car_new"
            :rules="formRuleCar(counter_car)"
          >
            <FormCarComponent
              :car="single_car_new"
              :type="type"
              :rules="formRule"
              :options="options"
              @change="handleChange"
              :ifCondition="counter_car != 0 ? true : false"
              @buttonClick="rimuoviCar"
              :counterCar="counter_car"
            ></FormCarComponent>
          </el-form>
        </div>
        <ButtonComponent
          label="Aggiungi auto"
          :isRouter="false"
          @buttonClick="aggiungiCar"
          type="success"
          :plain="true"
        ></ButtonComponent>

        <div v-if="carsNew.length > 0">
          <el-row justify="center" style="margin-top: 3.5%">
            <ButtonComponent
              label="Annulla"
              :isRouter="true"
              linkPath="/customer"
              @buttonClick="annulla"
            ></ButtonComponent>

            <ButtonComponent
              label="Conferma"
              :isRouter="false"
              type="primary"
              @buttonClick="onSubmitCar('form2')"
            ></ButtonComponent>
          </el-row>
        </div>
      </el-card>
    </div>
  </el-card>
</template>

<script>
import partnerAPI from '@/services/partnerAPI';
import carAPI from '../services/carAPI.js';
import { ref, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { reactive } from 'vue';
import { ElMessage } from 'element-plus';
import customerAPI from '../services/customerAPI.js';
import { Delete } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import FormCarComponent from '../Components/FormCarComponent.vue';
import FormComponent from '../Components/FormComponent.vue';
import TitleComponent from '../Components/TitleComponent.vue';
import ButtonComponent from '../Components/ButtonComponent.vue';
import SelectComponent from '../Components/SelectComponent.vue';
import DescriptionComponent from '../Components/DescriptionComponent.vue';
import storeCustomer from '../store/customer.js';
import { isLoggedStore } from '../store/logged';
import FormCustomerComponent from '@/Components/FormCustomerComponent.vue';
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import bookingAPI from '../services/bookingAPI.js';
export default {
  name: 'CustomerAlivePage',
  components: {
    FormCarComponent,
    FormComponent,
    ButtonComponent,
    TitleComponent,
    FormCustomerComponent,
    SelectComponent,
    DescriptionComponent,
  },
  setup() {
    const route = useRoute();

    const props1 = {
      expandTrigger: 'hover',
    };
    let carsId = ref([]);
    const storeLogin = isLoggedStore();

    storeCustomer.customerId = route.params.id;

    let minutesByCar = ref([]);
    const new_minutes = ref([{}]);
    let minutes = ref([
      {
        valet: 0,
        tariffa: 0,
        car: {},
      },
    ]);

    const router = useRouter();
    const customer = reactive({
      id: '',
      name: '',
      surname: '',
      date_birth: '',
      company_name: '',
      piva: '',
      CF: '',
      SDI: '',
      PEC: '',
      mail: '',
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      telephone_number: '',
      role: 'customer',
      isAlive: true,
    });

    const rimuoviMinute = (e, counter) => {
      minutes.value.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiMinute = (e) => {
      minutes.value.push({
        valet: 0,
        tariffa: 0,
        car: {},
      });
      e.preventDefault();
    };

    const postMinute = async (minute) => {
      try {
        let resp = await carAPI.addMinute(minute);
        await carAPI.mailAddMinute(resp.data.id);
        return resp;
      } catch (e) {
        console.log(e);
      }
    };

    const addMinute = async () => {
      try {
        minutes.value.forEach(async (minute) => {
          let car = await carAPI.getCarById(minute.car[0]);

          let newMinute = {
            valet: minute.valet,
            residual_valet: minute.valet,
            expiration_valet: new Date(),
            imponibile: minute.tariffa,
            tot_fattura: minute.tariffa + (minute.tariffa * 22) / 100,
            rif_fattura: 0,
            esito: false,
            accepted: false,
            data: new Date(),
            car: car.data[0],
            partner: partner.value,
          };

          newMinute.expiration_valet.setFullYear(
            newMinute.expiration_valet.getFullYear() + minute.valet
          );

          postMinute(toRaw(newMinute)).then((response) => {
            if (response.status === 201) {
              ElMessage({
                message: 'Cliente aggiornato con successo!',
                type: 'success',
              });
              router.push('/customer');
            } else {
              console.log('errore inatteso');
            }
          });
        });
      } catch (e) {
        ElMessage({
          message: 'Errore!',
          type: 'error',
        });
      }
    };

    const loadDetails = async () => {
      try {
        const response = await customerAPI.getCustomer(route.params.id);

        customer.id = response.data.id;
        customer.name = response.data.name;
        customer.surname = response.data.surname;
        customer.date_birth = response.data.date_birth;
        customer.mail = response.data.mail;

        customer.address = response.data.address;
        customer.street_number = response.data.street_number;
        customer.city = response.data.city;
        customer.cap = response.data.cap;
        customer.province = response.data.province;
        customer.telephone_number = response.data.telephone_number;

        customer.company_name = response.data.company_name;
        customer.piva = response.data.piva;
        customer.SDI = response.data.SDI;
        customer.PEC = response.data.PEC;
        customer.CF = response.data.CF;

        customer.isAlive = response.data.isAlive;

        await loadCars(customer.id);
      } catch (e) {
        console.log(e);
      }
    };

    //macchina
    const options = [
      {
        value: 'manual',
        label: 'manuale',
      },
      {
        value: 'automatic',
        label: 'automatico',
      },
    ];
    const formRuleCar = (counter) => {
      return {
        intestatario: [
          {
            required: true,
            message: 'Inserire intestatario macchina ' + counter,
            trigger: 'blur',
          },
        ],
        marca: [
          {
            required: true,
            message: 'Inserire marca',
            trigger: 'blur',
          },
        ],
        modello: [
          {
            required: true,
            message: 'Inserire modello',
            trigger: 'blur',
          },
        ],
        targa: [
          {
            required: true,
            message: 'Inserire targa',
            trigger: 'blur',
          },
        ],
        transmission: [
          {
            required: true,
            message: 'Scegliere cambio',
            trigger: 'blur',
          },
        ],
      };
    };
    let transmission = ref('');
    const handleChange = (val) => {
      transmission.value = val;
    };
    const carsNew = reactive([]);

    const cars = reactive([]);
    const carsEditable = reactive([]);

    const processCar = (car) => {
      const tableRow = {
        id: car.id,
        intestatario: car.intestatario,
        marca: car.marca,
        modello: car.modello,
        targa: car.targa,
        transmission: car.transmission,
        customer: car.customer,
      };
      return tableRow;
    };

    let headquarters = ref([{}]);

    const loadHeadquarters = async () => {
      try {
        const employeeId = storeLogin.employee.id;
        const response = await bookingAPI.getHeadquartersByEmployee(employeeId);
        headquarters.value = response.data[0];
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };

    loadHeadquarters();

    const loadCars = async (customerId) => {
      try {
        const employeeId = storeLogin.employee.id;
        const response = await carAPI.getCarsByCustomer(customerId);
        formatCar(response.data);
        response.data.forEach((car) => {
          const isEmployeeAssociated = car.headquarters.some((headquarter) =>
            headquarter.employees.some((employee) => employee.id === employeeId)
          );
            const tableRow = processCar(car);
            if (isEmployeeAssociated) {
              carsEditable.push(tableRow);
            } else {
              if (car.transmission === 'manual') {
                tableRow.transmission = 'manuale';
              } else if (car.transmission === 'automatic') {
                tableRow.transmission = 'automatico';
              } else {
                tableRow.transmission = 'entrambi';
              }
              cars.push(tableRow);
            }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const formatCar = (cars) => {
      cars.forEach((car) => {
        let tableRow = {
          value: car.id,
          label: `${car.marca} ${car.modello} ${car.targa}`,
        };
        console.lo;

        carsId.value.push(tableRow);
      });
    };
    const addCar = async (car) => {
      let response = {};
      try {
        if (car.transmission === 'manuale') {
          car.transmission = 'manual';
        } else if (car.transmission === 'automatico') {
          car.transmission = 'automatico';
        } else {
          car.transmission = 'both';
        }
        response = await carAPI.addCar(car);
        return response;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };
    const rimuoviCar = (e, counter) => {
      carsNew.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiCar = (e) => {
      carsNew.push({
        intestatario: '',
        marca: '',
        modello: '',
        telaio: '',
        transmission: '',
        customer: {},
        headquarters: [{}],
      });
      e.preventDefault();
    };

    const modifyCar = async (car) => {
      let response;
      try {
        if (car.transmission === 'manuale') {
          car.transmission = 'manual';
        } else if (car.transmission === 'automatico') {
          car.transmission = 'automatico';
        } else {
          car.transmission = 'both';
        }

        response = await carAPI.modifyCar(car);
        if (response.status === 200) {
          ElMessage({
            message: 'Macchina modificata con successo!',
            type: 'success',
          });

          router.push('/customer');
        } else {
          ElMessage({
            message: 'Errore modifica auto!',
            type: 'error',
          });
          console.log('errore modifica auto');
        }
      } catch (e) {
        console.log(e);
      }
    };

    let partner = ref({});

    const loadMinutes = async () => {
      try {
        const employeeId = storeLogin.employee.id;
        const res = await partnerAPI.getPartnerByEmployee(employeeId);
        partner.value = res.data[0].headquarters[0].partner;
        const response = await carAPI.getMinutesByCustomer(
          route.params.id,
          res.data[0].headquarters[0].partner.id
        );
        let tableRow = {};

        response.data.forEach((minute) => {
          tableRow = {
            id: minute.car.id,
            customer: `${minute.car.customer.name} ${minute.car.customer.surname}`,
            valet: minute.valet,
            residual_valet: minute.residual_valet,
            expiration_valet: minute.expiration_valet,
            marca: minute.car.marca,
            modello: minute.car.modello,
            targa: minute.car.targa,
          };
          minutesByCar.value.push(tableRow);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const update = () => {
      updCustomer(toRaw(customer)).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Cliente aggiornato con successo!',
            type: 'success',
          });
          router.push('/');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updCustomer = async (customer) => {
      let response = {};
      try {
        response = await customerAPI.updCustomer(customer);
        return response;
      } catch {
        console.log('error');
      }
    };

    const update_minute = () => {
      try {
        updMinute(toRaw(minutesByCar)).then((response) => {
          if (response.status === 200) {
            console.log('Minuti aggiornati con successo!');
          } else {
            console.log('errore inatteso');
          }
        });
        ElMessage({
          message: 'Minuti aggiornati con successo!',
          type: 'success',
        });
      } catch (error) {
        console.log(error);
        ElMessage({
          message: "Errore nell'aggiornamento",
          type: 'error',
        });
      }
    };

    const updMinute = async (minute) => {
      let response = {};
      try {
        minute.customer = customer;
        response = await customerAPI.updMinute(minute);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    const handleChangeValet = (minute) => {
      if (minute.valet == 1) {
        minute.tariffa = partner.value.one_valet;
      }
      if (minute.valet == 2) {
        minute.tariffa = partner.value.two_valet;
      }
      if (minute.valet == 3) {
        minute.tariffa = partner.value.three_valet;
      }
    };

    loadDetails();
    loadMinutes();

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });

        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    let responsePoint = ref({
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      latitude: '',
      longitude: '',
    });

    const handleInputBlur = async () => {
        
      if (
        customer.address != '' &&
        customer.street_number != '' &&
        customer.city != '' &&
        customer.cap != '' &&
        customer.province != ''
      ) {

        let point = {
          address: customer.address,
          street_number: customer.street_number,
          city: customer.city,
          cap: customer.cap,
          province: customer.province,
        };

        let response = await loadPoints(point);
        customer.label = response.label;
        customer.latitude = response.point.latitude;
        customer.longitude = response.point.longitude;

        responsePoint.value.address = response.point.address;
        responsePoint.value.street_number = response.point.street_number;
        responsePoint.value.city = response.point.city;
        responsePoint.value.cap = response.point.cap;
        responsePoint.value.province = response.point.province;
        responsePoint.value.latitude = response.point.latitude;
        responsePoint.value.longitude = response.point.longitude;
      }
    };

    return {
      update,
      minutesByCar,
      loadMinutes,
      new_minutes,
      update_minute,
      Delete,
      customer,
      modifyCar,
      addCar,
      cars,
      carsEditable,
      props1,
      formRuleCar,
      handleChange,
      rimuoviCar,
      aggiungiCar,
      transmission,
      options,
      carsNew,
      headquarters,
      storeLogin,
      minutes,
      carsId,
      addMinute,
      rimuoviMinute,
      aggiungiMinute,
      handleChangeValet,
      handleInputBlur,
      loadingPoint,
    };
  },
  methods: {
    onSubmitCar(formName) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          this.carsNew.forEach((car) => {
            car.customer = this.customer;
            car.headquarters = this.headquarters.headquarters;
            console.log(car);
            this.addCar(toRaw(car)).then((response) => {
              if (response.status === 201) {
                this.$router.push('/');
                ElMessage({
                  message: 'Macchina aggiunta con successo!',
                  type: 'success',
                });
              } else {
                ElMessage({
                  message: 'Errore inserimento auto!',
                  type: 'error',
                });
                console.log('errore aggiunta auto');
              }
            });
          });
        } else {
          console.log('erroree');
        }
      });
    },
  },
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}
</style>
