<template>
  <h1>CLIENTI</h1>
  <br />

  <AddNewCustomerComponent></AddNewCustomerComponent>

  <br /><br /><br /><br />
  <h2>Cliente in attesa di conferma</h2>
  <div class="top">
    <el-input v-model="input" placeholder="Inserisci la targa">
      <template #append>
        <el-button>
          <el-icon>
            <search />
          </el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table
      :data="filteredTableNotAlive"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: 'rgb(231,228,58)' }"
    >
      <el-table-column prop="customer" label="Cliente" />
      <el-table-column prop="valet" label="Valet" />
      <el-table-column prop="marca" label="Marca" />
      <el-table-column prop="modello" label="Modello" />
      <el-table-column prop="targa" label="Targa" />
      <el-table-column label="Dettagli">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/customerAlive/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.min_id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <br /><br /><br /><br />

  <h2>Tutti i clienti</h2>
  <div style="margin-left:87%">
  <el-button  @click="downloadExcel">Scarica Excell lista clienti</el-button>
</div>

  <div class="top">
    <el-input v-model="input2" placeholder="Inserisci il nome del customer">
      <template #append>
        <el-button>
          <el-icon>
            <search />
          </el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table
      :data="filteredTableAliveData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: 'rgb(231,228,58)' }"
    >
      <el-table-column prop="name" label="Nome" />
      <el-table-column prop="mail" label="Mail" />
      <el-table-column prop="telephone" label="Telefono" />
      <el-table-column prop="dateBirth" label="Data di nascita" />
      <el-table-column label="Dettagli">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/customerAlive/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="filteredTableAlive.length">
    </el-pagination>
</div>
</template>

<script>
import { ref, computed } from "vue";
import customerAPI from "../services/customerAPI";
import partnerAPI from "@/services/partnerAPI";
import carAPI from "../services/carAPI.js";
import { isLoggedStore } from "../store/logged";
import AddNewCustomerComponent from "@/Components/AddNewCustomerComponent.vue";
import ExcelJS from "exceljs";
const dayjs = require("dayjs");

export default {
  data() {
        return {
            page: 1,
            pageSize: 15
        }
  },
  setup() {
    const value = ref(0);
    const input = ref("");
    const input2 = ref("");
    let tableDataAlive = ref([]);
    let tableDataNotAlive = ref([]);
    const storeLogin = isLoggedStore();
    //cliente
    const loadCustomers = async () => {
      tableDataAlive.value = [];
      try {
        const employeeId = storeLogin.employee.id;
        const responsePartner = await partnerAPI.getPartnerByEmployee(employeeId);
        const partner = responsePartner.data[0].headquarters[0].partner;
        const response = await customerAPI.getCustomersByPartner(partner.id);

        response.data.forEach((customer_detail) => {
          if (customer_detail.is_alive == true) {
            let tableRowAlive = {
              id: customer_detail.id,
              name: `${customer_detail.name} ${customer_detail.surname}`,
              mail: customer_detail.mail,
              telephone: customer_detail.telephone_number,
              dateBirth: dayjs(customer_detail.date_birth).format('DD/MM/YYYY'),
            };
            tableDataAlive.value.push(tableRowAlive);
          }
        });
      } catch (error) {
        console.log(error);
        console.log("error customer");
      }
    };
    //AUTO
    const loadCars = async () => {
      try {
        const employeeId = storeLogin.employee.id;
        const partner = await partnerAPI.getPartnerByEmployee(employeeId);
        //const response = await carAPI.getMinutesByCustomer(route.params.id, res.data[0].headquarters[0].partner.id);
        const response = await carAPI.getMinutesByPartner(
          partner.data[0].headquarters[0].partner.id
        );
        
        let tableRow = {};
        response.data.forEach((minute) => {

          //if (car.minutes.length != 0) {
          //car.minutes.forEach((minute) => {
          if (minute.accepted == false) {
            tableRow = {
              id: minute.car.customer.id,
              customer: `${minute.car.customer.name} ${minute.car.customer.surname}`,
              valet: minute.valet,
              marca: minute.car.marca,
              modello: minute.car.modello,
              targa: minute.car.targa,
            };
            tableDataNotAlive.value.push(tableRow);
          }
          //})
          //}
        });
      } catch (error) {
        console.log(error);
      }
    };
    loadCustomers();
    loadCars();
    const filteredTableNotAlive = computed(function () {
      return tableDataNotAlive.value.filter((row) =>
        row.targa.toLowerCase().includes(input.value.toLowerCase())
      );
    });
    const filteredTableAlive = computed(function () {
      return tableDataAlive.value.filter((row) =>
        row.name.toLowerCase().includes(input2.value.toLowerCase())
      );
    });


    const downloadExcel = () =>{
      generateExcel(tableDataAlive.value);
    }

    const generateExcel= (data) =>{
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Clienti Minute");

      // Define the columns
      worksheet.columns = [
        { header: "ID cliente", key: "id" },
        { header: "Nome", key: "name" },
        { header: "Mail", key: "mail" },
        { header: "Telefono", key: "telephone_number" },
      ];

      // Add the data
      data.forEach((row) => {
        worksheet.addRow({
          id: row.id,
          name: row.name,
          mail: row.mail,
          data: row.data,
        });
      });

      // Generate the Excel file
      workbook.xlsx.writeBuffer().then((excelBuffer) => {
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);

        // Create a download link and trigger a click to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = "clienti.xlsx";
        a.click();

        window.URL.revokeObjectURL(url);
      });
    }

    return {
      input,
      input2,
      value,
      tableDataAlive,
      tableDataNotAlive,
      filteredTableAlive,
      filteredTableNotAlive,
      downloadExcel
    };
  },
  components: { AddNewCustomerComponent },
  computed: {
    filteredTableAliveData() {
            if (!this.filteredTableAlive || this.filteredTableAlive.length === 0) return [];
            return this.filteredTableAlive.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
    },
};
</script>

<style>
.top {
  margin-top: 30px;
}
</style>

