<template>
  <div style="margin-top: 1%">
    <h2>PRENOTA UNA CORSA</h2>
  </div>

  <el-row justify="center" style="margin-top: -2.5%">
    <el-col :span="5" :offset="1">
      
      <CardComponent
        :imgSrc="pickupImg"
        title="PICK UP & DELIVERY"
        label="La macchina deve effettuare dei lavori di manutenzione in officina"
        @click="handleCardClickPickup"
        :selectedCar="card==1"
        :isRouter=false
      ></CardComponent>
  
    </el-col>
        <el-col :span="5" :offset="1">
      <CardComponent
        :imgSrc="deliveryImg"
        title="CONSEGNA AUTO"
        label="La macchina deve essere consegnata da un punto diverso del partner "
        @click="handleCardClickDelivery"
        :selectedCar="card==2"
        :isRouter=false
      ></CardComponent>
    </el-col>
    <el-col :span="5" :offset="1">
      <CardComponent
        :imgSrc="escortImg"
        title="ACCOMPAGNA UN CLIENTE"
        label="Il delivery riguarda il cliente, non una macchina "
        @click="handleCardClickEscort"
        :selectedCar="card==3"
        :isRouter=false
      ></CardComponent>
    </el-col>
  </el-row>
  <br />
  <br />
  <br />


<div id="pickupSection">
  <el-space wrap  v-if="card == 1">
    <PickupComponent />
  </el-space>
 </div> 
 <div id="deliverySection">
  <el-space wrap v-if="card == 2">
    <DeliveryComponent />
  </el-space>
</div>
<div id="escortSection">
  <el-space wrap v-if="card == 3">
    <EscortComponent />
  </el-space>
</div>
</template>

<script>
import PickupComponent from "../Components/PickupComponent.vue";
import DeliveryComponent from "../Components/DeliveryComponent.vue";
import EscortComponent from "../Components/EscortComponent.vue";
import customerAPI from "../services/customerAPI.js";
import bookingAPI from "../services/bookingAPI.js";
import partnerAPI from "../services/partnerAPI.js";
import pickupImg from "../assets/pickup2-yellow.png";
import escortImg from "../assets/escort2-yellow.png";
import deliveryImg from "../assets/delivery-yellow.png";
import { isLoggedStore } from "../store/logged";
import { ref,nextTick } from "vue";
import store from "../store/prenotazione.js";
import CardComponent from "@/Components/CardComponent.vue";
export default {
  name: "PrenotazioniInfoPage",
  components: {
    PickupComponent,
    DeliveryComponent,
    EscortComponent,
    CardComponent,
  },
  setup() {
    store.customers = null;
    store.customersCascader = null;
    store.customerSelected = null;
    store.headquarters = null;
    store.headquartersCascader = null;
    store.heaquarterSelected = null;
    store.cars = [];
    store.carsCascader = [];
    store.carSelected = null;
    store.originPointAddress = "";
    store.originPointStreetNumber = "";
    store.originPointCity = "";
    store.originPointCap = "";
    store.originPointProvince = "";
    store.destinationPointAddress = "";
    store.destinationPointStreetNumber = "";
    store.destinationPointCity = "";
    store.destinationPointCap = "";
    store.destinationPointProvince = "";
    store.rangeKmGonePickup = 0;
    store.rangeCostGonePickup = 0;
    store.rangeKmReturnPickup = 0;
    store.rangeCostReturnPickup = 0;
    store.rangeKmGoneEscort = 0;
    store.rangeCostGoneEscort = 0;
    store.rangeKmReturnEscort = 0;
    store.rangeCostReturnEscort = 0;

    let card = ref(0);

    const storeLogin = isLoggedStore();

   const  scrollToSection= (sectionId)=> {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }

    //cliente
    let customersCascader = ref([]);
    const customers = ref([]);

    let partner=ref({});

    const loadCustomers = async () => {
      try {
        const responsePartner = await partnerAPI.getPartnerByEmployee(storeLogin.id);

        partner.value = responsePartner.data[0].headquarters[0].partner;
        const response = await customerAPI.getCustomersByPartner(partner.value.id);

        response.data.forEach((customer) => {
          if (customer.is_alive == true) {
            let tableRow = {
              value: customer.id,
              label: `${customer.name} ${customer.surname}, ${customer.mail}`,
            };
            customers.value.push(customer);
            customersCascader.value.push(tableRow);
          }
        });
      } catch (error) {
        console.log(error);
        console.log("error customer");
      }
    };

    //sede
    let headquartersCascader = ref([]);
    const headquarters = ref([]);

    const loadHeadquarters = async () => {
      try {
        const response = await bookingAPI.getHeadquartersByEmployee(storeLogin.id);


        response.data[0].headquarters.forEach((headquarter) => {
          let tableRow = {
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}`,
          };
          headquartersCascader.value.push(tableRow);
          headquarters.value.push(headquarter);
        });
      } catch {
        console.log("error");
      }
    };

    /* const scrollToBottom = () => {
      const bottomSection = document.getElementById('bottom-section');
      if (bottomSection) {
        bottomSection.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Section with ID 'bottom-section' not found.`);
      }
    }; */

    const handleCardClickPickup = () => {
      card.value = 1;
      nextTick(() => {
        scrollToSection('pickupSection');
      });
    };

    const handleCardClickDelivery = () => {
      card.value = 2;
      nextTick(() => {
        scrollToSection('deliverySection');
      });
    };

    const handleCardClickEscort = () => {
      card.value = 3;
      nextTick(() => {
        scrollToSection('escortSection');
      });
    };

    loadCustomers();
    loadHeadquarters();

    store.customers = customers.value;
    store.customersCascader = customersCascader.value;
    store.headquarters = headquarters.value;
    store.headquartersCascader = headquartersCascader.value;

    return {
      store,
      card,
      handleCardClickPickup,
      handleCardClickDelivery,
      handleCardClickEscort,
      pickupImg,
      escortImg,
      deliveryImg,
      partner,
      scrollToSection
    };
  },
};
</script>

<style>
h1 {
  margin-top: 40px;
}


</style>
