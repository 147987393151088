import API from './API';

export default {
  getEmployee(employeeId) {
    return API.get(`/employee/?filter=id||$eq||${employeeId}`)
  },
  
  getPartnerByHeadquarter(headquarterId) {

      return API.get(`/headquarter?filter=id||$eq||${headquarterId}&`,{
        params: {
             join: [
              'partner',
            ], 
          },
        });
  },

  updEmployee(employee) {
    return API.patch('/employee/' + employee.id, employee)
  },

  updPassword(password_obj) {
    return API.post('/auth/update-password' ,password_obj)
  },
};