<template>
    <el-card shadow="hover" class="info-card">
      <div class="info-content">
        <span class="info-label">{{ label }}:</span>
        <el-tag :type="tagType" class="info-value">{{ value }}</el-tag>
      </div>
    </el-card>
  </template>
  
  <script>
  export default {
    name: 'InfoCardComponent',
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      tagType: {
        type: String,
        default: 'info',
      },
    },
  };
  </script>
  
  <style scoped>
  .info-card {
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
  
  .info-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .info-label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .info-value {
    font-size: 16px;
  }
  </style>
  