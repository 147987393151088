<template>
  
<div :style="style"  v-if="!disabled">
    <el-form-item :label="title" :style="styleForm" :prop="prop">
      <el-input v-model="internalValue" :placeholder="placeholder"  @focus="handleInputFocus" @blur="handleInputBlur"  :class="{ 'highlight': highlight }" @keydown.tab="handleTabKey"/>
    </el-form-item>
  </div>


  <div :style="style" v-if="disabled">
    <el-form-item :label="title" :style="styleForm" :prop="prop">
      <el-input v-model="internalValue" :placeholder="placeholder" disabled  @focus="handleInputFocus" @blur="handleInputBlur"  :class="{ 'highlight': highlight }" @keydown.tab="handleTabKey"/>
    </el-form-item>
  </div>
</template>

<script>

//import { debounce } from 'lodash';
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },    
    prop: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    span: {
      type: Number,
      required: false,
      default: 3,
    },
    style: {
      type: String,
      required: false,
    },
    styleForm: {
      type: String,
      required: false,
    },
    highlight: {
      type: Boolean,
      require: false,
    },

    divWidth:{
      type:String
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      internalValue: this.value,
      fieldsOfInterest: ['address', 'cap', 'province', 'street_number', 'city'],
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    }
  },
  methods: {

    handleInputFocus() {
      this.$emit('focus');
    },
    handleInputBlur(){
      this.$emit('blur');
      this.$emit('input', this.internalValue);
    },
    handleTabKey() {
    this.handleInputBlur();
  }
  
  },
};
</script>

<style scoped>

.highlight {
  border: 2px solid rgb(205, 54, 54); 
}
</style>
