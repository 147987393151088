import { reactive  } from 'vue';

const storePrenotazione = reactive({

  employee:{},

  customers: null,
  customersCascader:null,
  customerSelected:null,
  customerSelectedObj:{},

  headquarters: null,
  headquartersCascader:null,
  heaquarterSelected:null,
  heaquarterSelectedObj:{},

  cars:[],
  carsCascader:[],
  carSelected:null,

  originPointAddress:"",
  originPointStreetNumber:"",
  originPointCity:"",
  originPointCap:"",
  originPointProvince:"",

  destinationPointAddress:"",
  destinationPointStreetNumber:"",
  destinationPointCity:"",
  destinationPointCap:"",
  destinationPointProvince:"",

  rangeKmGonePickup:0,
  rangeCostGonePickup:0,
  rangeKmReturnPickup:0,
  rangeCostReturnPickup:0,
  
  rangeKmGoneEscort:0,
  rangeCostGoneEscort:0,
  rangeKmReturnEscort:0,
  rangeCostReturnEscort:0,

  rangeKmDelivery:0,
  rangeCostDelivery:0,
});

export default storePrenotazione;
