<template>
  <h2>MODIFICA DATA E ORA DELLA PRENOTAZIONE</h2>
  <div style="height: 900px">
    <div v-if="formatDateGone(date_gone, is_gone)">
      <el-row justify="center" style="margin-top: 3%">
        <el-col :span="4">
          <el-form-item
            ><h4 style="font-size: large">
              Modifica data/ora andata:
            </h4></el-form-item
          >
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-input v-model="date_gone_formatted" :disabled="true" class="bold-input"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">

        </el-col>

        <el-col :span="6">
          <el-form-item label="Nuova data">
            <VueDatePicker
              v-model="new_date_gone"
              :enable-time-picker="false"
              @update:model-value="handleChangeDateGone"
              format="dd/MM/yyyy"
              :disabled-dates="disabledDate"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-cascader
              v-model="selectedSlotGone"
              placeholder="Ora"
              :options="timeSlotsGone"
              :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
              :disabled="new_date_gone == ''"
              @change="handleSlotChangeGone"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <div v-if="formatDateReturn(date_return, is_return)">
      <el-row justify="center" style="margin-top: 3%">
        <el-col :span="4">
          <el-form-item
            ><h4 style="font-size: large">
              Modifica data/ora ritorno:
            </h4></el-form-item
          >
        </el-col>
        <el-col :span="4" >
          <el-form-item>
            <el-input v-model="date_return_formatted" :disabled="true" class="bold-input"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="1">

        </el-col>


        <el-col :span="6" >
          <el-form-item label="Nuova data">
            <VueDatePicker
              v-model="new_date_return"
              :enable-time-picker="false"
              @update:model-value="handleChangeDateReturn"
              format="dd/MM/yyyy"
              :disabled-dates="disabledDateReturn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-cascader
              v-model="selectedSlotReturn"
              placeholder="Ora"
              :options="timeSlotsReturn"
              :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
              :disabled="new_date_return == ''"
              @change="handleSlotChangeReturn"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <el-button
      color="rgb(231,228,58)"
      :dark="true"
      :disabled="
        is_return &&
        selectedSlotReturn == null &&
        is_gone &&
        selectedSlotGone == null
      "
      @click="onSubmitDate"
      style="margin-top: 50px"
      >Modifica la prenotazione
    </el-button>
  </div>
</template>

<script>
import {
  ref,
  /* reactive */
  toRaw,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';

import bookingAPI from '@/services/bookingAPI';
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
const dayjs = require('dayjs');

export default {
  components: {
    VueDatePicker,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let booking = ref({});

    let date_gone_formatted = ref('');
    let date_return_formatted = ref('');


    let date_gone = ref('');
    let new_date_gone = ref('');

    let date_return = ref('');
    let new_date_return = ref('');

    let is_gone = ref(false);
    let is_return = ref(false);

    let area = ref('');

    //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando
    const type = ref(0);

    let id_prenotazione_gone = '';
    let id_prenotazione_return = '';

    let driver_gone = null;
    let driver_return = null;

    let origin_point_gone = {};
    let destination_point_gone = {};
    let origin_point_return = {};
    let destination_point_return = {};

    let type_prenotazione_gone = '';
    let type_prenotazione_return = '';

    let planners = [{}];

    const loadBooking = async () => {
      try {
        const response = await bookingAPI.getBookingById(route.params.id);
        booking.value = response.data;
        area.value = response.data.headquarter.area;

        if (
          booking.value.pickup_bookings &&
          booking.value.pickup_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.pickup_bookings.length; i++) {
            type.value = 1;
            let prenotazione = booking.value.pickup_bookings[i];

            planners.push(prenotazione.planners);

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_partner,
                true,
                prenotazione.id,
                prenotazione.driver_pickup,
                booking.value.headquarter,
                prenotazione.point,
                !booking.value.loaner ? 'PICKUP_GONE' : 'PICKUP_AUTO_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_partner,
                false,
                prenotazione.id,
                prenotazione.driver_pickup,
                booking.value.headquarter,
                prenotazione.point,
                !booking.value.loaner ? 'PICKUP_RETURN' : 'PIKCUP_AUTO_RETURN'
              );
            }
          }
        }
        if (
          booking.value.escort_bookings &&
          booking.value.escort_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.escort_bookings.length; i++) {
            let prenotazione = booking.value.escort_bookings[i];
            planners.push(prenotazione.planners);

            prenotazione.tasks == false ? (type.value = 2) : (type.value = 4);

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_partner,
                true,
                prenotazione.id,
                prenotazione.driver_escort,
                prenotazione.point_origin_escort,
                prenotazione.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_GONE' : 'ESCORT_AUTO_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_partner,
                false,
                prenotazione.id,
                prenotazione.driver_escort,
                prenotazione.point_origin_escort,
                prenotazione.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_RETURN' : 'ESCORT_AUTO_RETURN'
              );
            }
          }
        }
        if (
          booking.value.delivery_bookings &&
          booking.value.delivery_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.delivery_bookings.length; i++) {
            type.value = 3;
            let prenotazione = booking.value.delivery_bookings[i];
            planners.push(prenotazione.planners);

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_point,
                true,
                prenotazione.id,
                prenotazione.driver_delivery,
                prenotazione.point_origin_delivery,
                prenotazione.point_destination_delivery,
                'DELIVERY_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_point,
                false,
                prenotazione.id,
                prenotazione.driver_delivery,
                prenotazione.point_origin_delivery,
                prenotazione.point_destination_delivery,
                'DELIVERY_GONE'
              );
            }
          }
        }

        return booking;
      } catch (e) {
        console.log(e);
      }
    };

    loadBooking();

    const formatData = (data) => {
      console.log(dayjs(data).utc().format('DD/MM/YYYY HH:mm'));
      return dayjs(data).utc().format('DD/MM/YYYY HH:mm');
    };

    const extractBookingDetail = (
      date,
      isGone,
      id,
      driver,
      originPoint,
      destinationPoint,
      type
    ) => {
      if (isGone) {
        date_gone.value = date;
        date_gone_formatted.value=formatData(date);
        is_gone.value = true;
        id_prenotazione_gone = id;
        driver_gone = driver;
        origin_point_gone = originPoint;
        destination_point_gone = destinationPoint;
        type_prenotazione_gone = type;
      } else {
        date_return.value = date;
        
        date_return_formatted.value=formatData(date);
        is_return.value = true;
        id_prenotazione_return = id;
        driver_return = driver;
        origin_point_return = originPoint;
        destination_point_return = destinationPoint;
        type_prenotazione_return = type;
      }
    };

    const formatDateGone = (date, isGone) => {
      if (isGone) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate);
        let expiryPlus2Hours = expiry.add(2, 'hour'); 
        expiryPlus2Hours= dayjs(expiryPlus2Hours).format()

        let bookingDate = dayjs(date).utc().format();
        return  bookingDate>expiryPlus2Hours;
      }
    };

    const formatDateReturn = (date, isReturn) => {
      if (isReturn) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate)
        let expiryPlus2Hours = expiry.add(2, 'hour'); 
        expiryPlus2Hours= dayjs(expiryPlus2Hours).format()
        let bookingDate = dayjs(date).utc().format();
        return  bookingDate>expiryPlus2Hours;
        //return bookingDate.isAfter(expiryPlus2Hours);
      }
    };

    //ottengo slot disponibili

    const selectedSlotGone = ref(null);
    const selectedSlotReturn = ref(null);
    const timeSlotsGone = ref([
     
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);
    const timeSlotsReturn = ref([
     
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDateGone = async () => {
      try {
        const selectedDate = dayjs(new_date_gone.value).format('YYYY-MM-DD');
        await loadSlotGone(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeDateReturn = async () => {
      try {
        const selectedDate = dayjs(new_date_return.value).format('YYYY-MM-DD');
        await loadSlotReturn(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    let loadingSlotsGone = ref(true);
    let request_slot_gone = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotGone = async (date) => {
      loadingSlotsGone.value = true;
      try {
        request_slot_gone.originPoint = toRaw(origin_point_gone);
        request_slot_gone.destinationPoint = toRaw(destination_point_gone);
        request_slot_gone.partnerDay = date;
        request_slot_gone.type = type_prenotazione_gone;
        request_slot_gone.area = area.value;

        let response = null;
        response = await prenotazioneAPI.calcSlot(request_slot_gone);

        if (response) {
          loadingSlotsGone.value = false;
        }
        return response.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    let loadingSlotsReturn = ref(true);
    let request_slot_return = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotReturn = async (date) => {
      loadingSlotsReturn.value = true;
      try {
        request_slot_return.originPoint = origin_point_return;
        request_slot_return.destinationPoint = destination_point_return;
        request_slot_return.type = type_prenotazione_return;
        request_slot_return.partnerDay = date;
        request_slot_return.area = area.value;

        let response_slot = await prenotazioneAPI.calcSlot(request_slot_return);

        loadingSlotsReturn.value = false;
        return response_slot.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const mergeSlotsGone = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotGone(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const mergeSlotsReturn = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotReturn(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili

        const mergedSlots = timeSlots.map((timeSlot) => {
          let isAvailable = availableSlots.includes(timeSlot.value);

          const [hour, minute] = timeSlot.value.split(':').map(Number);

          const newDate = dayjs(selectedDate)
            .hour(hour)
            .minute(minute)
            .second(0)
            .toDate();

          isAvailable =
            isAvailable && is_gone.value
              ? new Date(newDate).getTime() >
                new Date(date_gone.value).getTime()
              : isAvailable;

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const disabledDate = (vDate) => {
      let expirydate = new Date();
      return vDate < expirydate.setDate(expirydate.getDate());
    };

    const disabledDateReturn = (vDate) => {
      let expirydate = new Date();
      if (is_gone.value && date_gone.value) {
        const partnerDate = new Date(date_gone.value);
        partnerDate.setHours(0, 0, 0, 0);

        return vDate < partnerDate;
      }

      return vDate < expirydate.setDate(expirydate.getDate());
    };

    const loadSlotGone = async (selectedDate) => {
      const mergedSlotsGone = await mergeSlotsGone(
        timeSlotsGone.value,
        selectedDate
      );
      timeSlotsGone.value.splice(
        0,
        timeSlotsGone.value.length,
        ...mergedSlotsGone
      );

      //console.log("timeSlotsGone : ", timeSlotsGone.value)
    };

    const loadSlotReturn = async (selectedDate) => {
      const mergedSlotsReturn = await mergeSlotsReturn(
        timeSlotsReturn.value,
        selectedDate
      );
      timeSlotsReturn.value.splice(
        0,
        timeSlotsReturn.value.length,
        ...mergedSlotsReturn
      );
    };

    const handleSlotChangeGone = (value) => {
      new_date_gone.value = dayjs(new Date(new_date_gone.value))
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };

    const handleSlotChangeReturn = (value) => {
      new_date_return.value = dayjs(new Date(new_date_return.value))
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };
    const modifyPrenotazione = async (
      new_date,
      id_prenotazione,
      type_prenotazione,
      origin_point,
      destination_point
    ) => {
      //console.log("🚀 ~ modifyPrenotazione ~ new_date, id_prenotazione, type_prenotazione, origin_point, destination_point:", new_date, id_prenotazione, type_prenotazione, origin_point, destination_point)
      let response;
      try {
        switch (type.value) {
          case 1:
            response = await bookingAPI.updateDatePickup(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'PICKUP_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 2:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 3:
            response = await bookingAPI.updateDateDelivery(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'DELIVERY_' + type_prenotazione,
              area: area.value,
            });
            return response;

          case 4:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const modifyBooking = async (
      new_date_gone,
      new_date_return,
      id_booking,
      type
    ) => {
      let response;

      let data = {
        customer: booking.value.customer,
        booking: booking.value,
        type: type, //"gone", "return", "both"
        goneDriver: driver_gone,
        returnDriver: driver_return,
        newDateGone: new_date_gone,
        newDateReturn: new_date_return,
      };
      
      try {
        let new_date;

        if (is_gone.value && new_date_gone!=null) new_date = new_date_gone;
        else if (is_gone.value && new_date_gone==null) new_date = date_gone.value;
        else if (!is_gone.value && new_date_return!=null) new_date = new_date_return;

        response = await bookingAPI.updateDateBooking(id_booking, {
          data: new_date,
        });

        await bookingAPI.messageModifyHourBooking(data);

        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const onSubmitDate = async () => {
      if (new_date_gone.value && new_date_return.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking.value.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking.value.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          new_date_gone.value,
          new_date_return.value,
          route.params.id,
          'both'
        ).then(async (response) => {
          if (response.status === 200) {
            router.push('/');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (new_date_return.value && !new_date_gone.value) {
        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking.value.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          null,
          new_date_return.value,
          route.params.id,
          'return'
        ).then(async (response) => {
          if (response.status === 200) {
            router.push('/');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (!new_date_return.value && new_date_gone.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking.value.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });

          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(new_date_gone.value, null, route.params.id, 'gone').then(
          async (response) => {
            if (response.status === 200) {
              router.push('/');
            } else {
              ElMessage({
                message: 'Errore modifica booking!',
                type: 'error',
              });
              console.log('errore inatteso');
            }
          }
        );
      }
    };

    return {
      is_gone,
      is_return,
      onSubmitDate,
      date_gone_formatted,
date_return_formatted,

      //aggiungiTask,
      //rimuoviTask,
      booking,
      Delete,

      new_date_gone,
      new_date_return,

      selectedSlotGone,
      selectedSlotReturn,
      timeSlotsGone,
      timeSlotsReturn,
      handleSlotChangeGone,
      handleSlotChangeReturn,
      handleChangeDateGone,
      handleChangeDateReturn,

      date_gone,
      date_return,

      formatDateGone,
      formatDateReturn,
      disabledDate,
      disabledDateReturn,
    };
  },
};
</script>

<style>
.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.vue-date-picker .vdp-datepicker__calendar {
  z-index: 9999;
  /* Adjust this value as needed */
}
</style>
