import API from './API';

export default {

/*   getPartnerByEmployee(employeeId) {
    return API.get(`/partner?filter=headquarters.employees.id||$eq||${employeeId}&`,{
        params: {
            join: [
              'headquarters',
              'headquarters.employees'
            ],
          },
        });
  },  */

   getPartnerByEmployee(employeeId) {
    return API.get(`/employee?filter=id||$eq||${employeeId}&`,{
        params: {
             join: [
              'headquarters',
              'headquarters.partner'
            ], 
          },
        });
  }, 

  
  updateTariffe(id, partner){
    return API.patch('/partner/'+id, partner)
  },

};