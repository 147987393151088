<template>


    <div class="point-container">
    <PointComponent
      :value="singleCar.intestatario"
      title="Intestatario"
      label="Intestatario"
      prop="intestatario"
      @input="(newValue) => (singleCar.intestatario = newValue)"
    />

    <PointComponent
      :value="singleCar.marca"
      title="Marca"
      label="Marca"
      prop="marca"
      @input="(newValue) => (singleCar.marca = newValue)"
    />

    <PointComponent
      :value="singleCar.modello"
      title="Modello"
      label="Modello"
      prop="modello"
      @input="(newValue) => (singleCar.modello = newValue)"
    />

    <PointComponent
      :value="singleCar.targa"
      title="Targa"
      label="Targa"
      prop="targa"
      @input="(newValue) => (singleCar.targa = newValue)"
    />

    
    
      <PointComponent
      v-if="inputTransmission"
        :value="singleCar.transmission"
        title="Tipo di cambio"
        label="Tipo di cambio"
        prop="transmission"
        @input="(newValue) => (singleCar.transmission = newValue)"
      />
      

      
      <SelectComponent
      v-if="!inputTransmission"
        @selected="(newValue)=>(singleCar.transmission=newValue)"
        v-model="singleCar.transmission"
        :options="options"
        :value="singleCar.transmission"
        title="Selezionare tipo di cambio"
        style="width:20%"
        :addButtom="false"
        :isDisabled="false"
        :span="6"
      ></SelectComponent>

      <el-form-item v-if="modifyButom">
        <ButtonComponent label="Modifica" :isRouter="true"  @buttonClick="handleButtonClick(single_car)"></ButtonComponent> 
      </el-form-item>



        <ButtonComponent v-if="deleteCar"
        :ifCondition="ifCondition"
        :isRouter="false"
        @buttonClick="handleButtonClickDelete($event, counterCar)"
        type="danger"
        icon="Delete"
        style="margin-right:45%"
        ></ButtonComponent>
      </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import PointComponent from './PointComponent.vue';
import SelectComponent from './SelectComponent.vue';

export default {
  components: { ButtonComponent, PointComponent, SelectComponent },
  props: {
    title: {
      type: String,
      required: true,
    },
    car: {
      required: true,
    },
    options: {
      required: true,
    },

    inputTransmission: {
      type: Boolean,
      default: false,
    },

    modifyButom: {
      type: Boolean,
      default: false,
    },

    ifCondition:{
      required: false,
    },
    buttonClickDelete:{
      required: false,
    },
    counterCar:{
      required:false
    },
    deleteCar:{
      required:false,
      default:true,
      type:Boolean
    },
    isDisabled:{
      deafult:false
    }
  },
  data() {
    return {
      singleCar: this.car,
    };
  },
  
  methods: {
    handleButtonClick() {
        this.$emit('buttonClick');
    },
    handleButtonClickDelete() {
      this.$emit('buttonClick', this.singleCar, this.counterCar);
    },
  }
};
</script>

<style>

.point-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .point-container > div {
    flex: 1;
    min-width: 14%; 
  }
</style>
