<template>
  <h1>PROFILO</h1>

  <el-form :model="user_partner">
    <el-row :gutter="30">
      <el-col :sm="6" :xs="24">
        <el-form-item label="Nome">
          <el-input v-model="user_partner.name" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Cognome">
          <el-input v-model="user_partner.surname" />
        </el-form-item>
      </el-col>

      <el-col :sm="5">
        <el-form-item label="Mail">
          <el-input v-model="user_partner.mail" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row justify="center" class="salva">
      <el-col :span="3">
        <el-form-item>
          <router-link
            v-if="role === 'employee'"
            :to="{ path: `/` }"
            class="routerLink"
          >
            <el-button color="rgb(231,228,58)" :dark="true" @click="update">
              Salva
            </el-button>
          </router-link>
          <router-link
            v-if="role === 'adminEmployee'"
            :to="{ path: `/tariffe` }"
            class="routerLink"
          >
            <el-button color="rgb(231,228,58)" :dark="true" @click="update">
              Salva
            </el-button>
          </router-link>
          <router-link
            v-if="role === 'salesvendor'"
            :to="{ path: `/customer` }"
            class="routerLink"
          >
            <el-button color="rgb(231,228,58)" :dark="true" @click="update">
              Salva
            </el-button>
          </router-link>

          <router-link :to="{ path: `/` }" class="routerLink">
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>

  <el-form :model="upd_password">
    <el-row :gutter="30">
      <el-col :sm="5">
        <el-form-item label="Vecchia Password">
          <el-input v-model="upd_password.password" />
        </el-form-item>
      </el-col>
      <el-col :sm="5">
        <el-form-item label="Nuova password">
          <el-input v-model="upd_password.newpassword" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item>
          <router-link
            v-if="role === 'employee'"
            :to="{ path: `/` }"
            class="routerLink"
          >
            <el-button
              color="rgb(231,228,58)"
              :dark="true"
              @click="update_password"
            >
              Conferma password
            </el-button>
          </router-link>

          <router-link
            v-if="role === 'adminEmployee'"
            :to="{ path: `/tariffe` }"
            class="routerLink"
          >
            <el-button color="rgb(231,228,58)" :dark="true" @click="update_password">
              Conferma password
            </el-button>
          </router-link>

          <router-link
            v-if="role === 'salesvendor'"
            :to="{ path: `/customer` }"
            class="routerLink"
          >
            <el-button
              color="rgb(231,228,58)"
              :dark="true"
              @click="update_password"
            >
              Conferma password
            </el-button>
          </router-link>
          <router-link
            v-if="role === 'employee'"
            :to="{ path: `/` }"
            class="routerLink"
          >
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>

          <router-link
            v-else-if="role === 'salesvendor'"
            :to="{ path: `/customer` }"
            class="routerLink"
          >
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>

          <router-link
            v-if="role === 'adminEmployee'"
            :to="{ path: `/tariffe` }"
            class="routerLink"
          >
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { toRaw } from 'vue';
import { reactive } from 'vue';
import { ElMessage } from 'element-plus';
import employeeAPI from '../services/employeeAPI.js';
import { Delete } from '@element-plus/icons-vue';
import { isLoggedStore } from '../store/logged';

export default {
  setup() {
    const store = isLoggedStore();
    const id = toRaw(store);
    const role = toRaw(store).role.value;

    const upd_password = reactive({
      mail: '',
      password: '',
      newpassword: '',
    });

    const user_partner = reactive({
      id: '',
      name: '',
      surname: '',
      date_birth: '',
      mail: '',
    });

    const loadDetails = async () => {
      try {
        const response = await employeeAPI.getEmployee(id.id.value);

        user_partner.id = response.data[0].id;
        user_partner.name = response.data[0].name;
        user_partner.surname = response.data[0].surname;
        user_partner.mail = response.data[0].mail;
      } catch (e) {
        console.log(e);
      }
    };

    const update = () => {
      updCustomer(toRaw(user_partner)).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Utente aggiornato con successo!',
            type: 'success',
          });
        } else {
          ElMessage.error('Error!');
        }
      });
    };

    const updCustomer = async (user) => {
      let response = {};
      try {
        response = await employeeAPI.updEmployee(user);
        return response;
      } catch {
        ElMessage.error('Error!');
        console.log('error');
      }
    };

    const update_password = () => {
      upd_password.mail = user_partner.mail;
      updPassword(toRaw(upd_password)).then((response) => {
        if (response.status === 201) {
          ElMessage({
            message: 'Password aggiornata con successo!',
            type: 'success',
          });
        } else {
          ElMessage.error('Error!');
        }
      });
    };

    const updPassword = async (pw) => {
      let response = {};
      try {
        response = await employeeAPI.updPassword(pw);
        return response;
      } catch {
        ElMessage.error('Error!');
        console.log('error');
      }
    };

    loadDetails();

    return {
      role,
      updPassword,
      upd_password,
      update_password,
      update,
      user_partner,
      Delete,
    };
  },
};
</script>

<style>
.el-row {
  margin-bottom: -10px;
}
.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}
</style>
