<template>
  <h2>AGGIUNGI LAVORI ALLA PRENOTAZIONE</h2>
  <el-form :model="task">
    <el-row justify="center" style="margin-top: 3%">
      <el-row :gutter="63">
        <AggiungiLavori
          @description="handleChangeDescription"
          @cost="handleChangeCost"
          @pay="handleChangePay"
          :highlightDescription="highlightDescription"
          :highlightCost="highlightCost"
        ></AggiungiLavori>
      </el-row>
    </el-row>

    <UploadFile
              label="Carica preventivo"
              @note="handleChangeNote"
              @uploadChange="onFileSelected"
            ></UploadFile>
  </el-form>
  <div
    v-if="
      formatDateGone(date_gone, is_gone) ||
      formatDateReturn(date_return, is_return)
    "
  >
    Vuoi modificare anche la data della prenotazione?
    <el-radio-group v-model="modifyData">
      <el-radio label="true">Sì</el-radio>
      <el-radio label="false">No</el-radio>
    </el-radio-group>
  </div>

  <div v-if="modifyData == 'true'" style="margin-top: 2%">
    <div v-if="formatDateGone(date_gone, is_gone)">
      <el-row :gutter="21" class="row-2">
        <el-col :span="5" style="margin: 2rem -1.3rem">
          <h4>Modifica data/ora andata</h4>
        </el-col>
        <el-col :span="4" style="margin: 1.8rem -2rem">
          <el-form-item>
            <el-input v-model="date_gone_formatted" :disabled="true" class="bold-input"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" style="margin: 1.8rem 1.5rem">
          <el-form-item label="Nuova data prenotazione">
            <VueDatePicker
              v-model="new_date_gone"
              :enable-time-picker="false"
              @update:model-value="handleChangeDateGone"
              format="dd/MM/yyyy"
              :disabled-dates="disabledDate"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2" style="margin: 1.8rem -0.5rem">
          <el-form-item>
            <el-cascader
              v-model="selectedSlotGone"
              placeholder="Ora"
              :options="timeSlotsGone"
              :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
              :disabled="new_date_gone == ''"
              @change="handleSlotChangeGone"
            />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="3" style="margin: 1.8rem -0.5rem">
            <el-button color="rgb(231,228,58)" :dark="true" @click="onSubmitDateGone">Modifica </el-button>
        </el-col> -->
      </el-row>
    </div>

    <div v-if="formatDateReturn(date_return, is_return)">
      <el-row :gutter="21" class="row-2" style="margin-top: -2%">
        <el-col :span="5" style="margin: 2rem -1.3rem">
          <h4>Modifica data/ora ritorno</h4>
        </el-col>
        <el-col :span="4" style="margin: 1.8rem -2rem">
          <el-form-item>
            <el-input v-model="date_return_formatted" :disabled="true" class="bold-input"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" style="margin: 1.8rem 1.5rem">
          <el-form-item label="Nuova data prenotazione">
            <VueDatePicker
              v-model="new_date_return"
              :enable-time-picker="false"
              @update:model-value="handleChangeDateReturn"
              format="dd/MM/yyyy"
              :disabled-dates="disabledDateReturn"
            />
          </el-form-item>
        </el-col>
        <el-col :span="2" style="margin: 1.8rem -0.5rem">
          <el-form-item>
            <el-cascader
              v-model="selectedSlotReturn"
              placeholder="Ora"
              :options="timeSlotsReturn"
              :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
              :disabled="new_date_return == ''"
              @change="handleSlotChangeReturn"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </div>

  <div justify="center" style="margin-top: 3%">
    <!-- <router-link class="routerLink" :to="{ path: `/`}"> -->
    <el-button color="rgb(231,228,58)" :dark="true" @click="onSubmit"
      >Modifica la prenotazione
    </el-button>
    <!-- </router-link> -->
    <!--(task.description=='' && modifyData=='false') ||   && (modifyData=='true' && is_return && date_return=='' && selectedSlotReturn==null) && (modifyData=='true' && is_gone && date_gone=='' && selectedSlotGone==null )" @click="onSubmit">Modifica la prenotazione </el-button> -->
  </div>
</template>

<script>
import {
  ref,
  /* reactive */
  toRaw,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';

import bookingAPI from '@/services/bookingAPI';
import prenotazioneAPI from '@/services/prenotazioneAPI';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AggiungiLavori from '@/Components/AggiungiLavori.vue';
import UploadFile from '@/Components/UploadFile.vue';

const dayjs = require('dayjs');

export default {
  components: {
    VueDatePicker,
    AggiungiLavori,
    UploadFile,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let booking = ref({});

    let date_gone = ref('');
    let date_gone_formatted = ref('');
    let new_date_gone = ref('');

    let date_return = ref('');
    let date_return_formatted = ref('');
    let new_date_return = ref('');

    let is_gone = ref(false);
    let is_return = ref(false);

    let modifyData = ref('false');

    //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando
    const type = ref(0);

    const task = ref({
      description: '',
      cost: 0,
      pay: false,
      accepted: false,
      booking: {},
    });

    let id_prenotazione_gone = '';
    let id_prenotazione_return = '';

    let driver_gone = null;
    let driver_return = null;

    let origin_point_gone = {};
    let destination_point_gone = {};
    let origin_point_return = {};
    let destination_point_return = {};

    let type_prenotazione_gone = '';
    let type_prenotazione_return = '';

    const formatDateGone = (date, isGone) => {
      if (isGone) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate);
        let expiryPlus2Hours = expiry.add(2, 'hour'); 
        expiryPlus2Hours= dayjs(expiryPlus2Hours).format()

        let bookingDate = dayjs(date).utc().format();
        return  bookingDate>expiryPlus2Hours;
      }
    };

    const formatDateReturn = (date, isReturn) => {
      if (isReturn) {
        let expirydate = new Date();
        const expiry = dayjs(expirydate)
        let expiryPlus2Hours = expiry.add(2, 'hour'); 
        expiryPlus2Hours= dayjs(expiryPlus2Hours).format()
        let bookingDate = dayjs(date).utc().format();
        return  bookingDate>expiryPlus2Hours;
        //return bookingDate.isAfter(expiryPlus2Hours);
      }
    };
    let area = ref('');

    const loadBooking = async () => {
      try {
        const response = await bookingAPI.getBookingById(route.params.id);
        booking.value = response.data;
        area.value = response.data.headquarter.area;

        if (
          booking.value.pickup_bookings &&
          booking.value.pickup_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.pickup_bookings.length; i++) {
            type.value = 1;
            let prenotazione = booking.value.pickup_bookings[i];

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_partner,
                true,
                prenotazione.id,
                prenotazione.driver_pickup,
                booking.value.headquarter,
                prenotazione.point,
                !booking.value.loaner ? 'PICKUP_GONE' : 'PICKUP_AUTO_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_partner,
                false,
                prenotazione.id,
                prenotazione.driver_pickup,
                booking.value.headquarter,
                prenotazione.point,
                !booking.value.loaner ? 'PICKUP_RETURN' : 'PIKCUP_AUTO_RETURN'
              );
            }
          }
        }
        if (
          booking.value.escort_bookings &&
          booking.value.escort_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.escort_bookings.length; i++) {
            let prenotazione = booking.value.escort_bookings[i];

            prenotazione.tasks == false ? (type.value = 2) : (type.value = 4);

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_partner,
                true,
                prenotazione.id,
                prenotazione.driver_escort,
                prenotazione.point_origin_escort,
                prenotazione.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_GONE' : 'ESCORT_AUTO_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_partner,
                false,
                prenotazione.id,
                prenotazione.driver_escort,
                prenotazione.point_origin_escort,
                prenotazione.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_RETURN' : 'ESCORT_AUTO_RETURN'
              );
            }
          }
        }
        if (
          booking.value.delivery_bookings &&
          booking.value.delivery_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.delivery_bookings.length; i++) {
            type.value = 3;
            let prenotazione = booking.value.delivery_bookings[i];

            if (prenotazione.type_booking === 'gone') {
              extractBookingDetail(
                prenotazione.date_point,
                true,
                prenotazione.id,
                prenotazione.driver_delivery,
                prenotazione.point_origin_delivery,
                prenotazione.point_destination_delivery,
                'DELIVERY_GONE'
              );
            } else {
              extractBookingDetail(
                prenotazione.date_point,
                false,
                prenotazione.id,
                prenotazione.driver_delivery,
                prenotazione.point_origin_delivery,
                prenotazione.point_destination_delivery,
                'DELIVERY_GONE'
              );
            }
          }
        }

        return booking;
      } catch (e) {
        console.log(e);
      }
    };

    loadBooking();

    const extractBookingDetail = (
      date,
      isGone,
      id,
      driver,
      originPoint,
      destinationPoint,
      type
    ) => {
      if (isGone) {
        date_gone.value = date;
        date_gone_formatted.value = formatData(date);

        is_gone.value = true;
        id_prenotazione_gone = id;
        driver_gone = driver;
        origin_point_gone = originPoint;
        destination_point_gone = destinationPoint;
        type_prenotazione_gone = type;
      } else {
        date_return.value = date;
        date_return_formatted.value = formatData(date);
        is_return.value = true;
        id_prenotazione_return = id;
        driver_return = driver;
        origin_point_return = originPoint;
        destination_point_return = destinationPoint;
        type_prenotazione_return = type;
      }
    };

    //ottengo slot disponibili

    const selectedSlotGone = ref(null);
    const selectedSlotReturn = ref(null);
    const timeSlotsGone = ref([
      {
        value: '08:00',
        label: '08:00',
      },
      {
        value: '08:15',
        label: '08:15',
      },
      {
        value: '08:30',
        label: '08:30',
      },
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);
    const timeSlotsReturn = ref([
      {
        value: '08:00',
        label: '08:00',
      },
      {
        value: '08:15',
        label: '08:15',
      },
      {
        value: '08:30',
        label: '08:30',
      },
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDateGone = async () => {
      try {
        const selectedDate = dayjs(new_date_gone.value).format('YYYY-MM-DD');
        await loadSlotGone(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeDateReturn = async () => {
      try {
        const selectedDate = dayjs(new_date_return.value).format('YYYY-MM-DD');
        await loadSlotReturn(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    const disabledDate = (vDate) => {
      let expirydate = new Date();
      return vDate < expirydate.setDate(expirydate.getDate());
    };

    const disabledDateReturn = (vDate) => {
      let expirydate = new Date();
      if (is_gone.value && date_gone.value) {
        const partnerDate = new Date(date_gone.value);
        partnerDate.setHours(0, 0, 0, 0);

        return vDate < partnerDate;
      }
      return vDate < expirydate.setDate(expirydate.getDate());
    };

    let loadingSlotsGone = ref(true);
    let request_slot_gone = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotGone = async (date) => {
      loadingSlotsGone.value = true;
      try {
        request_slot_gone.originPoint = origin_point_gone;
        request_slot_gone.destinationPoint = destination_point_gone;
        request_slot_gone.partnerDay = date;
        request_slot_gone.type = type_prenotazione_gone;
        request_slot_gone.area = area.value;

        let response = null;
        response = await prenotazioneAPI.calcSlot(request_slot_gone);

        if (response) {
          loadingSlotsGone.value = false;
        }
        return response.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    let loadingSlotsReturn = ref(true);
    let request_slot_return = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotReturn = async (date) => {
      loadingSlotsReturn.value = true;
      try {
        request_slot_return.originPoint = origin_point_return;
        request_slot_return.destinationPoint = destination_point_return;
        request_slot_return.type = type_prenotazione_return;
        request_slot_return.partnerDay = date;
        request_slot_return.area = area.value;

        let response_slot = await prenotazioneAPI.calcSlot(request_slot_return);
        loadingSlotsReturn.value = false;
        return response_slot.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const mergeSlotsGone = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotGone(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const mergeSlotsReturn = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotReturn(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          let isAvailable = availableSlots.includes(timeSlot.value);
          isAvailable =
            isAvailable && is_gone.value
              ? dayjs(new Date(timeSlot.value)).format('HH:mm') <
                dayjs(date_gone.value).format('HH:mm')
              : true;

          return {
            ...timeSlot,
            disabled: isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const loadSlotGone = async (selectedDate) => {
      const mergedSlotsGone = await mergeSlotsGone(
        timeSlotsGone.value,
        selectedDate
      );
      timeSlotsGone.value.splice(
        0,
        timeSlotsGone.value.length,
        ...mergedSlotsGone
      );

      //console.log("timeSlotsGone : ", timeSlotsGone.value)
    };

    const loadSlotReturn = async (selectedDate) => {
      const mergedSlotsReturn = await mergeSlotsReturn(
        timeSlotsReturn.value,
        selectedDate
      );
      timeSlotsReturn.value.splice(
        0,
        timeSlotsReturn.value.length,
        ...mergedSlotsReturn
      );
    };

    const handleSlotChangeGone = (value) => {
      new_date_gone.value = dayjs(new_date_gone.value)
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };

    const handleSlotChangeReturn = (value) => {
      new_date_return.value = dayjs(new Date(new_date_return.value))
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };

    const handleChangeDescription = (value) => {
      task.value.description = value;
    };

    const handleChangeCost = (value) => {
      task.value.cost = value;
    };

    const handleChangePay = (value) => {
      task.value.pay = value;
    };

    const handleChangeNote = (value) => {
      booking.value.note = value;
    };
    const modifyPrenotazione = async (
      new_date,
      id_prenotazione,
      type_prenotazione,
      origin_point,
      destination_point
    ) => {
      //console.log("🚀 ~ modifyPrenotazione ~ new_date, id_prenotazione, type_prenotazione, origin_point, destination_point:", new_date, id_prenotazione, type_prenotazione, origin_point, destination_point)
      let response;
      try {
        switch (type.value) {
          case 1:
            response = await bookingAPI.updateDatePickup(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'PICKUP_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 2:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 3:
            response = await bookingAPI.updateDateDelivery(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'DELIVERY_' + type_prenotazione,
              area: area.value,
            });
            return response;

          case 4:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const modifyBooking = async (
      new_date_gone,
      new_date_return,
      id_booking,
      type
    ) => {
      let response;

      let data = {
        customer: booking.value.customer,
        booking: booking.value,
        type: type, //"gone", "return", "both"
        goneDriver: driver_gone,
        returnDriver: driver_return,
        newDateGone: new_date_gone,
        newDateReturn: new_date_return,
      };
      try {
        let new_date;

         console.log(new_date_gone, " new_date_gone.value")
         console.log(date_gone, " date_gone.value")
         console.log(new_date_return, " new_date_return.value")

         if (is_gone.value && !new_date_gone)
         {console.log("1")
          console.log(is_gone.value , new_date_gone)
           new_date = new_date_gone;}
        else if (is_gone.value && new_date_gone)
         {console.log("2")
           new_date = date_gone.value;}
        else if (!is_gone.value && !new_date_return)
         {console.log("3")
          new_date = new_date_return;} 

        response = await bookingAPI.updateDateBooking(id_booking, {
          data: new_date,
        });

        await bookingAPI.messageModifyHourBooking(data);

        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const onSubmitDate = async () => {
      if (new_date_gone.value && new_date_return.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking.value.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking.value.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          new_date_gone.value,
          new_date_return.value,
          route.params.id,
          'both'
        ).then(async (response) => {
          if (response.status === 200) {
            console.log('data modificata!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (new_date_return.value && !new_date_gone.value) {
        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking.value.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          null,
          new_date_return.value,
          route.params.id,
          'return'
        ).then(async (response) => {
          if (response.status === 200) {
            console.log('data modificata!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (!new_date_return.value && new_date_gone.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking.value.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });

            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(new_date_gone.value, null, route.params.id, 'gone').then(
          async (response) => {
            if (response.status === 200) {
              console.log('data modificata!');
            } else {
              ElMessage({
                message: 'Errore modifica booking!',
                type: 'error',
              });
              console.log('errore inatteso');
            }
          }
        );
      }
    };

    //upload file
    let selectedFile1 = null;
    let fd = new FormData();
    const onFileSelected = (e) => {
      for (let file of e.target.files) {
        selectedFile1 = file;
        fd.append('files', selectedFile1, selectedFile1.name);
      }
      return fd;
    };

    const fileLoad = async (fd) => {
      try {
        const mail = booking.value.customer.mail;
        
        await prenotazioneAPI.uploadModify(fd, mail).then((resp) => {
          if (resp.status == 201) {
            console.log('riuscito');
          }
        });
      } catch (e) {
        console.log(e);
      }
    };
    /* const rimuoviTask = (e, counter) => {
            tasks.value.splice(counter, 1);
            e.preventDefault();
        }

        const aggiungiTask = (e) => {
            tasks.value.push({
                description: "",
                cost: 0,
                pay: false,
                accepted: false
            });
            e.preventDefault();
        } */

    const addTasks = async (task) => {
      let response = {};

      try {
        /* if(task.value.pay==false){
          task.value.accepted=true;
        } */
        response = await bookingAPI.addTask(task.value);
        let data = {
          customer: booking.value.customer,
          booking: booking.value,
          goneDriver: driver_gone,
          returnDriver: driver_return,
          task: response.data,
          isDataModify: modifyData.value == 'true' ? true : false,
        };
        const respmessage = await bookingAPI.messageModifyTaskBooking(data);
        console.log(respmessage);
        return response;
      } catch (error) {
        console.log(error);
      }
    };

    const onSubmitAddTask = async () => {
      task.value.booking = booking.value;
      task.value.pay==true? task.value.accepted=false : task.value.accepted=true;

      addTasks(toRaw(task)).then(async (response) => {
        if (response.status === 201) {
            if (selectedFile1 !== null) {
                         await fileLoad(fd);
                     } else {
                         await prenotazioneAPI.mailCustomerModify(booking.value.customer.mail);
                     } 

          ElMessage({
            message: 'Lavori aggiunti!',
            type: 'success',
          });
          router.push('/');
        } else {
          ElMessage({
            message: 'Errore aggiunta lavori!',
            type: 'error',
          });
          console.log('errore aggiunta lavori');
        }
      });
    };

    let highlightDescription = ref(false);
    let highlightCost = ref(false);

    const checkTask = () => {
      //for (let task of booking.tasks) {
      if (task.value.description == '') {
        ElMessage.error('Perfavore inserisci una descrizione dei lavori');
        highlightDescription.value = true;
        return false;
      }

      if (!task.value.cost) {
        ElMessage.error('Perfavore inserisci un costo dei lavori');
        highlightCost.value = true;
        return false;
      }
      if (task.value.cost.includes(',')) {
        highlightCost.value = true;
        ElMessage.error('Perfavore non usare virgole per il costo');
        return false;
      }

      const floatRegex = /^-?\d+(\.\d+)?$/;
      if (!floatRegex.test(task.value.cost)) {
        highlightCost.value = true;
        ElMessage.error('Perfavore inserisci un costo valido');
        return false;
      }
      highlightDescription.value = false;
      highlightCost.value = false;
      // }
      return true;
    };

    const onSubmit = async () => {
      if (!checkTask()) {
        return;
      }

      if (modifyData.value == 'true') {
        onSubmitDate();
        onSubmitAddTask();
      } else {
        onSubmitAddTask();
      }

      //route.push('/');
    };

    const formatData = (data) => {
      console.log(dayjs(data).utc().format('DD/MM/YYYY HH:mm'));
      return dayjs(data).utc().format('DD/MM/YYYY HH:mm');
    };

    return {
      formatData,
      modifyData,
      is_gone,
      is_return,
      onSubmit,
      task,

      fileLoad,
      onFileSelected,
      //aggiungiTask,
      //rimuoviTask,
      booking,
      Delete,

      new_date_gone,
      new_date_return,

      selectedSlotGone,
      selectedSlotReturn,
      timeSlotsGone,
      timeSlotsReturn,
      handleSlotChangeGone,
      handleSlotChangeReturn,
      handleChangeDateGone,
      handleChangeDateReturn,
      handleChangeDescription,
      handleChangeCost,
      handleChangePay,
      handleChangeNote,

      date_gone,
      date_return,

      formatDateGone,
      formatDateReturn,
      highlightDescription,
      highlightCost,
      disabledDate,
      disabledDateReturn,
      date_gone_formatted,
      date_return_formatted,
    };
  },
};
</script>

<style>
.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.bold-input .el-input__inner {
  font-weight: bold;
}

</style>
