import API from './API';


export default {

  addMinute(minute){
    return API.post(`/minute` , minute);
  },  
  
  mailAddMinute(id){
    return API.get(`/minute/new/` + id);
  },

  getCarByTarga(targa){
    return API.get(`/car?filter=targa||$eq||${targa}` );
  },
  getCarById(id){
    return API.get(`/car?filter=id||$eq||${id}` );
  },

  getCarsByCustomer(customerId, /* partnerId */) {
    return API.get(`/car?filter=customer.id||$eq||${customerId}&` , {
      params: {
       join: 
       [
         'minutes',
         'minutes.partner',
         'customer',
         'headquarters',
         'headquarters.employees'
       ]
       
     },  
   });
  },

  getMinutesByCustomer( customerId, partnerId) {
    return API.get(`/minute?filter=car.customer.id||$eq||${customerId}&filter=partner.id||$eq||${partnerId}&filter=accepted||$eq||${true}&` , {
      params: {
       join: 
       [
        'partner',
         'car',
         'car.customer',
       ],
     },  
   });
  },

  getMinutesByPartner(  partnerId) {
    return API.get(`/minute?filter=partner.id||$eq||${partnerId}&` , {
      params: {
       join: 
       [
        'partner',
         'car',
         'car.customer',
         'minutes'
       ],
     },  
   });
  },


/*   getMinutesByCustomer(customerId,partnerId) {
    return API.get(`/minute?filter=customer.id||$eq||${customerId}&minutes.partner.id||$eq||${partnerId}&` , {
      params: {
       join: 
       [
         'minutes',
         'minutes.partner',
         'customer'
       ],
     },  
   });
  },
 */
  addCar(car){
    return API.post('/car', car)
  },

  modifyCar(car){
    return API.patch('/car/'+car.id, car)
  }
  
};

